/* eslint-disable no-unused-vars */
/* section 1 to 8 is not used here*/
import { Empty, Row, Col, Form, Button, Space, Checkbox, Tooltip, Input, Radio, Select, Popconfirm, message, Table, Card, Spin, Modal, Image } from 'antd';
import constants from '../../constants/constants';
import SignaturePad from 'react-signature-canvas';
import { template1, template2, template0, template12, template4, template6, template9, template10, template11, template14, template16, template17, template23, template27, template28, popupMessage, luscherColorTest, uploadFile, notesModel, DOB } from "../../globalFunctions/GlobalFunctions";
import { LikeOutlined, DeleteOutlined, SaveOutlined, CheckOutlined, CheckCircleTwoTone, EditOutlined, UserOutlined, LockOutlined, TrophyOutlined, UnlockOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useRef, useState } from "react";
import { fetchApi, fetchAdminApi } from "../../services/api";
import { useHistory } from "react-router";
import { COOKIE, getCookie } from "../../services/cookie";
import moment from 'moment';
const { TextArea } = Input;
//Editable cells and rows Table code starts here
const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
	const [form] = Form.useForm();
	return (
		<Form form={form} component={false}>
			<EditableContext.Provider value={form}>
				<tr {...props} />
			</EditableContext.Provider>
		</Form>
	);
};
const EditableCell = ({
	editable,
	children,
	dataIndex,
	record,
	handleSaveTable,
	tableName,
	handleSaveTable2,
	handleAdd,
	disable,
	...restProps
}) => {
	const [editing, setEditing] = useState(false);
	const inputRef = useRef(null);
	const form = useContext(EditableContext);
	useEffect(() => {
		if (editing) {
			inputRef.current.focus();
		}
	}, [editing]);
	const toggleEdit = (param = null) => {
		setEditing(!editing);
		form.setFieldsValue({ [dataIndex]: record[dataIndex], });
	};
	//function to save and edit the cells
	const save = async (e) => {
		try {
			const values = await form.validateFields();
			toggleEdit();
			if (tableName === "Mylist" || tableName === "Mylist2" || tableName === "tableCode") {
				if (e) {
					handleAdd("EDIT", {
						...record,
						...values,
					});
				}
				if (e.code === "Enter") {
					handleAdd("ADD", {
						...record,
						...values,
					}, tableName);
				}
			} else {
				if (e) {
					handleAdd("EDIT", {
						...record,
						...values,
					});
				}
			}
		} catch (errInfo) {
			console.log('Save failed:', errInfo);
		}
	};
	let childNode = children;
	if (editable) {
		childNode = editing ? (
			<Form.Item
				style={{ margin: 0, }}
				name={dataIndex}
			>
				<Input readOnly={disable} ref={inputRef} onPressEnter={save} onBlur={save} />
			</Form.Item>
		) : (
			<div
				className="editable-cell-value-wrap"
				style={{ paddingRight: 24, }}
				onClick={() => toggleEdit("text")}
			>
				{children}
			</div>
		);
	}
	return <td {...restProps}>{childNode}</td>;
};
const Workbook = (props) => {
	let queryString = window.location.href;
	let token = queryString.substring(queryString.lastIndexOf('/') + 1);
	let is_parent_concent = token.includes("parent-consent");
	let is_payment_agreement = token.includes("payment-agreement");
	const sigPad = useRef(null);
	const key = "count";
	const history = useHistory();
	const [form] = Form.useForm();
	const [workBookData, setWorkBookData] = useState([]);
	const [isData, setisData] = useState(false);
	const [counter, setCounter] = useState(() => {
		const persistedValue = sessionStorage.getItem(key);
		return persistedValue !== null ? JSON.parse(persistedValue) : 1;
	});
	const [partCount, setPartCount] = useState(0);
	//to push the client ans values
	const [tempArray, setTempArray] = useState([]);
	const [newUser,setNewUser] = useState(props?.location?.state?.new_user);
	const [reload, setReload] = useState(false);
	const [validationArray, setvalidationArray] = useState([]);
	const [instructionModalOpen, setInstructionModalOpen] = useState(false);
	const [sectionId] = useState(queryString.substring(queryString.lastIndexOf('-') + 1));
	const [tableOneDataSource, setTableOneDataSource] = useState([]);
	const [isTableVisible, setIsTableVisible] = useState([]);
	const [tableCheckboxValue, setTableCheckboxValue] = useState([]);
	const [isResume, setIsResume] = useState({});
	const [adminDiscussionValue, setAdminDiscussionValue] = useState({});
	const [esignMessageModalOpen, setEsignMessageModalOpen] = useState(false);
	const [esignValue, setEsignValue] = useState("");
	const [isCompleteSectionPopup, setIsCompleteSectionPopup] = useState(false);
	const [isEditedByClient] = useState(sessionStorage.getItem('editedByClient'));
	const [editedData, setEditedData] = useState([]);
	const loginType = getCookie(COOKIE.LoginType);
	const [loader, setLoader] = useState(false);
	const [lastDiscussedPartId, setlastDiscussedPartId] = useState("");
	const [parentName, setparentName] = useState("");
	const [clientName, setClientName] = useState("");
	const [isFileUploaded, setisFileUploaded] = useState(false);
	const [pdfValue, setpdfValue] = useState("");
	//lusher color test
	const colors = [
		"rgb(255, 51, 0)", /*Orange-Red color*/
		"rgb(255, 204, 0)", /*Bright Yellow color*/
		"rgb(0, 84, 71)", /*Blue-Green color*/
		"rgb(0, 0, 85)", /*Dark Blue color*/
		"rgb(153, 51, 0)", /*Brown color*/
		"rgb(153, 153, 153)", /*Grey color*/
		"rgb(0, 0, 0)", /*Black color*/
		"rgb(153, 0, 102)" /*Violet color*/
	];
	const [boxes, setBoxes] = useState([
		{
			id: 1,
			color: ""
		},
		{
			id: 2,
			color: ""
		},
		{
			id: 3,
			color: ""
		},
		{
			id: 4,
			color: ""
		},
		{
			id: 5,
			color: ""
		},
		{
			id: 6,
			color: ""
		},
		{
			id: 7,
			color: ""
		},
		{
			id: 8,
			color: ""
		},
	]);
	const [isDirty,setIsDirty]=useState(false);
	//notes
	const [notesVisible, setnotesVisible] = useState(false);
	const [noteValue, setNoteValue] = useState(null);
	const [noteTypeValue, setNoteTypeValue] = useState("0");
	const [sessionChecked, setSessionChecked] = useState(false);
	//go to page drawer
	const [openDrawer, setOpenDrawer] = useState(false); 
	const drawerColumns = [
		{
			title: 'Page No',
			key: 'page_no',
			dataIndex: "page_no",
			align: "center",
			width: 60,
		},
		{
			title: 'Tittle',
			key: 'tittle',
			// dataIndex: "page_title",
			render:(record)=>{
				return <span className='cursor-pointer' onClick={()=>handleGoToPage(record?.page_no)} dangerouslySetInnerHTML={{ __html: record?.page_title }}/>;{/* {record?.page_title}</span> */}
			}
		},
		{
			title: false,
			key: 'is_edited',
			width: 60,
			render:(record)=>{
				if(record.is_edited){
					return <EditOutlined />
				};
			}
		}
	];
	const [goToPageData, setGoToPageData] = useState([]);
	// api value for go to page
	const [sectionPartTitle , setSectionPartTitle] =useState([]);
	//upload file
	const [fileList, setFileList] = useState([]);
	const [isRefresher, setIsRefresher] = useState(false);
	const [isModalOpen, setisModalOpen] = useState(false);
	const current = new Date();
	const [noteDate,setNoteDate] = useState({
		date: current.getDate(),
		month: String(current.getMonth() + 1),
		year: current.getFullYear()
	});
	const [medicalFormData, setMedicalFormData] = useState([]);
	//date of notes date onchange
	const handleNoteDayChange = (value) => {
		setNoteDate({
			...noteDate,
			date:value
		});
	};
	const handleNoteMonthChange = (value) => {
		setNoteDate({
			...noteDate,
			month:value
		});
	};
	const handleNoteYearChange = (value) => {
		setNoteDate({
			...noteDate,
			year:value
		});
	};
	const handleCancel = () => {
		setisModalOpen(false);
	};
	const containerRef = useRef(null);
	const components = {
		body: {
			row: EditableRow,
			cell: EditableCell,
		},
	};
	const handleFileChange = ({ fileList }) => {
		setFileList(fileList);
	};
	function beforeUpload(file) {
		const isJpgOrPng =
			file.name.includes(".pdf") ||
			file.name.includes(".jpg") ||
			file.name.includes(".png") ||
			file.name.includes(".jpeg");
		if (!isJpgOrPng) {
			message.error({
				message: "Failed",
				description: "You can only upload PDF/JPEG/PNG file!",
			});
		}
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
			message.error({
				message: "Failed",
				description: "Max file size accepted 2MB!",
			});
		}
		return isLt2M;
	}
	const handleUpload = async () => {
		try {
			let token2 = token.substring(token.lastIndexOf('?') + 1);
			let enquiry_id = atob(token2);
			const formData = new FormData();
			fileList.forEach((file) => {
				formData.append('files[]', file.originFileObj);
			});
			formData.append('enquiry_id', enquiry_id);
			const response = await fetch(`${constants.ApiUrl}/upload-proof`, {
				method: 'POST',
				body: formData,
				headers: { Authorization: `Bearer ${getCookie(COOKIE.Token)}` },
			});
			const data = await response.json();
			if (data.code === 200) {
				setisFileUploaded(true);
			} else {
				setisFileUploaded(false);
			}
		} catch (error) {
			message.error(error.message);
		}
	};
	const handleRemove = (file) => {
		// remove the file from the fileList
		const index = fileList.indexOf(file);
		const newFileList = fileList.slice();
		newFileList.splice(index, 1);
		setFileList(newFileList);
	};
	const updateBoxColors = () => {
		//genereting different colors
		const shuffledColors = colors.sort(() => 0.5 - Math.random());
		const initialBoxes = shuffledColors.map((color, index) => {
			return {
				id: index + 1,
				color: color,
				visible: true
			};
		});
		setBoxes(initialBoxes);
	};
	const [colorSequence, setColorSequence] = useState([]);
	const [isNoteAnswered, setIsNoteAnswered] = useState(false);
	const handleBoxClick = (id, color, item_id) => {
		const updatedBoxes = boxes.map((box) => {
			if (box.id === id) {
				return {
					...box,
					visible: false
				};
			} else {
				return box;
			}
		});
		setBoxes(updatedBoxes);
		let colorName = null;
		if (color === "rgb(255, 51, 0)") {
			colorName = "Orange-Red";
		} else if (color === "rgb(255, 204, 0)") {
			colorName = "Bright Yellow";
		} else if (color === "rgb(0, 84, 71)") {
			colorName = "Blue-Green";
		} else if (color === "rgb(0, 0, 85)") {
			colorName = "Dark Blue";
		} else if (color === "rgb(153, 51, 0)") {
			colorName = "Brown";
		} else if (color === "rgb(153, 153, 153)") {
			colorName = "Grey";
		} else if (color === "rgb(0, 0, 0)") {
			colorName = "Black";
		} else if (color === "rgb(153, 0, 102)") {
			colorName = "Violet";
		}
		if (colorSequence.length > 0) {
			let found = false;
			for (let index = 0; index < colorSequence.length; index++) {
				const element = colorSequence[index];
				if (element !== colorName) {
					colorSequence.push(colorName);
					found = true;
				}
				if (found) {
					break;
				}
			}
		} else {
			colorSequence.push(colorName);
		}
		if (colorSequence.length === 8) {
			if (loginType === "0") {
				let payload = {
					json_data: colorSequence,
					item_id: item_id,
					section_id: props?.location?.pathname === "/refresher-workbook" ? "100" : sectionId,
				};
				if (tempArray.length === 0) {
					tempArray.push(payload);
				}
				savingDatatoDb(null,null,true);
			}
			setCounter(counter + 1);
			setColorSequence([]);
		}
	};
	let adminClientValuefromSession = JSON.parse(sessionStorage.getItem("adminClientValue"));
	useEffect(() => {
		if (props?.location?.state || adminClientValuefromSession) {
			sessionStorage.setItem("adminDiscussionValue", JSON.stringify(props.history.location.state || adminClientValuefromSession));
		}
		if (adminClientValuefromSession?.is_medical_info) {
			getMedicalForm();
		} else {
			fetchWorkbookSections();
		}
		if (isEditedByClient) {
			getClientEditedData();
		}
		updateBoxColors();
		return () => {
			sessionStorage.removeItem("count");
		};
	}, []);
	useEffect(() => {
	}, [reload]);
	useEffect(() => {
		sessionStorage.setItem(key, JSON.stringify(counter));
	}, [counter]);
	const getMedicalForm = () => {
		setLoader(true);
		fetchApi(`/display-medical-info-release/${adminClientValuefromSession?.clientId}`, "get").then((res) => {
			if (res?.data) {
				setLoader(false);
				if (onclick === "onNext") {
					handleSetTemplate3formValue(res.data, "onNext");
				} else if (onclick === "onPrevious") {
					handleSetTemplate3formValue(res.data, "onPrevious");
				} else {
					handleSetTemplate3formValue(res.data);
				}
				setWorkBookData(res.data);
				// to get the length of the workbook(to checkeck how many part(pages) are ther in a workbook)
				let objectLength = Object.keys(res?.data).length;
				setPartCount(objectLength);
				setisData(true);
			} else {
				setLoader(false);
				setisData(false);
			}
		});
	};
	//function to delete row from a table
	const handleDelete = (key) => {
		const newData = tableOneDataSource.filter((item) => item.key !== key);
		setTableOneDataSource(newData);
	};
	const tableOneColumns = [
		{
			title: 'Name',
			dataIndex: 'name',
			width: '50%',
			editable: true,
		},
		{
			title: 'Action',
			dataIndex: 'operation',
			align: "center",
			width: '10%',
			render: (_, record) => {
				if (tableOneDataSource?.length > 1) {
					return <Tooltip title={constants.Delete}><Popconfirm title="Sure to Delete?" onConfirm={() => handleDelete(record.key,)}>
						<Button type="link" danger icon={<DeleteOutlined />} />
					</Popconfirm></Tooltip>;
				}
			}
		},
	];
	//function for dropdown table
	const handleChange = (e, record) => {
		for (const element of tableOneDataSource) {
			if (element.key === record.key) {
				element.affected = e;
				break;
			}
		}
	};
	// function to save the table data (particular row)
	const handleActionSave = (record) => {
		const index = tableOneDataSource.findIndex((item) => record.key === item.key);
		let sortArray = [];
		for (let i = index; i >= 0; i--) {
			if (record.table === "FearTable") {
				sortArray.push({
					fearCause: tableOneDataSource[i].fearCause,
					key: tableOneDataSource[i].key,
					name: tableOneDataSource[i].name,
					fearAction: tableOneDataSource[i].fearAction,
				});
			} else if (record.table === "AngerSheetTable") {
				sortArray.push({
					affected: tableOneDataSource[i].affected,
					key: tableOneDataSource[i].key,
					name: tableOneDataSource[i].name,
					problem: tableOneDataSource[i].problem,
					reaction: tableOneDataSource[i].reaction
				});
			}
		}
		let finalPayload = sortArray.sort((a, b) => a.key - b.key);
		if (tempArray.length === 1) {
			tempArray[0].json_data = finalPayload;
		}
		if (record.is_pushed === 0) {
			savingDatatoDb();
		}
		for (let j = index; j >= 0; j--) {
			tableOneDataSource[j].is_pushed = 1;
		}
	};
	//function to add the row to the editable table
	const handleAdd = (param = null, row, table = null) => {
		let dataSource = [...tableOneDataSource];
		if (param === "ADD") {
			let index = dataSource.findIndex((item) => item.key === row.key);
			if (index > -1) {
				dataSource[index] = row;
				if (dataSource[dataSource.length - 1].name !== "") {
					if (table === "Mylist") {
						dataSource.push({
							key: tableOneDataSource.reduce((previous, current) => {
								return current.key > previous.key ? current : previous;
							}).key + 1,
							name: ``,
							problem: ``,
							affected: ``,
							reaction: ``,
							is_pushed: 0,
							table: "AngerSheetTable",
						});
						setTableOneDataSource(dataSource);
					} else if (table === "Mylist2") {
						dataSource.push({
							key: tableOneDataSource.reduce((previous, current) => {
								return current.key > previous.key ? current : previous;
							}).key + 1,
							name: ``,
							fearAction: ``,
							fearCause: ``,
							is_pushed: 0,
							table: "FearTable",
						});
						setTableOneDataSource(dataSource);
					} else if (table === "tableCode") {
						dataSource.push({
							key: tableOneDataSource.reduce((previous, current) => {
								return current.key > previous.key ? current : previous;
							}).key + 1,
							name: ``,
							problem: ``,
							affected: ``,
							is_pushed: 0,
							table: "tableCode",
						});
						setTableOneDataSource(dataSource);
					}
				}
			}
		} else if (param === "EDIT") {
			let index = dataSource.findIndex((item) => item.key === row.key);
			if (dataSource[index].name !== row.name || dataSource[index].problem !== row.problem || dataSource[index].affected !== row.affected || dataSource[index].reaction !== row.reaction || dataSource[index].fearCause !== row.fearCause || dataSource[index].fearAction !== row.fearAction) {
				dataSource[index] = row;
				setTableOneDataSource(dataSource);
			}
		}
	};
	const template8 = (contentLeft, data, position, disable) => {
		if (data) {
			if (validationArray.length === 0) {
				validationArray.push({ isStressTable: true });
			}
		}
		const onchangeSelect = value => {
			if (value !== null && value !== "" && value !== undefined) {
				if (validationArray.length === 1) {
					workBookData[counter].filter((item) => item.template_id === "8")
						.map((item, index) => {
							if (item.client_ans !== "" && item.client_ans !== null) {
								let payload = null;
								payload = {
									section_id: item.section_id,
									item_id: item.item_id,
									option_data: item.client_ans
								};
								validationArray.push(payload);
							} else {
								let payload = null;
								payload = {
									section_id: item.section_id,
									item_id: item.item_id,
									option_data: null
								};
								validationArray.push(payload);
							}
						});
				}
				for (let i = 0; i < validationArray.length; i++) {
					if (validationArray[i].item_id === data.item_id) {
						validationArray[i].option_data = value;
						if (data?.is_discussed === 1) {
							validationArray[i].is_changed = 1;
						}
						break;
					}
				}
				workBookData[counter][position].client_ans = value;
			}
			setReload(!reload);
		};
		let cssClass = null;
		if (data?.isEdited) {
			cssClass = 'w-full disabled-outline';
		} else {
			cssClass = 'w-full';
		}
		return (
			<div className='flex table-bordered w-6/12 mx-auto'>
				<div className='w-3/5 p-3 border-black border-l-2 border-t-2'>{contentLeft}</div>
				<div className='w-2/5 p-3 border-black border-x-2 border-t-2'>
					<Select
						status={data?.isEdited ? "error" : null}
						disabled={disable}
						showSearch
						onChange={onchangeSelect}
						className={`disabled-select-dark ${cssClass}`}
						value={workBookData[counter][position].client_ans}
						optionFilterProp="children"
						filterOption={(input, option) => {
							return option.label.toLowerCase().indexOf(input.toLowerCase()) > -1 && option.label.toLowerCase().indexOf(input.toLowerCase()) < 1;
						}}
						options={[
							{
								value: "1",
								label: 'Never',
							},
							{
								value: "2",
								label: 'Once in a great while',
							},
							{
								value: "3",
								label: 'Rarely',
							},
							{
								value: "4",
								label: 'Sometimes',
							},
							{
								value: "5",
								label: 'Often',
							},
							{
								value: "6",
								label: 'Usually',
							},
							{
								value: "7",
								label: 'Always',
							},
						]}
					/>
				</div>
			</div>
		);
	};
	const template7 = (contentLeft, data, position, disable) => {
		if (data) {
			if (validationArray.length === 0) {
				validationArray.push({ isradioButton: true });
			}
		}
		const onChangeRadio = e => {
			if (e.target.value) {
				if (validationArray.length === 1) {
					workBookData[counter].filter((item) => item.template_id === "7")
						.map((item, index) => {
							if (item.client_ans !== "" && item.client_ans !== null) {
								let payload = null;
								payload = {
									section_id: item.section_id,
									item_id: item.item_id,
									option_data: item.client_ans
								};
								// if (data?.is_discussed === 1) {
								// 	payload.is_changed = 1
								// }
								validationArray.push(payload);
							} else {
								let payload = null;
								payload = {
									section_id: item.section_id,
									item_id: item.item_id,
									option_data: null
								};
								// if (data?.is_discussed === 1) {
								// 	payload.is_changed = 1
								// }
								validationArray.push(payload);
							}
						});
				}
				for (let i = 0; i < validationArray.length; i++) {
					if (validationArray[i].item_id === data.item_id) {
						validationArray[i].option_data = e.target.value;
						if (data?.is_discussed === 1) {
							validationArray[i].is_changed = 1;
						}
						break;
					}
				}
				workBookData[counter][position].client_ans = e.target.value;
			}
			setReload(!reload);
		};
		let divclass = 'w-1/5 text-center p-3 border-black border-x-2 border-t-2';
		if (data?.isEdited) {
			divclass = 'w-1/5 text-center p-3 border-black border-x-2 border-t-2 flex';
		}
		return (
			<div className='flex table-bordered'>
				<div className='w-4/5 p-3 border-black border-l-2 border-t-2 font-semibold'>{contentLeft}</div>
				<div className={divclass}>
					{data?.isEdited ? <div className="mr-4 mt-1"><EditOutlined /></div> : null}
					<Radio.Group disabled={disable} onChange={onChangeRadio} value={workBookData[counter][position].client_ans}>
						<Radio id="yes" value={"1"}>Yes</Radio>
						<Radio id="no" value={"2"}>No</Radio>
					</Radio.Group>
				</div>
			</div>
		);
	};
	const template3 = (question, data, position, type_left, is_readOnly, innerposition, tooltipValue) => {
		let rowValue = null;
		let bgDisable;
		if (type_left === "TA") {
			rowValue = 8;
		} else {
			rowValue = 1;
		}
		const getToolTipValue = () => {
			if (tooltipValue === "tooltip1" && type_left === "R4") {
				let date = data?.dt_answered ? `(${moment(data?.dt_answered).format(constants.dateformat)})` : ""
				let val =constants.Status1Ttip +" "+ date;
				return val;
			}
			if (tooltipValue === "tooltip2" && type_left === "R4") {
				let date = data?.dt_answered ? `(${moment(data?.dt_answered).format(constants.dateformat)})` : ""
				let status = props?.location?.pathname === "/refresher-workbook" ? constants.Status2Ttip : constants.Status3Ttip
				let val = status + " " + date;
				return val;
			}
		};
		if (is_readOnly) {
			bgDisable = "bg-gray-200";
		}
		const onchangeText = (e) => {
			if (e) {
				if (is_parent_concent && question === "Parent's Name") {
					setparentName(e.target.value);
				}
				workBookData[counter][position].client_ans = e.target.value;
				if (tempArray.length > 0) {
					let found = false;
					for (let i = 0; i < tempArray.length; i++) {
						if (tempArray[i].item_id === data.item_id) {
							tempArray[i].textValue = e.target.value;
							found = true;
							// Removing the object from temparray when e.target.value ==="0" & section_id =0. this is for validation purpose
							if (sectionId === "0") {
								if (tempArray[i].textValue === "") {
									tempArray.splice(i, 1);
								}
							}
							break;
						}
					}
					if (!found) {
						let payload = null;
						payload = {
							item_id: data.item_id,
							section_id: data?.section_id ? data?.section_id : sectionId,
							textValue: e.target.value
						};
						if (data?.is_discussed === 1) {
							payload.is_changed = 1;
						}
						tempArray.push(payload);
					}
				} else {
					let payload = null;
					payload = {
						item_id: data.item_id,
						section_id: data?.section_id ? data?.section_id : sectionId,
						textValue: e.target.value,
					};
					if (data?.is_discussed === 1) {
						payload.is_changed = 1;
					}
					tempArray.push(payload);
				}
			}
			setReload(!reload);
		};
		return (
			<div className='py-2'>
				<Tooltip title={getToolTipValue()} placement="bottom" trigger={['focus']}>
					<Form.Item
						rules={[
							{
								required: data.item_id === "733" || data.item_id === "1091"|| data.item_id === "1092"|| data.item_id === "762" ? true : false,
								message: "Please enter the field.",
							},
						]}
						className="mb-0"
						label={<div dangerouslySetInnerHTML={{ __html: question }} />}
						name={
							innerposition > -1 ?
								innerposition :
								workBookData[counter][position].item_id
						}
					>
						<TextArea
							className={bgDisable}
							disabled={question === "Parent's Email" && is_parent_concent || question === "Client Name" && is_parent_concent ? true : false}
							autoSize={type_left !== "TA" ? true : false}
							rows={rowValue}
							status={data?.isEdited ? "error" : null}
							onChange={(e) => { onchangeText(e); }}
							readOnly={is_readOnly}
						/>
					</Form.Item>
				</Tooltip>
			</div>
		);
	};
	const template5 = (label, data, position, disable) => {
		let tempDataCheckbox;
		const onChangeCheckboxValue = (e) => {
			if (e) {
				tempDataCheckbox = workBookData[counter].filter((item) => (
					item.template_id === "5"
				));
				tempDataCheckbox.map((item, index) => {
					if (item.item_id === data.item_id) {
						if (e.target.checked === false) {
							if (tempArray.length > 0) {
								let found = false;
								for (let i = 0; i < tempArray.length; i++) {
									if (tempArray[i].item_id === data.item_id) {
										tempArray.splice(i, 1);
										found = true;
										break;
									}
								}
								if (!found) {
									if (item.client_ans || item.check_box === "t") {
										let payload = null;
										payload = {
											item_id: data.item_id,
											section_id: data?.section_id,
											option_data: 2
										};
										if (data?.is_discussed === 1) {
											payload.is_changed = 1;
										}
										tempArray.push(payload);
									}
								}
							} else {
								if (item.client_ans || item.check_box === "t") {
									let payload = null;
									payload = {
										item_id: data.item_id,
										section_id: data?.section_id,
										option_data: 2
									};
									if (data?.is_discussed === 1) {
										payload.is_changed = 1;
									}
									tempArray.push(payload);
								}
							}
							workBookData[counter][position].client_ans = "";
						} else {
							if (tempArray.length > 0) {
								let found2 = false;
								for (let j = 0; j < tempArray.length; j++) {
									if (tempArray[j].item_id === data.item_id) {
										tempArray[j].option_data = 1;
										found2 = true;
										break;
									}
								}
								if (!found2) {
									let payload = null;
									payload = {
										item_id: data.item_id,
										section_id: data?.section_id,
										option_data: 1
									};
									if (data?.is_discussed === 1) {
										payload.is_changed = 1;
									}
									tempArray.push(payload);
								}
							} else {
								let payload = null;
								payload = {
									item_id: data.item_id,
									section_id: data?.section_id,
									option_data: 1
								};
								if (data?.is_discussed === 1) {
									payload.is_changed = 1;
								}
								tempArray.push(payload);
							}
						}
						tempDataCheckbox[index].check_box = e.target.checked === true ? 't' : 'f';
					}
				});
			}
			setReload(!reload);
		};
		const onchangeText = (e) => {
			let tempTextValue;
			tempTextValue = workBookData[counter].filter((item) => (
				item.template_id === "5"
			));
			tempTextValue.map((item, index) => {
				if (item.item_id === data.item_id) {
					tempTextValue[index].client_ans = e.target.value;
					if (tempArray.length > 0) {
						let found = false;
						for (let i = 0; i < tempArray.length; i++) {
							if (tempArray[i].item_id === data.item_id) {
								tempArray[i].textValue = e.target.value;
								found = true;
								break;
							}
						}
						if (!found) {
							let payload = null;
							payload = {
								item_id: data.item_id,
								textValue: e.target.value,
								section_id: data?.section_id,
							};
							if (data?.is_discussed === 1) {
								payload.is_changed = 1;
							}
							tempArray.push(payload);
						}
					} else {
						let payload = null;
						payload = {
							item_id: data.item_id,
							textValue: e.target.value,
							section_id: data?.section_id,
						};
						if (data?.is_discussed === 1) {
							payload.is_changed = 1;
						}
						tempArray.push(payload);
					}
				}
			});
			// }
			setReload(!reload);
		};
		return (
			<Col span={24}>
				<Form.Item
					name="template5">
					<Row gutter={[8, 8]}>
						<Col xs={24} lg={6}>
							<Checkbox disabled={disable} id={data.item_id} checked={workBookData[counter][position].check_box === 'f' ? false : true} onChange={e => onChangeCheckboxValue(e)}>{<div dangerouslySetInnerHTML={{ __html: label }} />}</Checkbox>
						</Col>
						<Col xs={24} lg={18}>
							<TextArea
								status={data?.isEdited ? "error" : null}
								readOnly={disable}
								autoSize={true}
								rows={1}
								name={position}
								value={workBookData[counter][position].client_ans}
								disabled={workBookData[counter][position].check_box === "t" ? false : true}
								onChange={e => onchangeText(e)} />
						</Col>
					</Row>
				</Form.Item>
			</Col>
		);
	};
	// Checkbox with input field below.
	const template19 = (content_left, data, position, disable) => {
		let tempDataCheckbox;
		const onChangeCheckboxValue = (e) => {
			if (e) {
				tempDataCheckbox = workBookData[counter].filter((item) => (
					item.template_id === "19"
				));
				tempDataCheckbox.map((item, index) => {
					if (item.item_id === data.item_id) {
						if (e.target.checked === false) {
							if (tempArray.length > 0) {
								let found = false;
								for (let i = 0; i < tempArray.length; i++) {
									if (tempArray[i].item_id === data.item_id) {
										tempArray.splice(i, 1);
										found = true;
										break;
									}
								}
								if (!found) {
									if (item.client_ans || item.check_box === "t") {
										let payload = null;
										payload = {
											item_id: data.item_id,
											section_id: data?.section_id,
											option_data: 2
										};
										if (data?.is_discussed === 1) {
											payload.is_changed = 1;
										}
										tempArray.push(payload);
									}
								}
							}
							else {
								if (item.client_ans || item.check_box === "t") {
									let payload = null;
									payload = {
										item_id: data.item_id,
										section_id: data?.section_id,
										option_data: 2
									};
									if (data?.is_discussed === 1) {
										payload.is_changed = 1;
									}
									tempArray.push(payload);
								}
							}
							workBookData[counter][position].client_ans = "";
						}
						else {
							if (tempArray.length > 0) {
								let found2 = false;
								for (let j = 0; j < tempArray.length; j++) {
									if (tempArray[j].item_id === data.item_id) {
										tempArray[j].option_data = 1;
										found2 = true;
										break;
									}
								}
								if (!found2) {
									let payload = null;
									payload = {
										item_id: data.item_id,
										section_id: data?.section_id,
										option_data: 1
									};
									if (data?.is_discussed === 1) {
										payload.is_changed = 1;
									}
									tempArray.push(payload);
									// }
								}
							} else {
								let payload = null;
								payload = {
									item_id: data.item_id,
									section_id: data?.section_id,
									option_data: 1
								};
								if (data?.is_discussed === 1) {
									payload.is_changed = 1;
								}
								tempArray.push(payload);
							}
						}
						tempDataCheckbox[index].check_box = e.target.checked === true ? 't' : 'f';
					}
				});
			}
			setReload(!reload);
		};
		const onchangeText = (e) => {
			let tempTextValue;
			tempTextValue = workBookData[counter].filter((item) => (
				item.template_id === "19"
			));
			tempTextValue.map((item, index) => {
				if (item.item_id === data.item_id) {
					tempTextValue[index].client_ans = e.target.value;
					if (tempArray.length > 0) {
						let found = false;
						for (let i = 0; i < tempArray.length; i++) {
							if (tempArray[i].item_id === data.item_id) {
								tempArray[i].textValue = e.target.value;
								found = true;
								break;
							}
						}
						if (!found) {
							let payload = null;
							payload = {
								item_id: data.item_id,
								textValue: e.target.value,
								section_id: data?.section_id,
							};
							if (data?.is_discussed === 1) {
								payload.is_changed = 1;
							}
							tempArray.push(payload);
						}
					} else {
						let payload = null;
						payload = {
							item_id: data.item_id,
							textValue: e.target.value,
							section_id: data?.section_id,
						};
						if (data?.is_discussed === 1) {
							payload.is_changed = 1;
						}
						tempArray.push(payload);
					}
				}
			});
			setReload(!reload);
		};
		return (
			<div>
				<div>
					<Checkbox disabled={disable} id={data.item_id} checked={workBookData[counter][position].check_box === 'f' ? false : true} onChange={e => onChangeCheckboxValue(e)}><div dangerouslySetInnerHTML={{ __html: content_left }} /></Checkbox>
				</div>
				<div className='pl-6 mb-5'>
					<TextArea status={data?.isEdited ? "error" : null} readOnly={disable} autoSize={true} rows={1} name={position} value={workBookData[counter][position].client_ans} disabled={workBookData[counter][position].check_box === "t" ? false : true} onChange={e => onchangeText(e)} />
				</div>
			</div>
		);
	};
	const template13 = (content_left, data, position, disable) => {
		const onChangeCheckboxValue = (e) => {
			if (e) {
				workBookData[counter][position].check_box = e.target.checked === true ? 't' : 'f';
				if (tempArray.length > 0) {
					let found = false;
					for (let i = 0; i < tempArray.length; i++) {
						if (tempArray[i].item_id === data.item_id) {
							tempArray[i].option_data = e.target.checked === true ? '1' : '0';
							found = true;
							break;
						}
					}
					if (!found) {
						let payload = null;
						payload = {
							option_data: e.target.checked === true ? '1' : '0',
							section_id: data?.section_id,
							item_id: data.item_id
						};
						if (data?.is_discussed === 1) {
							payload.is_changed = 1;
						}
						tempArray.push(payload);
					}
				} else {
					let payload = null;
					payload = {
						option_data: e.target.checked === true ? '1' : '0',
						section_id: data?.section_id,
						item_id: data.item_id
					};
					if (data?.is_discussed === 1) {
						payload.is_changed = 1;
					}
					tempArray.push(payload);
				}
			}
			setReload(!reload);
		};
		return (
			<div className='py-2'>
				{data?.isEdited ? <div className="mr-4"><CheckCircleTwoTone /></div> : null}
				<Checkbox disabled={disable} checked={workBookData[counter][position].check_box === 'f' ? false : true} onChange={e => onChangeCheckboxValue(e)} id={data.item_id}>{<div dangerouslySetInnerHTML={{ __html: content_left }} />}</Checkbox>
			</div>
		);
	};

	//checkbox with image.
	const template26 = (content_left, data, position, disable) => {
		const onChangeCheckboxValue = (e) => {
			if (e) {
				workBookData[counter][position].check_box = e.target.checked === true ? 't' : 'f';
				if (tempArray.length > 0) {
					let found = false;
					for (let i = 0; i < tempArray.length; i++) {
						if (tempArray[i].item_id === data.item_id) {
							tempArray[i].option_data = e.target.checked === true ? '1' : '0';
							found = true;
							break;
						}
					}
					if (!found) {
						let payload = null;
						payload = {
							option_data: e.target.checked === true ? '1' : '0',
							section_id: data?.section_id,
							item_id: data.item_id
						};
						if (data?.is_discussed === 1) {
							payload.is_changed = 1;
						}
						tempArray.push(payload);
					}
				} else {
					let payload = null;
					payload = {
						option_data: e.target.checked === true ? '1' : '0',
						section_id: data?.section_id,
						item_id: data.item_id
					};
					if (data?.is_discussed === 1) {
						payload.is_changed = 1;
					}
					tempArray.push(payload);
				}
			}
			setReload(!reload);
		};
		return (
			<div className='py-2 inline-block w-1/4 checkbox-start'>
				<Checkbox
					disabled={disable}
					checked={workBookData[counter][position].check_box === 'f' ? false : true}
					onChange={e => onChangeCheckboxValue(e)}
					id={data.item_id}
				>
					<Image
						alt="img"
						className='!w-60'
						preview={false}
						src={content_left}
					/>
				</Checkbox>
			</div>
		);
	};

	const checkboxGrid = (content_left, data, position, disable) => {
		const onChangeCheckboxValue = (e) => {
			if (e) {
				workBookData[counter][position].check_box = e.target.checked === true ? 't' : 'f';
				if (tempArray.length > 0) {
					let found = false;
					for (let i = 0; i < tempArray.length; i++) {
						if (tempArray[i].item_id === data.item_id) {
							tempArray[i].option_data = e.target.checked === true ? '1' : '0';
							found = true;
							break;
						}
					}
					if (!found) {
						let payload = null;
						payload = {
							option_data: e.target.checked === true ? '1' : '0',
							section_id: data?.section_id,
							item_id: data.item_id
						};
						if (data?.is_discussed === 1) {
							payload.is_changed = 1;
						}
						tempArray.push(payload);
					}
				} else {
					let payload = null;
					payload = {
						option_data: e.target.checked === true ? '1' : '0',
						section_id: data?.section_id,
						item_id: data.item_id
					};
					if (data?.is_discussed === 1) {
						payload.is_changed = 1;
					}
					tempArray.push(payload);
				}
			}
			setReload(!reload);
		};
		return (
			<div className='py-2 mr-5 inline-block'>
				{data?.isEdited ? <div className="mr-4"><CheckCircleTwoTone /></div> : null}
				<Checkbox disabled={disable} checked={workBookData[counter][position].check_box === 'f' ? false : true} onChange={e => onChangeCheckboxValue(e)} id={data.item_id}>{<div dangerouslySetInnerHTML={{ __html: content_left }} />}</Checkbox>
			</div>
		);
	};

	const template15 = (values, disable) => {
		if (isTableVisible.length === 0) {
			isTableVisible.push({ vissible: true });
			if (!values.client_ans) {
				//setting 5 empty rows as default on load of the page.
				if (values.content_left === "My List Table") {
					setTableOneDataSource([
						{
							key: 1,
							name: ``,
							problem: ``,
							affected: ``,
							reaction: ``,
							table: "AngerSheetTable",
							is_pushed: 0
						},
						{
							key: 2,
							name: ``,
							problem: ``,
							affected: ``,
							reaction: ``,
							table: "AngerSheetTable",
							is_pushed: 0
						},
						{
							key: 3,
							name: ``,
							problem: ``,
							affected: ``,
							reaction: ``,
							table: "AngerSheetTable",
							is_pushed: 0
						},
						{
							key: 4,
							name: ``,
							problem: ``,
							affected: ``,
							reaction: ``,
							table: "AngerSheetTable",
							is_pushed: 0
						},
						{
							key: 5,
							name: ``,
							problem: ``,
							affected: ``,
							reaction: ``,
							table: "AngerSheetTable",
							is_pushed: 0
						}
					]);
				} else if (values.content_left === "My List Table 2") {
					setTableOneDataSource([
						{
							key: 1,
							name: ``,
							fearCause: ``,
							fearAction: ``,
							table: "FearTable",
							is_pushed: 0
						},
						{
							key: 2,
							name: ``,
							fearCause: ``,
							fearAction: ``,
							table: "FearTable",
							is_pushed: 0
						},
						{
							key: 3,
							name: ``,
							fearCause: ``,
							fearAction: ``,
							table: "FearTable",
							is_pushed: 0
						},
						{
							key: 4,
							name: ``,
							fearCause: ``,
							fearAction: ``,
							table: "FearTable",
							is_pushed: 0
						},
						{
							key: 5,
							name: ``,
							fearCause: ``,
							fearAction: ``,
							table: "FearTable",
							is_pushed: 0
						}
					]);
				} else if (values.content_left === "table code") {
					setTableOneDataSource([
						{
							key: 1,
							name: ``,
							problem: ``,
							affected: ``,
							table: "tableCode",
							is_pushed: 0
						},
						{
							key: 2,
							name: ``,
							problem: ``,
							affected: ``,
							table: "tableCode",
							is_pushed: 0
						},
						{
							key: 3,
							name: ``,
							problem: ``,
							affected: ``,
							table: "tableCode",
							is_pushed: 0
						},
						{
							key: 4,
							name: ``,
							problem: ``,
							affected: ``,
							table: "tableCode",
							is_pushed: 0
						},
						{
							key: 5,
							name: ``,
							problem: ``,
							affected: ``,
							table: "tableCode",
							is_pushed: 0
						}
					]);
				}
			}
			//setting datasource for angersheet table 1 and 2
			if (values.content_left === "My List Table" || values.content_left === "My List Table 2") {
				if (workBookData[counter + 1].length > 0) {
					for (const element of workBookData[counter + 1]) {
						if (element.client_ans) {
							let datasource = element.client_ans;
							if (datasource.length > 0) {
								setTableOneDataSource(datasource);
							}
						}
					}
				}
			}
			//setting client ans for table code
			if (values.client_ans) {
				if (values.content_left === "table code" || values.content_left === "Anger Sheet table" || values.content_left === "Anger Sheet table 2") {
					if(tableOneDataSource.length > 0){
						if(JSON.stringify(tableOneDataSource) !== JSON.stringify(values?.client_ans)){
							let datasource = [...tableOneDataSource];
							setTableOneDataSource(datasource);
						}
					}else{
						setTableOneDataSource(values.client_ans);
					};
				}
			}
		}
		if (tableOneDataSource?.length > 0 && loginType === "0") {
			if (tempArray.length === 0) {
				if (values.content_left === "Anger Sheet table" || values.content_left === "Anger Sheet table 2" || values.content_left === "table code" || values.content_left === "My List Table" || values.content_left === "My List Table 2" ) {
					let payload = null;
					if(values.content_left === "My List Table"){
						let index = workBookData?.[counter + 1]?.findIndex((val)=> val.content_left==="Anger Sheet table");
						payload = {
							item_id: workBookData[counter + 1][index].item_id,
							section_id: workBookData[counter + 1][index].section_id
						};
					}else if(values.content_left === "My List Table 2"){
						let index = workBookData?.[counter + 1]?.findIndex((val)=> val.content_left==="Anger Sheet table 2");
						payload = {
							item_id: workBookData[counter + 1][index].item_id,
							section_id: workBookData[counter + 1][index].section_id
						};
					}
					else{
						payload = {
							item_id: values.item_id,
							section_id: values.section_id
						};
					};
					if (values?.is_discussed === 1) {
						payload.is_changed = 1;
					};
					tempArray.push(payload);
				};
			}
		}
		const column = tableOneColumns.map((col) => {
			if (!col.editable) {
				return col;
			}
			return {
				...col,
				onCell: (record) => ({
					record,
					editable: col.editable,
					dataIndex: col.dataIndex,
					tableName: values.content_left === "My List Table" ? "Mylist" : "Mylist2",
					handleAdd,
					disable: disable,
				}),
			};
		});
		const tableTwoColumns = [
			// section 4 table starts here
			{
				title: 'Name',
				dataIndex: 'name',
				width: '10%',
				editable: true,
				hidden: values.content_left === "Anger Sheet table 2" ? true : false,
			},
			{
				title: "What they did to me?",
				dataIndex: "problem",
				editable: true,
				width: "30%",
				hidden: values.content_left === "Anger Sheet table 2" ? true : false,
			},
			{
				title: "Which Instinct was affected?",
				dataIndex: "affected",
				width: "20%",
				hidden: values.content_left === "Anger Sheet table 2" ? true : false,
				render: (_, record) => {
					return <Select
						disabled={disable}
						defaultValue={record.affected ? record.affected : []}
						onChange={(e) => handleChange(e, record)}
						mode="multiple"
						allowClear
						className="w-full"
						options={[
							{
								label: "Sex",
								options: [
									{
										label: "Connections",
										value: "Connections"
									},
									{
										label: "Relationships",
										value: "Relationships"
									}
								]
							},
							{
								label: "Social",
								options: [
									{
										label: "Fame",
										value: "Fame"
									},
									{
										label: "Popularity",
										value: "Popularity"
									},
									{
										label: "Self-esteem",
										value: "Self-esteem"
									}
								]
							},
							{
								label: "Survival",
								options: [
									{
										label: "Jobs",
										value: "Jobs"
									},
									{
										label: "Money",
										value: "Money"
									},
									{
										label: "Power",
										value: "Power"
									}
								]
							}
						]}
					/>;
				}
			},
			{
				title: "How did I react",
				dataIndex: "reaction",
				editable: true,
				width: "30%",
				hidden: values.content_left === "Anger Sheet table 2" ? true : false,
			},
			// section 4 table ends here
			// section 5 table starts here
			{
				title: "Who Or what do i fear?",
				dataIndex: "name",
				editable: true,
				width: "20%",
				hidden: values.content_left === "Anger Sheet table" ? true : false,
			},
			{
				title: "Why do i have this fear?",
				dataIndex: "fearCause",
				editable: true,
				width: "30%",
				hidden: values.content_left === "Anger Sheet table" ? true : false,
			},
			{
				title: "Are There any actions i took to cause Or cover the fear?",
				dataIndex: "fearAction",
				editable: true,
				width: "30%",
				hidden: values.content_left === "Anger Sheet table" ? true : false,
			},
			// section 5 table ends here
			// common column 
			{
				title: 'Action',
				dataIndex: 'action',
				align: "center",
				width: '10%',
				// hidden:values.content_left === "Anger Sheet table 2"?true:false,
				render: (_, record) => {
					return <Space>
						<Tooltip title={constants.Save}>
							<Button type="link" disabled={disable} onClick={() => { handleActionSave(record); }} icon={<SaveOutlined />} /></Tooltip>
						{tableOneDataSource.length > 1 && (
							<Tooltip title={constants.Delete}>
								<Popconfirm disabled={disable} title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
									<Button type="link" danger icon={<DeleteOutlined />} />
								</Popconfirm></Tooltip >
						)}
					</Space>;
				}
			},
		].filter(item => !item.hidden);
		const tableTwoColumnsNoDrodown = [
			// section 6 table column starts here
			{
				title: 'People or Institution we have hurt by our actions arising out of character flaws',
				dataIndex: 'name',
				width: '40%',
				editable: true,
			},
			{
				title: "What did we do or say to them?",
				dataIndex: "problem",
				editable: true,
				width: "25%",
			},
			{
				title: "How can we make this right?",
				dataIndex: "affected",
				editable: true,
				width: "25%",
			},
			{
				title: 'Action',
				dataIndex: 'action',
				align: "center",
				width: '10%',
				render: (_, record) => {
					return <Space>
						<Tooltip title={constants.Save}>
							<Button type="link" disabled={disable} onClick={() => { handleActionSave(record); }} icon={<SaveOutlined />} /></Tooltip>
						{tableOneDataSource.length > 1 && (
							<Tooltip title={constants.Delete}>
								<Popconfirm disabled={disable} title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
									<Button type="link" danger icon={<DeleteOutlined />} />
								</Popconfirm></Tooltip >
						)}
					</Space>;
				}
			},
		];
		const column2 = tableTwoColumns.map((col) => {
			if (!col.editable) {
				return col;
			}
			return {
				...col,
				onCell: (record) => ({
					record,
					editable: col.editable,
					dataIndex: col.dataIndex,
					disable: disable,
					handleAdd
				}),
			};
		});
		const column3 = tableTwoColumnsNoDrodown.map((col) => {
			if (!col.editable) {
				return col;
			}
			return {
				...col,
				onCell: (record) => ({
					record,
					editable: col.editable,
					dataIndex: col.dataIndex,
					tableName: "tableCode",
					disable: disable,
					handleAdd
				}),
			};
		});
		return (
			<>
				<div>
					{
						values.content_left === "Anger Sheet table" || values.content_left === "Anger Sheet table 2" || values.content_left === "table code" ?
							<Table
								scroll={{ x: 900 }}
								components={components}
								rowClassName={() => 'editable-row'}
								bordered
								dataSource={tableOneDataSource}
								columns={values.content_left === "table code" ? column3 : column2}
								pagination={false}
								title={() => values?.isEdited ? <CheckCircleTwoTone /> : null}
							/> : <div className="w-2/5 mx-auto">
								<Table
									components={components}
									rowClassName={() => 'editable-row'}
									bordered
									dataSource={tableOneDataSource}
									columns={column}
									pagination={false}
									title={() => values?.isEdited ? <CheckCircleTwoTone /> : null}
								/></div>}
				</div>
			</>
		);
	};
	// Table with header & sub header having checkboxes in each column.
	const template18 = (values, position, disable) => {
		// let tempTableCheckbox
		const onChangeCheckboxTable = (checkedValues) => {
			workBookData[counter][position].client_ans = checkedValues;
			setTableCheckboxValue(checkedValues);
			setReload(!reload);
		};
		if (tableCheckboxValue.length > 0) {
			if (tempArray.length === 0) {
				if (values.content_left === "table with checkboxes code?") {
					let payload = null;
					payload = {
						item_id: values.item_id,
						section_id: values.section_id
					};
					if (values?.is_discussed === 1) {
						payload.is_changed = 1;
					}
					tempArray.push(payload);
				}
			}
		}
		const column = [
			{
				title: 'My List',
				dataIndex: 'mylist',
				width: '50%',
			},
		];
		let datasource = [];
		if (values.content_left === "table checkbox value") {
			if (values.client_ans) {
				if (values.client_ans.length > 0) {
					datasource.length = 0;
					for (let i = 0; i < values.client_ans.length; i++) {
						datasource.push({ mylist: values.client_ans[i] });
					}
				}
			}
		};
		//To show or hide Spiritual column.
		const includedOptions = values?.client_ans?.filter(answer =>
			constants.SpiritualOptions?.some(option => option.value === answer)
		);
		return (
			<>
				{values.content_left === "table with checkboxes code?" ?
					<div className='overflow-x-auto'>
						<Checkbox.Group disabled={disable} onChange={onChangeCheckboxTable} value={workBookData[counter][position].client_ans}>
							<table className="w-full blue-header">
								{values?.isEdited ? <caption className="text-left"><CheckCircleTwoTone /></caption> : null}
								<thead>
									<tr>
										<th>General</th>
										{
											includedOptions?.length > 0 ?
												<th>Spiritual</th> :
												null
										}
										<th>Buddhism</th>
										<th>Christianity</th>
										<th>Hinduism</th>
										<th>Sikhism</th>
										<th>Islam</th>
									</tr>
									<tr>
										<th>The greatest defects of humans are</th>
										{
											includedOptions?.length > 0 ?
												<th>Social Evils</th> :
												null
										}
										<th>Obscurations</th>
										<th>Sins</th>
										<th>Skhandas</th>
										<th>Enemies of Man</th>
										<th>Nafs</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<Space direction="vertical">
												{constants.GeneralOptions.map((option) => {
													return <Tooltip title={option.tooltip} color='#075985'>
														<div className='flex items-center'>
															<Checkbox key={option.value} value={option.value}>{option.label}
															</Checkbox>
														</div>
													</Tooltip>
												})}
											</Space>
										</td>
										{
											includedOptions?.length > 0 ?
												<td>
													<Space direction="vertical">
														{constants.SpiritualOptions.map((option) => {
															return <Tooltip title={option.tooltip} color='#075985'>
																<div className='flex items-center'>
																	<Checkbox key={option.value} value={option.value}>{option.label}
																	</Checkbox>
																</div>
															</Tooltip>
														})}
													</Space>
												</td> :
												null
										}
										<td>
											<Space direction="vertical">
												{constants.BuddhismOptions.map((option) => {
													return <Tooltip title={option.tooltip} color='#075985'>
														<div className='flex items-center'>
															<Checkbox key={option.value} value={option.value}>{option.label}
															</Checkbox>
														</div>
													</Tooltip>
												})}
											</Space>
										</td>
										<td>
											<Space direction="vertical">
												{constants.ChristianityOptions.map((option) => {
													return <Tooltip title={option.tooltip} color='#075985'>
														<div className='flex items-center'>
															<Checkbox key={option.value} value={option.value}>{option.label}
															</Checkbox>
														</div>
													</Tooltip>
												})}
											</Space>
										</td>
										<td>
											<Space direction="vertical">
												{constants.HinduismOptions.map((option) => {
													return <Tooltip title={option.tooltip} color='#075985'>
														<div className='flex items-center'>
															<Checkbox key={option.value} value={option.value}>{option.label}
															</Checkbox>
														</div>
													</Tooltip>
												})}
											</Space>
										</td>
										<td>
											<Space direction="vertical">
												{constants.SikhismOptions.map((option) => {
													return <Tooltip title={option.tooltip} color='#075985'>
														<div className='flex items-center'>
															<Checkbox key={option.value} value={option.value}>{option.label}
															</Checkbox>
														</div>
													</Tooltip>
												})}
											</Space>
										</td>
										<td>
											<Space direction="vertical">
												{constants.IslamOptions.map((option) => {
													return <Tooltip title={option.tooltip} color='#075985'>
														<div className='flex items-center'>
															<Checkbox key={option.value} value={option.value}>{option.label}
															</Checkbox>
														</div>
													</Tooltip>
												})}
											</Space>
										</td>
									</tr>
								</tbody>
							</table>
						</Checkbox.Group>
					</div> :
					<div className="w-2/5 mx-auto">
						<Table
							columns={column}
							dataSource={datasource}
							pagination={true}
						></Table>
					</div>}
			</>
		);
	};
	const template20 = () => {
		const clear = () => {
			sigPad.current.clear();
			setEsignValue("");
		};
		function handleEnd() {
			// Get the data URL of the signature image
			const dataUrl = sigPad.current.toDataURL();
			setEsignValue(dataUrl);
		}
		return (
			<Card title={<h3>Signature</h3>}>
				<div className="sigContainer">
					<SignaturePad canvasProps={{ className: 'sigPad' }}
						ref={sigPad}
						onEnd={handleEnd}
					/>
					<Tooltip title="Clear">
						<Button type="text" danger className="absolute bottom-0 right-0 m-4" onClick={clear} icon={<DeleteOutlined />} />
					</Tooltip>
				</div>
			</Card>
		);
	};
	//get client Edited Data
	const getClientEditedData = () => {
		let adminDiscussionValuefromSession = JSON.parse(sessionStorage.getItem("adminDiscussionValue"));
		let client_id = null;
		if (loginType === "1" || loginType === "2") {
			client_id = adminDiscussionValuefromSession?.clientId;
		} else {
			client_id = getCookie(COOKIE.ClientId);
		}
		fetchAdminApi(`/review-data/${client_id}`, "get").then((res) => {
			if (res?.code === 200 && res?.data) {
				setEditedData(res.data);
			}
		});
	};
	//Instruction modal
	const showInstructionModal = () => {
		setInstructionModalOpen(true);
	};
	const handleInstructionmodalOk = () => {
		setInstructionModalOpen(false);
	};
	const getModal = () => {
		//popup for esign message
		if (sectionId === "0") {
			return popupMessage(
				null,
				esignMessageModalOpen,
				handleEsignMessageModalOk,
				counter === constants.SectionZeroPart.coachingAgreementpagetwo ? constants.EsignPopupMessageConsentForm : constants.EsignPopupMessage,
				null,
				null,
				null
			);
		} else {
			return popupMessage(
				constants.WorkbookHeaderText,
				instructionModalOpen,
				handleInstructionmodalOk,
				token === "refresher-workbook" ? constants.WorkbookInstructionsTextRefresher : constants.WorkbookInstructionsText,
				token === "refresher-workbook" ? null : `${sectionId}.`,
				constants.WorkbookInstructionsText2,
				constants.complete4
			);
		}
	};
	const disableTemplate3 = (sectionResponse) => {
		let is_disable;
		if (loginType === "0") {
			if (sectionId === "1" || sectionId === "8") {
				is_disable = true;
			}
			else if (sectionId === "7") {
				if (sectionResponse?.heading === null || sectionResponse?.heading === "") {
					is_disable = false;
				} else {
					is_disable = true;
				}
			}
			else if (props?.location?.pathname === "/refresher-workbook") {
				if (counter === 3) {
					is_disable = true;
				} else {
					for (let index = 0; index < workBookData[counter + 1].length; index++) {
						if (workBookData[counter + 1][index]?.section_res) {
							is_disable = true;
						}
					}
				}
			}
		} else {
			is_disable = true
		}
		return is_disable;
	};
	const displayWorkBooks = () => {
		let data = [];
		Object.keys(workBookData).forEach(function (key, index, arr) {
			if (workBookData) {
				if (key === counter.toString()) {
					data.push(workBookData[key]);
				}
			}
		});
		let menuList = [];
		if (data && data.length > 0) {
			for (const element of data) {
				for (let j = 0; j < element.length; j++) {
					if (isEditedByClient) {
						if (editedData.length > 0) {
							for (let index = 0; index < editedData.length; index++) {
								if (element[j]?.item_id === editedData[index]?.item_id) {
									element[j].isEdited = true;
								}
							}
						}
					}
					if (element[j]?.template_id === "3" && element[j]?.section_res) {
						for (let index = 0; index < element[j]?.section_res?.length; index++) {
							const element1 = element[j]?.section_res[index];
							if (element1?.response) {
								menuList.push(template3(element1?.heading, element1, j, element[j]?.type_left, disableTemplate3(element1), index, element1?.heading && element1?.response ? "tooltip1" : "tooltip2"));
							}
							if (element1?.response === "" || element1?.response === null) {
								menuList.push(template3(element1?.heading, element1, j, element[j]?.type_left, disableTemplate3(element1), index, "tooltip2"));
							}
						}
					}
					if (element[j]?.template_id === "0") {
						menuList.push(template0(element[j]?.title, element[j]?.option_msg, element[j]?.scale_msg));
					}
					if (element[j]?.template_id === "1") {
						menuList.push(template1(element[j]?.content_left, element[j]?.type_left));
					}
					if (element[j]?.template_id === "2") {
						if (element[j]?.item_id === "699") {
							let newData = element[j]?.content_left
								.replace("parent_name", parentName).replace("child_name", clientName);
							menuList.push(template2(element[j]?.title, newData, element[j]?.option_msg, element[j]?.scale_msg));
						} else {
							menuList.push(template2(element[j]?.title, element[j]?.content_left, element[j]?.option_msg, element[j]?.scale_msg));
						}
					}
					if (element[j]?.template_id === "3" && !element[j]?.section_res) {
						menuList.push(template3(element[j]?.content_left, element[j], j, element[j]?.type_left, adminDiscussionValue?.isAdmin ? true : element[j]?.is_disable === "t" ? true : false));
					}
					if (element[j]?.template_id === "4") {
						menuList.push(template4(element[j]?.content_left, element[j]?.content_right, element[j]?.type_left));
					}
					if (element[j]?.template_id === "5") {
						menuList.push(template5(element[j]?.content_left, element[j], j, adminDiscussionValue?.isAdmin ? true : false));
					}
					if (element[j]?.template_id === "6") {
						menuList.push(template6(element[j]?.content_left, element[j]?.title, element[j]?.video_url));
					}
					if (element[j]?.template_id === "7") {
						menuList.push(template7(element[j]?.content_left, element[j], j, adminDiscussionValue?.isAdmin ? true : false));
					}
					if (element[j]?.template_id === "8") {
						menuList.push(template8(element[j]?.content_left, element[j], j, adminDiscussionValue?.isAdmin ? true : false));
					}
					if (element[j]?.template_id === "9") {
						menuList.push(template9(element[j]?.stress_chart_result));
					}
					if (element[j]?.template_id === "10") {
						menuList.push(template10(element[j]?.content_left));
					}
					if (element[j]?.template_id === "11") {
						menuList.push(template11(element[j]?.content_left, element[j]?.content_right, element[j]?.type_left, element[j]?.type_right));
					}
					if (element[j]?.template_id === "12") {
						menuList.push(template12(element[j]?.content_left));
					}
					if (element[j]?.template_id === "13") {
						menuList.push(template13(element[j]?.content_left, element[j], j, adminDiscussionValue?.isAdmin ? true : element[j]?.is_disable === "t" ? true : false));
					}
					if (element[j]?.template_id === "14") {
						menuList.push(template14(element[j]?.content_left));
					}
					if (element[j]?.template_id === "15") {
						menuList.push(template15(element[j], adminDiscussionValue?.isAdmin ? true : false));
					}
					if (element[j]?.template_id === "16") {
						menuList.push(template16(element[j]?.content_left, element[j]?.type_left));
					}
					if (element[j]?.template_id === "17") {
						menuList.push(template17(element[j]?.content_left, element[j]?.content_right, element[j]?.type_left));
					}
					if (element[j]?.template_id === "18") {
						menuList.push(template18(element[j], j, adminDiscussionValue?.isAdmin ? true : false));
					}
					if (element[j]?.template_id === "19") {
						menuList.push(template19(element[j]?.content_left, element[j], j, adminDiscussionValue?.isAdmin ? true : false));
					}
					if (element[j]?.template_id === "20") {
						menuList.push(template20());
					}
					if (element[j]?.template_id === "21") {
						menuList.push(luscherColorTest(boxes, handleBoxClick, element[j]?.item_id, element[j]?.client_ans?.length > 0 ? "test taken" : null, loginType === "1" ? adminClientValuefromSession?.client_name : null, resetLusherColor, downloadResult));
					}
					if (element[j]?.template_id === "22") {
						menuList.push(uploadFile(fileList, handleFileChange, handleRemove, beforeUpload, "age-proof"));
					}
					if (element[j]?.template_id === "23") {
						menuList.push(template23(element[j]?.content_left, element[j]?.client_ans));
					}
					if (element[j]?.template_id === "24") {
						menuList.push(checkboxGrid(element[j]?.content_left, element[j], j, adminDiscussionValue?.isAdmin ? true : element[j]?.is_disable === "t" ? true : false));
					}
					if (element[j]?.template_id === "26") {
						menuList.push(template26(element[j]?.content_left, element[j], j, adminDiscussionValue?.isAdmin ? true : element[j]?.is_disable === "t" ? true : false));
					}
					if (element[j]?.template_id === "27") {
						menuList.push(template27(element[j]?.content_left, element[j]?.client_ans));
					}
					if (element[j]?.template_id === "28") {
						menuList.push(template28(element[j]?.client_ans));
					}
				}
			}
			return <Col span={24}>{menuList}</Col>;
		}
	};
	const downloadResult = () => {
		let documentValue = {
			client_id: adminClientValuefromSession?.clientId,
			file_type: "result-pdf",
		};
		sessionStorage.setItem("documentValue", JSON.stringify(documentValue));
		window.open(constants.BaseUrl + `/view-document`);
		// window.open(constants.ApiUrl + `/download-test-pdf/${adminClientValuefromSession?.clientId}`,"_blank");
	};
	const resetLusherColor = () => {
		let payload = { client_id: adminClientValuefromSession?.clientId };
		fetchAdminApi(`/reset-luscher-color`, "post", payload).then((res) => {
			if (res && res.code === 200) {
				fetchWorkbookSections();
				message.success(res.message);
			} else {
				message.success(res.message);
			}
		});
	};
	//function to save and close
	const handleSaveAndClose = () => {
		if (loginType === "0") {
			fetchWorkbookSections();
			if (tempArray.length > 0 || validationArray.length > 0 || tableOneDataSource?.length > 0 || tableCheckboxValue?.length > 0) {
				savingTableDataToDb();
				if (tempArray.length > 0) {
					savingDatatoDb();
				}
				if (validationArray.length > 0) {
					checkTableValidation(false,"flag");
				}
			}
		}
		let clientId = null;
		let state = null;
		if (adminDiscussionValue?.clientId) {
			let adminClientValuefromSession = JSON.parse(sessionStorage.getItem("adminClientValue"));
			clientId = adminDiscussionValue?.clientId;
			state = {
				isAdmin: true,
				clientId: adminDiscussionValue?.clientId,
				client_name: adminDiscussionValue?.client_name,
				client_serial_number: adminClientValuefromSession?.client_serial_number
			};
		} else {
			clientId = getCookie(COOKIE.ClientId);
		}
		let payload = {
			section_id: props?.location?.pathname === "/refresher-workbook" ?"100":sectionId,
			is_admin: loginType,
			part_id: counter
		};
		if (noteValue) {
			message.error(constants.notesVM);
		} else {
			fetchApi(`/save-and-close/${clientId}`, "put", payload).then((res) => {
				if (res && res.code === 200) {
					message.success(res.message);
					history.push({
						pathname: "/dashboard",
						state: state
					});
				} else {
					message.error(res.message);
				}
			});
		}
	};
	const handleResume = () => {
		//logintype 0 = client side and logintype 1 = admin side
		if (loginType === "0") {
			if (isResume.client_resume !== null) {
				fetchWorkbookSections();
				handleSetTemplate3formValue(workBookData, "resume", isResume.client_resume);
				setCounter(parseInt(isResume.client_resume));
			}
		} else {
			if (isResume.admin_resume !== null) {
				fetchWorkbookSections();
				handleSetTemplate3formValue(workBookData, "resume", isResume.admin_resume);
				setCounter(parseInt(isResume.admin_resume));
			}
		}
	};
	const showEsignMessageModal = () => {
		setEsignMessageModalOpen(true);
	};
	const handleEsignMessageModalOk = () => {
		setEsignMessageModalOpen(false);
		setCounter(counter + 1);
		handleSetTemplate3formValue(workBookData, "onNext");
		sessionStorage.setItem('count', counter + 1);
	};
	const requiredFields = ['733','1091','1092','762'];

	const validateRequiredFormFields = (fieldNames) => {
		return fieldNames?.every((fieldName) => {
			if (requiredFields.includes(fieldName)) {
				const fieldValue = form.getFieldValue(fieldName);
	
				if (fieldValue !== undefined && fieldValue !== null && fieldValue !== '') {
					return true; // Continue to the next field
				} else {
					return false; // Break the loop, as one required field doesn't have a value
				}
			} else {
				return true; // Non-required fields are considered valid
			}
		});
	}

	const handleSubmitSection0 = () => {
		// calling store-signature api for e-sign
		if (counter === constants.SectionZeroPart.coachingAgreementpagetwo || counter === constants.SectionZeroPart.suicidelForm || token.includes("medical-info") || counter === partCount) {
			let valdaterequredfield = validateRequiredFormFields(['733', '1091']);
			if (esignValue && counter === constants.SectionZeroPart.coachingAgreementpagetwo || esignValue && counter === constants.SectionZeroPart.suicidelForm && valdaterequredfield === true || token.includes("medical-info") || counter === partCount) {
				setLoader(true);
				let payload = {
					client_id: getCookie(COOKIE.ClientId),
					image: esignValue,
				};
				if (counter === constants.SectionZeroPart.coachingAgreementpagetwo) {
					payload.client_ans = tempArray?.[0]?.textValue;
					payload.form_type = "1";
					payload.pdfValue = pdfValue;
				}
				if (counter === constants.SectionZeroPart.suicidelForm) {
					payload.client_ans = tempArray
					payload.form_type = "2";
				}
				if (token.includes("medical-info")) {
					payload.form_type = "3";
					payload.client_ans = medicalFormData
				}
				if (counter === constants.SectionZeroPart.releaseFormpagetwo) {
					payload.form_type = "3";
					payload.client_ans = medicalFormData;
					payload.send_agreement_email = "1";
				}
				fetchApi(`/store-signature`, "post", payload).then((res) => {
					if (res?.code === 200) {
						if (token.includes("medical-info")) {
							setMedicalFormData([]);
							if(adminClientValuefromSession?.count){
								sessionStorage.removeItem("adminClientValue");
								history.push({pathname:"/dashboard",state:{section_zero_counter:adminClientValuefromSession?.count}});
							}else{
								history.push("/dashboard");
							}
						}
						if (counter === constants.SectionZeroPart.suicidelForm) {
							setTempArray([]);
						}
						setTimeout(() => {
							if (counter === constants.SectionZeroPart.releaseFormpagetwo && sectionId === "0") {
								let userIdFromCookies = getCookie(COOKIE.ClientId);
								let payload = {};
								payload.section_id = props?.location?.pathname === "/refresher-workbook" ? "100" : sectionId;
								payload.flag = "2";
								payload.is_admin = "0";
								fetchAdminApi(`/update-client-sections/${userIdFromCookies}`, "put", payload).then((res) => {
									if (res?.code === 200) {
										history.push({ pathname: "/dashboard" });
									} else {
										message.error(res.message);
									}
								});
							}
						}, 2000);
						if(counter !== partCount){
							showEsignMessageModal();
							setLoader(false);
							setEsignValue("");
						}
					} else {
						message.error(res.message);
						setLoader(false);
						setEsignValue("");
					}
				});
			}
			else {
				if (counter === constants.SectionZeroPart.coachingAgreementpagetwo) {
					message.error(constants.signVM);
				} else {
					if (!esignValue) {
						message.error(constants.signMedicalVM);
					}
					let valdaterequredfield = validateRequiredFormFields(['733', '1091']);
					if(!valdaterequredfield){
						message.error(constants.section0VMCF)
					}
				}
			}
		}
		else {
			// let check = workBookData?.[constants.SectionZeroPart.profile]?.some(user => user.client_ans !== null);
			form.validateFields().then((values) => {
				let checkboxPresent = tempArray.find((user) => user.option_data);
				//userAnser already exist than procceed
				let userAnswer = workBookData?.[counter].find((user) => user.check_box === "t")
				if (values && tempArray.length > 0 && checkboxPresent || values && userAnswer ) {
					if(tempArray.length > 0){
						setLoader(true);
						let clientId = getCookie(COOKIE.ClientId);
						let payload = {
							client_id: clientId,
							client_data: tempArray,
						};
						fetchApi(`/add-answers`, "post", payload)
							.then((res) => {
								if (res?.code === 200) {
									setLoader(false);
									setCounter(counter + 1);
									sessionStorage.setItem('count', counter + 1);
									setTempArray([]);
								} else {
									setLoader(false);
									message.error(res.message);
								}
							});
					}else{
						handleNextPart();
					}
				} else {
					message.error(constants.section0VM);
				}
			}).catch((errorInfo) => {
				if (errorInfo) {
					message.error(constants.section0VM);
				}
			});
		}
	};
	const hideModal = () => {
		setNoteValue(null);
		setNoteTypeValue("0");
		setnotesVisible(false);
	};
	const createNote = (type) => {
		let admin_id = getCookie(COOKIE.ClientId);
		let payload = {
			client_id: adminDiscussionValue?.clientId,
			notes_content: noteValue[0] ? "<b><u>DATA:</u></b>" + " " +noteValue[0]: "",
			notes_assesment: noteValue[1] ? "<b><u>ASSESSMENT:</u></b>" + " " +noteValue[1]:"",
			notes_plan: noteValue[2] ? "<b><u>PLAN:</u></b>" + " " +noteValue[2]: "",
			section_id: props?.location?.pathname === "/refresher-workbook" ?"100":sectionId,
			admin_id: admin_id,
			notes_type: noteTypeValue,
			dt_created:`${noteDate.year}-${noteDate.month}-${noteDate?.date}`
		};
		if (sessionChecked === true) {
			payload.session_checked = sessionChecked;
		}
		if (type === "esign") {
			payload.signed_by = admin_id;
		}
		if (noteValue !== null) {
			fetchAdminApi(`/add-notes`, "post", payload).then((res) => {
				if (res && res.code && res.code === 200) {
					setNoteDate({
						...noteDate,
						date: current.getDate(),
						month: String(current.getMonth() + 1),
						year: current.getFullYear()
					});
					message.success(res.message);
				} else {
					message.error(res.message);
				}
			});
		}
		hideModal();
	};
	const onchangeNoteValue = (e, index) => {
		noteValue[index] = e;
	};
	const routeToListNotes = () => {
		window.open(constants.BaseUrl + "/notes");
	};
	const handleClose = () => {
		if (noteValue) {
			message.error(constants.notesVM);
		} else {
			history.push({ pathname: "/dashboard" });
		}
	};
	const addNotes = () => {
		document.getElementById('top').scrollIntoView({ behavior: "smooth" });
		getForm(noteTypeValue);
		setnotesVisible(true);
	};
	const buttonComponent = () => {
		let btnstyle = null;
		//for complete and discussed button style.
		if (loginType === "0" || is_parent_concent || is_payment_agreement) {
			//client side
			btnstyle = 'bg-sky-800 text-white uppercase';
		} else {
			//admin side
			btnstyle = 'bg-amber-300 black-white uppercase';
		}
		let tickMarak = false;
		if (lastDiscussedPartId) {
			if (counter <= parseInt(lastDiscussedPartId)) {
				tickMarak = true;
			}
		}
		let sectionStatus = JSON.parse(sessionStorage.getItem("adminDiscussionValue"));
		if (props?.location?.state?.status === "3" || sectionStatus?.status === "3") {
			tickMarak = true;
		}
		return <div className="w-full">
			<div>
				<Col span={24}>
					<Space size={'small'} wrap>
						{/* tick icon if admin has dissed the page */}
						{tickMarak ? (<CheckOutlined />) : null}
						{/* dissused button condition */}
						{loginType === "1" && counter < partCount && sectionId !== "0" && (!is_parent_concent || !is_payment_agreement) || loginType === "2" && counter < partCount && sectionId !== "0" && (!is_parent_concent || !is_payment_agreement) ?
							(<Button className='bg-amber-300 text-black uppercase' onClick={handleAdminDiscussed}>{constants.Discussed}</Button>) :
							null
						}
						{/* submit button condition for admin and client side */}
						{
							sectionId === "0" || (token.includes("medical-info") && counter===partCount)?
								counter === constants.SectionZeroPart.coachingAgreementpagetwo || counter === constants.SectionZeroPart.suicidelForm || token.includes("medical-info") || counter === partCount && sectionId === "0"?
									(<Button className='bg-sky-800 text-white uppercase' onClick={handleSubmitSection0}>{constants.Submit}</Button>)
									: null
								: counter === partCount && isRefresher === false || counter === partCount && isRefresher && props?.location?.pathname === "/refresher-workbook" ? (<Button className={btnstyle} onClick={handleSubmit}>{adminDiscussionValue?.isAdmin ? constants.Completed : constants.Submit}</Button>)
									: null}
						{/* close button for admin */}
						{loginType === "1" && counter === partCount && (!is_parent_concent || !is_payment_agreement) || loginType === "2" && counter === partCount && (!is_parent_concent || !is_payment_agreement) ?
							(<Button className='bg-sky-800 text-white uppercase' onClick={handleClose}>{constants.CloseBtn}</Button>) :
							null
						}
						<Space className='mx-4'>
							{/* previous button condition */}
							{
								sectionId === "0" && counter === constants.SectionZeroPart.coachingAgreementpageone || sectionId === "0" && counter === constants.SectionZeroPart.suicidelForm || sectionId === "0" && counter === constants.SectionZeroPart.releaseFormpageone?
									null :
									counter >= 2 || counter === constants.SectionZeroPart.coachingAgreementpagetwo ?
										(<Button className='bg-sky-800 text-white uppercase' onClick={handlePreviousPart}>{constants.Previous}</Button>)
										: null}
							{/* next button condition */}
							{
								sectionId === "0" && counter === constants.SectionZeroPart.coachingAgreementpagetwo || sectionId === "0" && counter === constants.SectionZeroPart.suicidelForm ?
									null :
									counter < partCount ?
										(<Button className='bg-sky-800 text-white uppercase' onClick={handleNextPart}>{constants.Next}</Button>)
										: null}
						</Space>
						{/* save and close button condition*/}
						{
							//removed save and close for medical info release form
							sectionId === "0" || token.includes("medical-info") ?
								null :
								counter !== partCount && (!is_parent_concent && !is_payment_agreement) ?
									(<Button className='bg-sky-800 text-white uppercase' onClick={handleSaveAndClose}>{constants.SaveClose}</Button>)
									: null}
						{/* resume button condition for admin and client side */}
						{sectionId === "0" ? null : displayResumeButton()}
					</Space>
				</Col></div>
		</div>;
	};
	const displayResumeButton = () => {
		if (loginType === "0") {
			if (isResume?.client_resume && counter === 1) {
				return <Button className='bg-sky-800 text-white uppercase' onClick={handleResume}>{constants.Resume}</Button>;
			}
		} else {
			if (isResume?.admin_resume && counter === 1) {
				return <Button className='bg-sky-800 text-white uppercase' onClick={handleResume}>{constants.Resume}</Button>;
			}
		}
	};
	const fetchWorkbookSections = (onclick = null,pageno = null) => {
		setLoader(true);
		let token = queryString.substring(queryString.lastIndexOf('/') + 1);
		let adminDiscussionValuefromSession = JSON.parse(sessionStorage.getItem("adminDiscussionValue"));
		setNewUser(adminDiscussionValuefromSession?.new_user);
		setAdminDiscussionValue(adminDiscussionValuefromSession);
		let clientId = null;
		if (props?.location?.state?.isAdmin || adminDiscussionValuefromSession?.isAdmin) {
			clientId = props?.location?.state?.clientId || adminDiscussionValuefromSession?.clientId;
		} else {
			clientId = getCookie(COOKIE.ClientId);
		}
		let payload = {};
		payload.section = props?.location?.pathname === "/refresher-workbook" ? "100": sectionId;
		let latestSessionIdEnabled = sessionStorage.getItem("latestSessionEnabled");
		if (parseInt(latestSessionIdEnabled) >= parseInt(sectionId) || sectionId === "0" || props?.location?.pathname === "/refresher-workbook") {
			if (sectionId === "0" && loginType === "1" || sectionId === "0" && loginType === "2" ) {
				history.push("/client-list-open");
			} else {
				fetchApi(`/get-sections/${clientId}`, "post", payload).then((res) => {
					if (res?.data) {
						setLoader(false);
						if (props?.location?.pathname === "/refresher-workbook" && res?.initial_client_id === 0) {
							history.push("/dashboard")
						} else {
							setSectionPartTitle(res?.section_page_title);
							if(res?.section_page_title?.length > 0){
								if(res?.section_page_title[0]?.is_viewed){
									handleDrawerTableData(counter,res?.section_page_title,res.data);
								};
							};
							handleDrawerTableData(counter,res?.section_page_title,res.data)
							setWorkBookData(res.data);
							setIsResume(res?.is_resume);
							// setCountryName(res?.country_name);
							setlastDiscussedPartId(res?.last_discussed_part_id);
							setIsRefresher(res?.initial_client_id === 1 ? true : false);
							if (counter === 1 && pageno === null || pageno !==null && pageno === 1) {
								if (props?.location?.pathname !== "/workbook-section-0") {
									if (loginType === "0" && res?.show_section_popup === true) {
										showInstructionModal();
										setIsCompleteSectionPopup(true);
									} else {
										setIsCompleteSectionPopup(false);
									}
								}
							}
							if (onclick === "onNext") {
								handleSetTemplate3formValue(res.data, "onNext");
							} else if (onclick === "onPrevious") {
								handleSetTemplate3formValue(res.data, "onPrevious");
							} else {
								handleSetTemplate3formValue(res.data);
							}
							// to get the length of the workbook(to checkeck how many part(pages) are ther in a workbook)
							let objectLength = Object.keys(res?.data).length;
							setPartCount(objectLength);
							// if (res?.data?.section === "0") {
							// 	setisSection0(true);
							// } else {
							// 	setisSection0(false);
							// }
							setisData(true);
						}
					} else {
						setSectionPartTitle([]);
						setLoader(false);
						setisData(false);
						setIsRefresher(false);
					}
				});
			}
		} else if (is_parent_concent) {
			let token2 = token.substring(token.lastIndexOf('?') + 1);
			let enquiry_id = atob(token2);
			fetchApi(`/get-parent-consent/${enquiry_id}`, "get").then((res) => {
				if (res) {
					setLoader(false);
					if (res?.code === 200 && res?.consent_given === 1) {
						setisFileUploaded(true);
						setisData(true);
					} else if (res?.code === 200 && res?.data) {
						setisFileUploaded(false);
						setWorkBookData(res.data);
						handleSetTemplate3formValue(res.data);
						let parentName = res.data[counter]?.find((val) => val.content_left === "Parent's Name");
						let clientName = res.data[counter]?.find((val) => val.content_left === "Client Name");
						if (parentName) {
							setparentName(parentName?.client_ans);
						}
						if (clientName) {
							setClientName(clientName?.client_ans);
						}
						// to get the length of the workbook(to checkeck how many part(pages) are ther in a workbook)
						let objectLength = Object.keys(res?.data).length;
						setPartCount(objectLength);
						setisData(true);
					}
				} else {
					setLoader(false);
					setisData(false);
				}
			});
		}
		else if (adminDiscussionValuefromSession?.is_medical_info) {
			getMedicalForm();
		}
		else if (is_payment_agreement) {
			let token2 = token.substring(token.lastIndexOf('?') + 1);
			let enquiry_id = atob(token2);
			fetchApi(`/get-payment-agreement/${enquiry_id}`, "get").then((res) => {
				if (res) {
					setLoader(false);
					if (res?.code === 200 && res?.payment_signed === 1) {
						setisFileUploaded(true);
						setisData(true);
					} else if (res?.code === 200 && res?.data) {
						setisFileUploaded(false);
						setWorkBookData(res.data);
						handleSetTemplate3formValue(res.data);
						// to get the length of the workbook(to checkeck how many part(pages) are ther in a workbook)
						let objectLength = Object.keys(res?.data).length;
						setPartCount(objectLength);
						setisData(true);
					}
				} else {
					setLoader(false);
					setisData(false);
				}
			});
		}
		else {
			history.push("/");
		}
	};
	const handleSetTemplate3formValue = (data, onclick = null, resumeval = null) => {
		let values = {};
		let count = counter;
		if (onclick === "onNext") {
			count = count + 1;
		} else if (onclick === "onPrevious") {
			count = count - 1;
		} else if (onclick === "resume") {
			count = resumeval;
		}
		let found = false;
		for (let index = 0; index < data[count]?.length; index++) {
			if (data[count][index]?.template_id === "3" && data[count][index]?.section_res) {
				for (let i = 0; i < data[count][index]?.section_res.length; i++) {
					const element = data[count][index]?.section_res[i];
					if (element?.response) {
						let key = i;
						let value = element?.response;
						values[key] = value;
						found = true;
					}
					else{
						let key = i;
						let value = "";
						values[key] = value;
						found = true;
					}
				}
			} else if (data[count][index]?.template_id === "3" && !data[count][index]?.section_res) {
				let key = data[count][index].item_id;
				let value = data[count][index].client_ans;
				values[key] = value;
			}
			if (found) {
				break;
			}
		}
		for (const key in values) {
			if (Object.hasOwnProperty.call(values, key)) {
				form.setFieldsValue({ [key]: values[key] });
			}
		}
	};
	const checkTableValidation = (isFound,flag = null) => {
		let found = false;
		if (validationArray.length > 0) {
			for (let i = 0; i < validationArray?.length; i++) {
				if (validationArray[i].option_data === null) {
					found = true;
					break;
				}
			}
		}
		if (found === true) {
			message.error(constants.ValidationMessage);
		}
		else {
			let isClientAns = false;
			workBookData[counter].filter((item) => item.template_id === "8" || item.template_id === "7")
				.map((item) => {
					if (item.client_ans !== "" && item.client_ans !== null) {
						isClientAns = true;
					}
				});
			if (validationArray?.length === 1 && isClientAns === false) {
				if (validationArray[0].isStressTable === true || validationArray[0].isradioButton === true) {
					if (validationArray[0].isStressTable === true) {
						message.error(constants.ValidationMessage);
					}
					if (validationArray[0].isradioButton === true) {
						message.error(constants.ValidationMessage);
					}
				}
			} else {
				if (validationArray?.length > 1 ) {
					validationArray.shift();
					let clientId = getCookie(COOKIE.ClientId);
					let payload = {
						client_id: clientId,
						client_data: validationArray,
					};
					fetchApi(`/add-answers`, "post", payload)
						.then((res) => {
							if (res?.code === 200) {
								if(isFound){
									fetchWorkbookSections("onNext");
								}else if (loginType === "0"){
									setWBdata(validationArray,null);
								}
								setvalidationArray([]);
							}
						});
				}
				if(displayGamblingValidateMessage){
					setWBdata(validationArray,null);
					setCounter(counter + 3);
					sessionStorage.setItem('count', counter + 3);
				}
				//counter + 1 will only when onclick of next button
				else if (!flag) {
					setCounter(counter + 1);
					sessionStorage.setItem('count', counter + 1);
				}
			}
		}
	};
	const checkGamblingOption = (userData) => {
		let option1 = userData?.findIndex((item) => item?.item_id === "1036");//item id of option1 of gambling
		let option2 = userData?.findIndex((item) => item?.item_id === "1048");//item id of option2 of gambling
		let result;
		if( userData?.[option1]?.option_data === "2" && userData?.[option2]?.option_data === "2" || userData?.[option1]?.client_ans === "2" && userData?.[option2]?.client_ans === "2"){
			result = true;
		}else{
			result = false;
		}
		return result;
	}
	let displayGamblingValidateMessage = checkGamblingOption(validationArray?.length > 1 ? validationArray : workBookData[counter]);
	const savingTableDataToDb = () => {
		if (tableOneDataSource?.length > 0) {
			if (tempArray.length === 1) {
				tempArray[0].json_data = tableOneDataSource;
			}
		} else if (tableCheckboxValue?.length > 0) {
			if (tempArray.length === 1) {
				tempArray[0].json_data = tableCheckboxValue;
			}
		}
	};
	// Function to format the cost in the PDF.
	const formatNumber = (value, currency) => {
		if (!value) return '';
		if (currency === 'INR') {
			return `${value}`.replace(/(\d)(?=(\d\d)+\d(\.\d{0,2})?$)/g, '$1,');
		} else {
			return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		}
	};
	const getCurrency = (amount) => {
		if (amount.includes('INR')) return 'INR';
		if (amount.includes('USD')) return 'USD';
		return null;
	};
	const extractAmount = (amount) => {
		const matches = amount.match(/\d+(,\d{3})*(\.\d{1,2})?/);
		return matches ? matches[0] : '';
	};
	const formatAmountString = (amount) => {
		const currency = getCurrency(amount);
		const numericValue = extractAmount(amount).replace(/,/g, '');
		const formattedValue = formatNumber(numericValue, currency);
		return amount.replace(numericValue, formattedValue);
	};
	const getHtmlCode = () => {
		let html = containerRef.current.innerHTML;
		const container = document.createElement('div');
		container.innerHTML = html;
		// to replace the html string for special scholarship client.
		const searchScholarship = container.querySelectorAll("div[id=commit-to-pay]");
		const searchScholarshipText = container.querySelectorAll("div[id=commit-to-pay-text]");

		let perSessionCost = null;
		let programCost = null;
		let totalPlanCost = null;
		let wbdata = workBookData[counter];

		for (const element of wbdata) {
			if (element.content_left.includes("Per Session Cost") && element.client_ans !== null) {
				perSessionCost = element?.client_ans;
			} else if (element.content_left.includes("Program Cost") && element.client_ans !== null) {
				programCost = element?.client_ans;
			} else if (element.content_left.includes("Total Plan Cost") && element.client_ans !== null) {
				totalPlanCost = element?.client_ans;
			}
		}

		if (searchScholarship.length > 0) {
			searchScholarship.forEach(value => {
				const text = perSessionCost ? '<b class="color-red">Per Session Cost </b>' : '<b class="color-red">Program Cost </b>';
				const cost = perSessionCost || programCost || "";
				const formattedCost = formatAmountString(cost);
				value.insertAdjacentHTML('beforebegin', `${text}: <b>${formattedCost}</b>`);
				value.remove();
			});
			html = container.innerHTML;
		}

		if (searchScholarshipText.length > 0) {
			searchScholarshipText.forEach(value => {
				const text = '</br><b class="color-red">Total Plan Cost </b>';
				const formattedTotalCost = formatAmountString(totalPlanCost);
				value.insertAdjacentHTML('beforebegin', `${text}: <b>${formattedTotalCost}</b>`);
				value.remove();
			});
			html = container.innerHTML;
		}
		setpdfValue(html);
	};
	const handleAdminDiscussed = () => {
		if (loginType === "1" || loginType === "2") {
			handleSetTemplate3formValue(workBookData, "onNext");
			let sectionStatus = JSON.parse(sessionStorage.getItem("adminDiscussionValue"));
			if (props?.location?.state?.status !== "3" || sectionStatus?.status !== "3") {
				let noClientAns = false;
				workBookData[counter]?.filter((item) => {
					if (item.client_ans !== null) {
						return tempArray.push({
							section_id: item.section_id,
							item_id: item.item_id,
							is_admin: "1",
							part_id: counter
						});
					} else {
						noClientAns = true;
					}
				});
				if (noClientAns) {
					if (lastDiscussedPartId) {
						if (parseInt(lastDiscussedPartId) < counter) {
							tempArray.push({
								section_id: props?.location?.pathname === "/refresher-workbook" ?"100":sectionId,
								is_admin: "1",
								part_id: counter
							});
						}
					} else {
						tempArray.push({
							section_id: props?.location?.pathname === "/refresher-workbook" ?"100":sectionId,
							is_admin: "1",
							part_id: counter
						});
					}
				}
			}
			savingDatatoDb(null,"onNext");
			if(displayGamblingValidateMessage){
				setCounter(counter + 3);
				sessionStorage.setItem('count', counter + 3);
			}else{
				setCounter(counter + 1);
				sessionStorage.setItem('count', counter + 1);
			}
		}
		document.getElementById('top').scrollIntoView({ behavior: "smooth" });
	};
	const handleNextPart = () => {
		if(sectionId > 0 && loginType === "0"){
			handleDrawerTableData(counter,sectionPartTitle,workBookData);
		};
		let wbdata = workBookData[counter + 1];
		const isFound = wbdata?.length > 0 ? wbdata.some((element) => element?.section_res?.length > 0 || element?.type_left === "R1" || element?.type_left === "R2" || element?.type_left === "R3" ||  element?.type_left === "R4" || element?.type_left === "R5" || element?.content_left === "table checkbox value" || element?.type_left === "LCR" ) : false;
		//not resetting form field because there is validation for a required form field in section 0.
		if(sectionId !== "0" && counter !== constants.SectionZeroPart.releaseFormpageone){
			form.resetFields();
		}
		if (loginType === "1" || loginType === "2" || (is_parent_concent || is_payment_agreement)) {
			handleSetTemplate3formValue(workBookData, "onNext");
			if (is_parent_concent) {
				let parentName = workBookData[counter + 1]?.find((val) => val.content_left === "Parent's Name");
				let clientName = workBookData[counter + 1]?.find((val) => val.content_left === "Client Name");
				if (parentName) {
					setparentName(parentName?.client_ans);
				}
				if (clientName) {
					setClientName(clientName?.client_ans);
				}
				// if (selectValue && inputValue) {
				// 	setSelectValue(selectValue);
				// 	form.setFieldsValue({ "684": inputValue });
				// }
				if (counter === 2) {
					getHtmlCode();
				};
			}
			if (is_payment_agreement) {
				getHtmlCode();
			}
			// let commitmentTopay = workBookData[counter].find(element => element.item_id === constants.commitmentToPayitemId);
			// if (is_parent_concent && inputValue === null && commitmentTopay?.item_id) {
			// 	message.error(constants.commitmentToPayVM);
			// } else {
			// 	setCounter(counter + 1);
			// 	sessionStorage.setItem('count', counter + 1);
			// }
				if(displayGamblingValidateMessage){
					setCounter(counter + 3);
					sessionStorage.setItem('count', counter + 3);
				}else{
					setCounter(counter + 1);
					sessionStorage.setItem('count', counter + 1);
				}
		} else {
			if (validationArray?.length >= 1) {
				let isClientAns = false;
				workBookData[counter].filter((item) => item.template_id === "8" || item.template_id === "7")
					.map((item) => {
						if (item.client_ans !== "" && item.client_ans !== null) {
							isClientAns = true;
						}
					});
				if (isClientAns === false || validationArray?.length > 1) {
					checkTableValidation(isFound,null);
				} else {
					if(displayGamblingValidateMessage){
						setCounter(counter + 3);
					  sessionStorage.setItem('count', counter + 3);
					}else{
						setCounter(counter + 1);
						sessionStorage.setItem('count', counter + 1);
					}
				}
			}else {
				savingTableDataToDb();
				//sending html code for generating pdf.
				if (sectionId === "0" && counter === constants.SectionZeroPart.coachingAgreementpageone) {
					getHtmlCode();
				};
				if(tempArray.length > 0 && !token.includes("medical-info") && sectionId !== "0"){
					savingDatatoDb(null, "onNext",isFound);
				}else if(isFound){
					fetchWorkbookSections("onNext",counter + 1);
				}else if(token.includes("medical-info") || sectionId==="0" && counter+1 ===constants.SectionZeroPart.releaseFormpagetwo ){
					setMedicalFormData(tempArray)
				}
				if(counter === constants.SectionZeroPart.releaseFormpageone && sectionId === "0"){
					let valdaterequredfield = validateRequiredFormFields(['762', '1092']);
					if(!valdaterequredfield){
						message.error(constants.section0VMCF);
					}else{
						handleSetTemplate3formValue(workBookData, "onNext");
						setCounter(counter + 1);
						sessionStorage.setItem('count', counter + 1);
					}
				}else{
					handleSetTemplate3formValue(workBookData, "onNext");
					setCounter(counter + 1);
					sessionStorage.setItem('count', counter + 1);
				}
			}
			let lusherValue = workBookData[counter].find((item) => item.template_id === "21");
			if (colorSequence.length < 8 && loginType === "0" && lusherValue?.client_ans === null && lusherValue?.template_id === "21") {
				message.error("Please Answer the Lusher color test.");
				setCounter(counter);
				sessionStorage.setItem('count', counter);
			} else if (colorSequence.length === 8 && loginType === "0") {
				setColorSequence([]);
				setCounter(counter + 1);
				sessionStorage.setItem('count', counter + 1);
			}
			if (validationArray?.length === 1) {
				setvalidationArray([]);
			}
			if (isTableVisible?.length > 0) {
				setIsTableVisible([]);
			}
			// if(isFound){
			// 	fetchWorkbookSections("onNext");
			// }
		}
		document.getElementById('top').scrollIntoView({ behavior: "smooth" });
	};
	const handleDrawerTableData =(page_no,res_secparttittle,wbdata) => {
		let section_part_tittle = res_secparttittle;
		let find_page_index = section_part_tittle?.findIndex((val)=>Number(val.page_no) === Number(page_no));
		let check_is_answered_part = wbdata[page_no]?.find((val) => val.client_ans);
		if(find_page_index > -1){
			section_part_tittle[find_page_index].is_viewed = true;
		}
		if(check_is_answered_part){
			if(find_page_index > -1){
				section_part_tittle[find_page_index].is_edited = true;
			}
		};
		let viewed_section_page_title = section_part_tittle?.filter((val)=> val.is_viewed !== false);
		setGoToPageData(viewed_section_page_title);
	};
	const handleGoToPage = (page_no) => {
		if (page_no) {
			handleSetTemplate3formValue(workBookData, "resume", page_no);
			setCounter(Number(page_no));
			sessionStorage.setItem('count', Number(page_no));
		};
	}
	const handlePreviousPart = () => {
		let wbdata = workBookData[counter + 1];
		const isFound = wbdata?.length > 0 ? wbdata.some((element) => element?.section_res?.length > 0 || element?.type_left === "R1" || element?.type_left === "R2" || element?.type_left === "R3" ||  element?.type_left === "R4" || element?.type_left === "R5" || element?.content_left === "table checkbox value" || element?.type_left === "LCR" ) : false;
		let check = checkGamblingOption(workBookData[counter - 3]);
		if (loginType === "1" || is_parent_concent || loginType === "2" || is_payment_agreement) {
			handleSetTemplate3formValue(workBookData,"onPrevious");
			// if (selectValue && inputValue || countryName) {
			// 	setSelectValue(selectValue);
			// 	form.setFieldsValue({ "684": inputValue });
			// }
			if(check){
				setCounter(counter - 3);
			  sessionStorage.setItem('count', counter - 3);
			}else{
				setCounter(counter - 1);
				sessionStorage.setItem('count', counter - 1);
			}
		} else {
			if (validationArray?.length > 0) {
				setvalidationArray([]);
			}
			savingTableDataToDb();
			// if(tempArray.length > 0){
			// 	savingDatatoDb(null, "onPrevious",isFound);
			// }
			// if (sectionId === "0") {
			// 	if (tempArray.length > 0) {
			// 		savingDatatoDb(null, "onPrevious");
			// 	} else {
			// 		handleSetTemplate3formValue(workBookData, "onPrevious");
			// 	}
			// }else{
			// 	handleSetTemplate3formValue(workBookData, "onPrevious");
			// }
			handleSetTemplate3formValue(workBookData, "onPrevious");
			if(check){
				setCounter(counter - 3);
			  sessionStorage.setItem('count', counter - 3);
			}else{
				setCounter(counter - 1);
				sessionStorage.setItem('count', counter - 1);
			}
		}
		document.getElementById('top').scrollIntoView({ behavior: "smooth" });
	};
	const savingDatatoDb = (profilepayload = null, onclick = null,isFound = null) => {
		if (tempArray.length > 0 && (!is_parent_concent || !is_payment_agreement)) {
			let clientId = null;
			if (adminDiscussionValue?.clientId) {
				clientId = adminDiscussionValue.clientId;
			} else {
				clientId = getCookie(COOKIE.ClientId);
			}
			let payload = {
				client_id: clientId,
				client_data: tempArray,
			};
			// setMedicalFormData(tempArray);
			fetchApi(`/add-answers`, "post", payload)
				.then((res) => {
					if (res?.code === 200) {
						if (profilepayload) {
							message.success(constants.profileUpdate);
						}
						if(isFound){
							fetchWorkbookSections(onclick);
						}else if(loginType === "0"){
							setWBdata(tempArray,onclick,);
						};
						setTempArray([]);
					} else {
						if (profilepayload) {
							message.error(res.message);
						}
					}
				});
		}
	};
	// const stopBlockerCall=()=>{
	// 	setIsDirty(false);
	// }
	const setWBdata = (clientData,onclick = null) => {
		let  wb = workBookData;
		for (let index = 0; index < clientData?.length; index++) {
			let tempvalue = clientData[index]
			for (let index2 = 0; index2 < wb[counter]?.length; index2++) {
					if(tempvalue.item_id === wb[counter][index2]?.item_id){
						let clientAns;
						if(tempvalue?.textValue){
							clientAns = tempvalue?.textValue
						}else if(tempvalue?.option_data){
							if(wb[counter][index2].template_id === "5" && wb[counter][index2].template_id === "9" || wb[counter][index2].template_id === "7"){
								clientAns = tempvalue?.option_data
							}
						}else if(tempvalue?.json_data){
							clientAns = tempvalue?.json_data
						}
						wb[counter][index2].client_ans = clientAns 
					}else if(wb[counter][index2]?.section_res) {
						fetchWorkbookSections();
					}
			};
		}	
		if (onclick === "onNext") {
			handleSetTemplate3formValue(wb, "onNext");
		} else if (onclick === "onPrevious") {
			handleSetTemplate3formValue(wb, "onPrevious");
		} else {
			handleSetTemplate3formValue(wb);
		}
		setWorkBookData(wb);
		handleDrawerTableData(counter,sectionPartTitle,wb);
	};
	const handleSubmit = () => {
		// let userIdFromCookies = getCookie(COOKIE.ClientId);
		let state = null;
		let userIdFromCookies = null;
		if (adminDiscussionValue?.clientId) {
			let adminClientValuefromSession = JSON.parse(sessionStorage.getItem("adminClientValue"));
			userIdFromCookies = adminDiscussionValue.clientId;
			state = {
				isAdmin: true,
				clientId: adminDiscussionValue?.clientId,
				client_name: adminDiscussionValue?.client_name,
				client_serial_number: adminClientValuefromSession?.client_serial_number
			};
		} else {
			userIdFromCookies = getCookie(COOKIE.ClientId);
			let sectionStatus = JSON.parse(sessionStorage.getItem("adminDiscussionValue"));
			if (isCompleteSectionPopup || props?.location?.state?.status === "1" || sectionStatus?.status === "1") {
				state = {
					isPopUpMessage: true,
					sectionId: sectionId
				};
			}
		}
		savingDatatoDb();
		let payload = {};
		payload.section_id = props?.location?.pathname === "/refresher-workbook" ? "100":sectionId;
		payload.flag = "2";
		payload.is_admin = loginType;
		if (is_parent_concent) {
			setLoader(true);
			let findvalue = workBookData[counter].find(element => element.item_id === "699");
			let parentconcent = findvalue?.content_left
				.replace("parent_name", parentName).replace("child_name", clientName);
			let token2 = token.substring(token.lastIndexOf('?') + 1);
			let enquiry_id = atob(token2);
			let payload = {
				enquiry_id: enquiry_id,
				image: esignValue,
				text: parentconcent,
				pdfValue: pdfValue
			};
			// if (inputValue !== null) {
			// 	payload.amount = inputValue;
			// 	payload.currency = selectValue === "USD" ? "0" : "1";
			// }
			if (fileList.length > 0 && esignValue) {
				fetchApi(`/store-parent-consent`, "post", payload).then((res) => {
					if (res?.code === 200) {
						message.success(res.message);
						handleUpload();
						setLoader(false);
						fetchWorkbookSections();
					} else {
						setLoader(false);
					}
				});
			} else {
				setLoader(false);
				if (fileList.length === 0) {
					message.error(constants.ageProofVM);
				}
				if (!esignValue) {
					message.error(constants.signVM);
				}
			}
		} else if (is_payment_agreement) {
			setLoader(true);
			let token2 = token.substring(token.lastIndexOf('?') + 1);
			let enquiry_id = atob(token2);
			let payload = {
				enquiry_id: enquiry_id,
				image: esignValue,
				pdfValue: pdfValue
			};
			if (esignValue) {
				fetchApi(`/store-payment-agreement`, "post", payload).then((res) => {
					if (res?.code === 200) {
						message.success(res.message);
						setLoader(false);
						fetchWorkbookSections();
					} else {
						setLoader(false);
					}
				});
			} else {
				setLoader(false);
				message.error(constants.signPA);
			}
		} else {
			if (noteValue) {
				message.error(constants.notesVM);
			} else {
				setLoader(true);
				if (token.includes("medical-info")) {
					handleSubmitSection0();
				} else {
					fetchAdminApi(`/update-client-sections/${userIdFromCookies}`, "put", payload).then((res) => {
						if (res?.code === 200) {
							setLoader(false);
							if(token === "refresher-workbook"){
								if(isCompleteSectionPopup){
									setisModalOpen(true);
								}else{
									setisModalOpen(false);
								}
								history.push("/dashboard")
							}else{
								history.push({
									pathname: "/dashboard",
									state: state
								});
							}
							message.success(res?.message);
						} else {
							message.error(res.message);
							setLoader(false);
						}
					});
				}
			}
		}
		document.getElementById('top').scrollIntoView({ behavior: "smooth" });
	};
	const EmptyComponent = () => {
		return <Empty
			image={null}
			className="custom-ant-empty-image"
			description={
				<div className="text-center">
					<LikeOutlined className="text-8xl text-sky-800 mb-2.5" />
					<div className="text-lg">
						{sectionId.includes("consent") ? constants.ParentConsentMessage : constants.CallerMessage}
					</div>
					<br />
					<div className="text-lg">
						{constants.ParentConsentMessage2}
					</div>
				</div>
			}
		>
		</Empty>;
	};
	const redirectToEditProfile = () => {
		window.open(constants.BaseUrl + "/edit-client");
	};
	const getSubtitlePageName = () => {
		let subTitlePageName;
		if (sectionId === "0" && counter === constants.SectionZeroPart.welcome) {
			subTitlePageName = constants.WelcomePage;
		}
		else if (sectionId === "0" && counter === constants.SectionZeroPart.coachingAgreementpageone || sectionId === "0" && counter === constants.SectionZeroPart.coachingAgreementpagetwo) {
			subTitlePageName = constants.CoachingAgreement;
		}
		else if (sectionId === "0" && counter === constants.SectionZeroPart.releaseFormpageone || sectionId === "0" && counter === constants.SectionZeroPart.releaseFormpagetwo) {
			subTitlePageName = constants.ReleaseForm;
		}
		else if (sectionId === "0" && counter === constants.SectionZeroPart.suicidelForm ) {
			subTitlePageName = constants.SuicideForm;
		}
		else if (sectionId.includes("consent")) {
			subTitlePageName = constants.ParentConsentText;
		}
		else if (sectionId.includes("agreement")) {
			subTitlePageName = constants.PaymentAgreement;
		}
		else if (props?.location?.pathname === "/refresher-workbook") {
			subTitlePageName = constants.RefresherWorkSheet;
		}
		else if (adminClientValuefromSession?.is_medical_info) {
			subTitlePageName = constants.MedicalInfo;
		}
		else {
			subTitlePageName = constants.sec;
		}
		return <span className="py-0.5 px-3 ml-3 text-sm bg-white rounded-xl">{subTitlePageName === constants.sec ? <><span>{subTitlePageName} <span className='text-lg'>#{sectionId}</span></span> <span className="ml-3">Page <span className='text-lg'>#{counter}</span></span></>: subTitlePageName}</span>;
	};
	const getForm = (formType) => {
		setNoteTypeValue(formType);
		setLoader(true);
		let payload = {
			note_type: formType,
			client_id: adminClientValuefromSession?.clientId,
		};
		fetchAdminApi("/notes", 'post', payload).then((res) => {
			if (res?.code === 200) {
				setLoader(false);
				setNoteValue(res?.data?.data);
				if(res?.data?.is_note_answered === 1){
					setIsNoteAnswered(true);
				}else{
					setIsNoteAnswered(false);
				}
				// setNoteTitle(res?.data?.date);
			} else {
				setNoteValue([]);
				// setNoteTitle("");
				setLoader(false);
			}
		});
	};
	const getSectionSubTitle = (sec_id) => {
		switch (sec_id) {
			case "1":
				return constants.sec1sub;
			case "2":
				return constants.sec2sub;
			case "3":
				return constants.sec3sub;
			case "4":
				return constants.sec4sub;
			case "5":
				return constants.sec5sub;
			case "6":
				return constants.sec6sub;
			case "7":
				return constants.sec7sub;
			case "8":
				return constants.sec8sub;
			default:
				return ""
		}
	};
	// Filter `option.label` match the user type `input`
	const selectFilterOption = (input, option) => {
		return option?.children?.props?.children[2]?.props?.title?.toLowerCase().indexOf(input?.toLowerCase()) > -1 && option?.children?.props?.children[2]?.props?.title?.toLowerCase().indexOf(input?.toLowerCase()) < 1;
	};

	const getMedicalFormData = () => {
		sessionStorage.clear();
		let payload = {
			clientId:  getCookie(COOKIE.ClientId),
			client_name: getCookie(COOKIE.FirstName) + " " + getCookie(COOKIE.LastName),
			is_medical_info : true,
			count:counter
		};
		sessionStorage.setItem("adminClientValue", JSON.stringify(payload));
		window.open(constants.BaseUrl + "/medical-info");
	};

	function capitalizeFirstLetter(str) {
		return str?.toLowerCase()?.replace(/(?:^|\s)\S/g, function(a) {
			return a?.toUpperCase();
		});
	};

	const onSessionChange = (value) => {
		setSessionChecked(value);
	}

	return (
		<Spin spinning={loader} size="large">
			<div className='mb-5'>
				<div className='bg-amber-300 text-lg font-semibold p-3 flex flex-wrap items-center justify-center'>
					{constants.Subtitle}
					{getSubtitlePageName()}
				</div>
				{(loginType === "1" || loginType === "2" || ((is_parent_concent === false || is_payment_agreement === false) && loginType === "1")) ? (
					<div className='bg-amber-100 text-base font-semibold p-2 mb-2'>
						<div className="container mx-auto flex justify-between items-center">
							{
										loginType === "1" || loginType === "2" ?
											(
													<Space size={'small'} wrap direction="vertical">
														<span onClick={() => {
															history.push({
																pathname: "/edit-client",
																state: { client_id: adminClientValuefromSession?.clientId }
															});
														}} className="font-semibold text-base text-black cursor-pointer">{constants.AdminWorkbook} - {`${adminClientValuefromSession?.client_name ? adminClientValuefromSession?.client_name + " " : ""} (#${adminClientValuefromSession?.client_serial_number ? adminClientValuefromSession?.client_serial_number : ""})`}</span>
														<Space><Button size='small' className='text-xs font-semibold bg-sky-800 text-white uppercase' onClick={() => history.goBack()}>{constants.BackBtn}</Button>
															<Space className='mx-4'>
															{/* previous button condition */}
															{
																sectionId === "0" && counter === constants.SectionZeroPart.suicidelForm ?
																	null :
																	counter >= 2 ?
																		(<Button size='small' className='text-xs font-semibold bg-sky-800 text-white uppercase' onClick={handlePreviousPart}>{constants.Previous}</Button>)
																		: null}
															{/* next button condition */}
															{
																sectionId === "0" && counter === constants.SectionZeroPart.coachingAgreementpagetwo || sectionId === "0" && counter === constants.SectionZeroPart.suicidelForm?
																	null :
																	counter < partCount ?
																		(<Button size='small' className='text-xs font-semibold bg-sky-800 text-white uppercase' onClick={handleNextPart}>{constants.Next}</Button>)
																		: null}
															</Space>
															<Button size='small' className='text-xs font-semibold bg-sky-800 text-white uppercase' onClick={routeToListNotes}>{constants.ViewNotes}</Button>
															{
																loginType === "1" && (!is_parent_concent || !is_payment_agreement) && sectionId !== "0" || loginType === "2" && (!is_parent_concent || !is_payment_agreement) && sectionId !== "0" ?
																	<Button size='small' className='text-xs font-semibold bg-sky-800 text-white uppercase' onClick={addNotes}>{constants.AddNote}</Button>
																	: null
															}
															{loginType === "1" && sectionId !== "0" && (is_parent_concent === false || is_payment_agreement === false) || loginType === "2" && sectionId !== "0" && (is_parent_concent === false || is_payment_agreement === false) ? <Space className='mx-3'>
																<Button size='small' className='text-xs font-semibold bg-sky-800 text-white uppercase' onClick={redirectToEditProfile}>Profile</Button>
															</Space> : null}
															{/* go to page dropdown condition */}
															{
																sectionId > 0 ?
																	<Select
																		placeholder={constants.GoToPage}
																		className='!w-[350px]'
																		showSearch
																		allowClear
																		filterOption={selectFilterOption}
																		onChange={handleGoToPage}
																	>
																{goToPageData &&
																	goToPageData.map((goToPageData) => {
																		return <Option key={goToPageData.page_no} value={goToPageData.page_no}>
																			<Space>
																				{goToPageData?.is_edited ? <EditOutlined className='min-w-[15px]' /> : <div className='w-[15px]'></div>}
																				<span className='w-6 text-right block m-auto'>{goToPageData.page_no} : </span>
																				<p className="text-ellipsis overflow-hidden max-w-[240px]" title={capitalizeFirstLetter(`${goToPageData.page_title}`)}>{capitalizeFirstLetter(`${goToPageData.page_title}`)}</p>
																				{goToPageData?.is_enabled ? <UnlockOutlined className='min-w-[15px]' /> : <LockOutlined className='min-w-[15px]' />}
																			</Space>
																		</Option>
																	})}
																	</Select> : null
															}
															</Space>
													</Space>
											) :
											null
							}
						</div>
					</div>
				) : 
				<div className=' bg-amber-100 p-2 mb-2'>
					<div className='container mx-auto '>
						<Space className={newUser && 'flex justify-between'}>
							{/* previous button condition */}
							{
								sectionId === "0" && counter === constants.SectionZeroPart.coachingAgreementpageone /* || isProfileForm */ || sectionId === "0" && counter === constants.SectionZeroPart.suicidelForm || sectionId === "0" && counter === constants.SectionZeroPart.releaseFormpageone ?
									null :
									counter >= 2 || counter === constants.SectionZeroPart.coachingAgreementpagetwo ?
										(<Button size='small' className='text-xs font-semibold bg-sky-800 text-white uppercase' onClick={handlePreviousPart}>{constants.Previous}</Button>)
										: null}
							{/* next button condition */}
							{sectionId === "0" && counter === constants.SectionZeroPart.coachingAgreementpagetwo || sectionId === "0" && counter === constants.SectionZeroPart.suicidelForm ?
									null :
									counter < partCount ?
										(<Button size='small' className='text-xs font-semibold bg-sky-800 text-white uppercase' onClick={handleNextPart}>{constants.Next}</Button>)
										: null}
								{/* go to page dropdown condition */}
								{
									sectionId > 0 ?
										<Select
											placeholder={constants.GoToPage}
											className='!w-[350px]'
											showSearch
											allowClear
											filterOption={selectFilterOption}
											onChange={handleGoToPage}
										>
											{goToPageData &&
												goToPageData.map((goToPageData) => {
													return <Option key={goToPageData.page_no} value={goToPageData.page_no}>
														<Space>
															{goToPageData?.is_edited ? <EditOutlined className='min-w-[15px]' /> : <div className='w-[15px]'></div>}
															<span className='w-6 text-right block m-auto'>{goToPageData.page_no} : </span>
															<p className="text-ellipsis overflow-hidden max-w-[240px]" title={capitalizeFirstLetter(`${goToPageData.page_title}`)}>{capitalizeFirstLetter(`${goToPageData.page_title}`)}</p>
															{goToPageData?.is_enabled ? <UnlockOutlined className='min-w-[15px]' /> : <LockOutlined className='min-w-[15px]' />}
														</Space>
													</Option>
												})}
										</Select> : null
								}
								{newUser && (<Button size='small' className='text-xs font-semibold bg-sky-800 text-white uppercase' onClick={() => { history.push({pathname:"/my-profile",state:{counter:counter}}); }}>{constants.MyProfileText}</Button>)}
								{!token.includes("medical-info") && sectionId === "0" && <Button size='small' className='text-xs font-semibold bg-sky-800 text-white uppercase' onClick={getMedicalFormData}>{constants.ReleaseForm}</Button>}
						</Space>
					</div>
				</div>}
				<div className='container mx-auto mt-2 px-2'>
					{isData && (
						<Form layout='vertical' form={form}>
							<>
								{isFileUploaded
									?
									EmptyComponent() :
									<Row gutter={[8, 8]} className="disabled-dark">
										<div ref={containerRef} className='contents'>
											{displayWorkBooks()}
										</div>
										{buttonComponent()}
										{/* To display validate message for gambling section page. */}
										{displayGamblingValidateMessage ? (
											<div class="font-semibold text-lg">{constants.GamblingValidateMessage}</div>
										) : null}
									</Row>
								}
							</>
						</Form>
					)}
				</div>
				<div style={{ visibility: "hidden" }}>
					<b>partId:</b> {counter}
				</div>
				{getModal()}
				{noteValue && notesModel(hideModal, notesVisible, onchangeNoteValue, createNote, getForm, noteValue, noteTypeValue, isNoteAnswered, DOB(noteDate.month, handleNoteMonthChange, noteDate.date, handleNoteDayChange, noteDate.year, handleNoteYearChange, "Date", false), onSessionChange, sessionChecked, false, null, null)}
				<Modal
					title={
						<div>
							<TrophyOutlined className="text-5xl text-sky-900" />
						</div>
					}
					open={isModalOpen}
					onCancel={handleCancel}
					footer={null}
					className="welcome-modal text-center"
					width={410}
				>
					<div className="text-2xl font-bold mb-2.5 text-sky-900">
						{constants.complete}
					</div>
					<div className="text-lg font-semibold text-slate-500" type="secondary">
						{constants.RefresherCompletedMessage}
					</div>
				</Modal>
			</div>
		</Spin>
	);
};
export default Workbook;