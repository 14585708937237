import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Button, Card, Collapse, Form, Select, Space, Table } from 'antd';
import constants from '../../../constants/constants';
import { CaretRightOutlined, EyeFilled } from '@ant-design/icons';
import { fetchAdminApi } from '../../../services/api';
const { Panel } = Collapse;

const MasterDocs = () => {
  const history = useHistory();
  const [associatesData, setAssociatesData] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState(1);
  const [collapseItems, setCollapseItems] = useState(null);

  useEffect(() => {
    getAssociateList();
    return () => {
      sessionStorage.removeItem('agreementValue');
    }
  }, []);

  const getAssociateList = async () => {
    try {
      const res = await fetchAdminApi(`/view-associates`, "post", {});
      if (res?.code === 200) {
        const { newArray, removedItem } = removeItemById(res?.data, "1");
        const updatedArray = [removedItem, ...newArray];
        setAssociatesData(updatedArray);
        handleSelectChange(1);
      } else {
        setAssociatesData([]);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const removeItemById = (array, id) => {
    const removedItem = array.find(item => item.client_id === id);
    const newArray = array.filter(item => item.client_id !== id);
    return { newArray, removedItem };
  };

  const handleSelectChange = async (value) => {
    setSelectedClientId(value);
    if (!value) return;

    try {
      const response = await fetchAdminApi(`/master-workbook`, "post", {
        section: "",
        type: "",
        associate_id: value
      });

      if (response?.code === 200) {
        const { data } = response;
        const items = data && data.length > 0 ? generateCollapseItems(data, value) : null;
        setCollapseItems(items);
      } else {
        setCollapseItems(null);
      }
    } catch (error) {
      setCollapseItems(null);
    }
  };

  const generateCollapseItems = (data, associateId) => {
    const uniqueDurations = new Set(data.map(item => item.duration));
    return Array.from(uniqueDurations).map((duration, index) => {
      const itemsWithCurrentDuration = data.filter(item => item.duration === duration);
      const panelKey = `panel-${index}`;

      const tableData = itemsWithCurrentDuration.map(item => ({
        ...item,
        id: "0",
        type: "",
        doc: constants.CoachingAgreement,
        doctype: "C",
        section: getSectionName(item.is_scholarship),
        isScholarship: item.is_scholarship,
        associateId: associateId,
        header: durationLabels[duration],
      }));

      return (
        <Collapse.Panel key={panelKey} header={durationLabels[duration]}>
          <Table className='overflow-x-auto [text-align-last:center] m-3 borderTable collapseTable'
            columns={getColumns('Agreements', 'mx-auto')}
            pagination={false}
            dataSource={tableData.map(item => ({ ...item, key: item.id }))} />
        </Collapse.Panel>
      );
    });
  };

  const getSectionName = (isScholarship) => {
    switch (isScholarship) {
      case "1": return "Trauma";
      case "2": return "Youth & Women";
      case "3": return "All";
      default: return constants.CoachingAgreement;
    }
  };

  const getColumns = (firstColumnTitle, className) => [
    {
      title: firstColumnTitle,
      dataIndex: 'section',
      key: 'section',
      render: (text, record) => (
        <div className={className}>
          <button onClick={() => handleSectionClick(record.id, record.type, record.doc, record.doctype, record.section, record.duration, record.key, record.isScholarship, record.associateId, record.header)} className='text-inherit'>
            {text}
          </button>
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size='middle'>
          <Button
            icon={<EyeFilled />}
            onClick={() => handleSectionClick(record.id, record.type, record.doc, record.doctype, record.section, record.duration, record.key, record.isScholarship, record.associateId, record.header)}
            className='bg-sky-800 text-white uppercase'
          >
            View
          </Button>
        </Space>
      ),
    },
  ];

  const data1 = [
    { key: 'a1', type: "1", doc: constants.MasterWk, doctype: "A", section: constants.sec1 + ": " + constants.sec1sub, id: '1' },
    { key: 'a2', type: "1", doc: constants.MasterWk, doctype: "A", section: constants.sec2 + ": " + constants.sec2sub, id: '2' },
    { key: 'a3', type: "1", doc: constants.MasterWk, doctype: "A", section: constants.sec3 + ": " + constants.sec3sub, id: '3' },
    { key: 'a4', type: "1", doc: constants.MasterWk, doctype: "A", section: constants.sec4 + ": " + constants.sec4sub, id: '4' },
    { key: 'a5', type: "1", doc: constants.MasterWk, doctype: "A", section: constants.sec5 + ": " + constants.sec5sub, id: '5' },
    { key: 'a6', type: "1", doc: constants.MasterWk, doctype: "A", section: constants.sec6 + ": " + constants.sec6sub, id: '6' },
    { key: 'a7', type: "1", doc: constants.MasterWk, doctype: "A", section: constants.sec7 + ": " + constants.sec7sub + ' (Addict)', id: '7' },
    { key: 'a8', type: '0', doc: constants.MasterWk, doctype: "A", section: constants.sec7 + ": " + constants.sec7sub + ' (Non-Addict)', id: '7' },
  ];
  const data2 = [
    { key: 'b1', type: "0", doc: constants.DocumentList, doctype: "B", section: constants.RefresherWorkSheet, id: '7' },
  ]
  const data4 = [
    { key: 'd1', type: "1", doc: constants.ConsentForm, doctype: "D", section: constants.ConsentForm + ": " + constants.MedicalInfo, id: "0" },
    { key: 'd2', type: "2", doc: constants.ConsentForm, doctype: "D", section: constants.SuicideForm, id: "0" },
    { key: 'd3', type: "4", doc: constants.ConsentForm, doctype: "D", section: `${constants.ParentConsentText} (${constants.Minor})`, id: "0" },
    { key: 'd4', type: "3", doc: constants.ConsentForm, doctype: "D", section: constants.BioForm, id: "0" },
  ]

  const handleSectionClick = (sectionId, sectionType, docName, docType, sectionName, duration, key, isScholarship, associateId, header) => {
    const pathnames = {
      'A': '/master-workbook-section',
      'B': '/refresher-worksheet',
      'C': '/coaching-agreement',
      'D': '/consent-form',
    };

    const pathname = pathnames[docType];
    const selectedAssociate = options.find(option => option.value === selectedClientId);
    const associateName = selectedAssociate ? selectedAssociate.label : '';

    if (pathname) {
      history.push({
        pathname,
        state: { sectionId, sectionType, docName, sectionName, duration, key, isScholarship, associateId, header, associateName },
      });
    }
  };

  const durationLabels = {
    "7": constants.SevenDays,
    "30": constants.ThirtyDays,
    "1": constants.OneDay,
    "0": constants.Unlimited,
    "null": constants.Family
  };

  const indexOfClientId1 = associatesData.findIndex(item => item.client_id === "1");
  const sortedAssociatesData = indexOfClientId1 !== -1 ? [
    associatesData[indexOfClientId1],
    ...associatesData.slice(0, indexOfClientId1),
    ...associatesData.slice(indexOfClientId1 + 1)
  ] : associatesData;

  const options = sortedAssociatesData.map(({ firstname, lastname, client_id }) => ({
    label: `${firstname} ${lastname}`,
    value: client_id,
    key: client_id,
  }));

  const items = [
    {
      key: '1',
      label: constants.MasterWk,
      children: (
        <Table className='overflow-x-auto [text-align-last:center] borderTable collapseTable' columns={getColumns(constants.sec, 'lg:w-5/6 xl:w-2/3 2xl:w-1/2 mx-auto [text-align-last:left]')} pagination={false} dataSource={data1} />
      ),
      className: 'mb-3',
    },
    {
      key: '2',
      label: constants.RefresherWorkSheet,
      children: (
        <Table className='overflow-x-auto [text-align-last:center] borderTable collapseTable' columns={getColumns('Worksheet', 'mx-auto')} pagination={false} dataSource={data2} />
      ),
      className: 'mb-3',
    },
    {
      key: '3',
      label: constants.CoachingAgreement,
      children: (
        <div>
          <Form>
            <Form.Item label="Select" name="select" initialValue={"1"}>
              <Select
                style={{ width: '30%' }}
                showSearch
                optionFilterProp="label"
                filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                options={options}
                onChange={handleSelectChange}
              />
            </Form.Item>
          </Form>
          {collapseItems ? (
            <Collapse className='tableCollapse' expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />} size="small" accordion >
              {collapseItems}
            </Collapse>
          ) : (
            <h3 className='font-bold text-center'>{constants.AgreementsNo}</h3>
          )}
        </div>
      ),
      className: 'mb-3',
    },
    {
      key: '4',
      label: constants.ConsentForm,
      children: (
        <Table className='overflow-x-auto [text-align-last:center] borderTable collapseTable' columns={getColumns('Forms', 'lg:w-7/12 xl:w-5/12 2xl:w-2/5 mx-auto [text-align-last:left]')} pagination={false} dataSource={data4} />
      ),
      className: 'mb-3',
    },
  ];

  return (
    <>
      <div className='text-center bg-amber-300 text-lg font-semibold p-3'>
        {constants.Subtitle}
        <span className="py-0.5 px-3 ml-3 text-sm bg-white rounded-xl">
          {constants.MasterDocs}
        </span>
      </div>
      <div className='container mx-auto'>
        <div className='mx-2 md:mx-6 md:mt-8'>
          <Card className='lg:w-4/5 self-end mx-auto'>
            <h2 className='text-2xl text-center font-semibold border-b pb-3'>{constants.MasterDocs}</h2>
            <Collapse className='pt-5 customCollapse'
              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              size="large" accordion>
              {items.map(item => (
                <Panel key={item.key} header={item.label} className={item.className}>
                  {item.children}
                </Panel>
              ))}
            </Collapse>
          </Card>
        </div>
      </div>
    </>
  );
};

export default MasterDocs;