/* eslint-disable no-undef */
/* eslint-disable react/jsx-key */
const constants = {
	AppName: "RoyCares & Associates",
	LoginHeader: "Login to",
	RegisterHeader: "Register to",
	AnyoneRecover: "Anyone can recover!",
	Tagline: "Renew, Restart, Reset & Refocus",
	AlreadyRegisteredMessage:[<b>You have already registered with RoyCares & Associates. <br/><a rel = "noreferrer" target="_blank" href={process.env.REACT_APP_URLS_FOR_BASE + "/login"}>Click here</a> to login now with the same email address you entered during registration. <br/>Forgot your password? <a rel = "noreferrer" target="_blank" href={process.env.REACT_APP_URLS_FOR_BASE + "/forgot-password"}>Click here</a> to reset your password.</b>],
	ForgotPsdTitle:"Forgot your password? Don't Panic!",
	ForgotPsdSubTitle:"Type the email address linked to your account and we'll send your password reset instructions. The email might end up in your spam folder, so make sure to check there as well.",
	AccountActivation:"Account Activation",
	Profile:"Edit Profile",
	EditEnquiry:"Edit Enquiry",
	MyProfileText:"My Profile",
	MyWorkbook:"My Workbook",
	AdminWorkbook:"Workbook",
	MasterWk: "Master Workbook",
	MasterDocs: "Master Docs",
	// LinkExpired:["This link has expired. Click",<Button className="px-2" type="link" href="/">here</Button>,"to generate a new link for activation."],
	ClientList:"Clients",
	RegistrationLinkText:"Registration Links",
	ParentConsentText:"Parent Consent",
	PaymentAgreement: "Payment Agreement",
	LinkExpired:"This link has expired.",
	ActivationLintGenerate:"to generate a new link for activation.",
	// Labels 
	Username: "Username",
	Password: "Password",
	RegPassword: "Set your unique Password",
	RegConfirmPassword: "Confirm your unique Password",
	NewPsd:"New Password",
	ConfirmNewPsd:"Confirm New Password",
	ResetPsd:"Reset Password",
	FirstName: "First Name",
	LastName: "Last Name",
	ConfirmPsd: "Confirm Password",
	Email: "E-mail Address",
	EmailPlaceholder: "your.email@mail.com",
	country: "Country",
	state: "State",
	city: "City",
	Age:"Age",
	PaidBy:"Paid By",
	phone: "Phone Number (WhatsApp)",
	NotesVisible : "View other's Notes",
	AddNote: "Add Note",
	Gender:"Gender",
	dob:"Date of Birth",
	Doe:"Date of Enquiry",
	Startdt: "Start date",
	Enddt: "End Date",
	occupation: "Occupation",
	addict: "Addict",
	NotAnAddict: "Non-Addict",
	FullCost:"Full cost",
	Male:"Male",
	Female:"Female",
	Scholarship:"Scholarship",
	FriendsAndFamily:"Friends And Family",
	FamilyAndFriends: "Family And Friends",
	SpclScholarship:"Special Scholarship",
	Adult:"Adult",
	Minor:"Minor",
	PasswordNotMatch: "The two passwords that you entered do not match!",
	PsdTip: "Password should be more than 2 characters.",
	PasswordNoSpace: "No space allowed",
	Subtitle:"R.A.R.E. - RoyCares & Associates Amazing Readjustment Experience Workbook",
	sec:"Section",
	childDetailFormheader:"Minor's Details",
	clientDetailFormheader:"Client Details",
	otherDetailFormheader: "Other's Details",
	callerDetailFormheader: "Caller's Details",
	parentsName:"Parent's Name",
	parentsEmail:"Parent's Email",
	clientName:"Client Name",
	Payment:"Payment Info",
	ConfidentialInfo:"Confidential Info",
	Plan:"Plan",
	Program:"Program",
	EnableSection : "Enable Section",
	UnEnableSection : "UnEnable Section",
	EnableSectionDescription:"Are you sure you want to Enable Section",
	UnEnableSectionDescription:"Are you sure you want to UnEnable Section",
	ParentsDetails:"Parent's Details", 
	InterestIn: "Interested in",
	// personal_info:"Personal Info",
	//completing on section meessage
	complete:"Congratulations",
	complete2:"Thank you for completing Section",
	complete3:<div> After you discuss and process this section with your coach you will receive a message to enable you to move to the next section.</div>,
	complete4:<div>Thank you.</div>,
	RefresherCompletedMessage:"Thank you for completing Refresher Workbook.",
	sec1:"Section 1",
	sec2:"Section 2",
	sec3:"Section 3",
	sec4:"Section 4",
	sec5:"Section 5",
	sec6:"Section 6",
	sec7:"Section 7",
	sec8:"Section 8",
	ChangeJar: "Change Jar",
	ChangeJarPara: [<><p>We can’t allow these negative personality traits make us react to hurt instincts in a manner that aggravate the other party <i>(even though they are completely in the wrong)</i>, and make them act in a way that makes the situation worse, thus perpetuating the cycle of problems.</p>
		<ol><li value="2"><br /><br /></li></ol></>],
	GratitudeJar: "Gratitude Jar",
	sec1sub:"Readjustment to Life’s New Parameters",
	sec2sub:"Identify the Addiction problems.",
	sec3sub:"Identify areas you may be struggling with.",
	sec4sub:"The Causes...",
	sec5sub:"Review how we REACT with FEAR and how we ACT.",
	sec6sub:"What’s holding me back?",
	sec7sub:"Recovery",
	sec8sub:"Ongoing Coaching",
	// Buttons & links
	ForgotPsdRedirectLink: "Forgot password?",
	LoginBTN: "Login",
	RegisterBTN: "Register",
	ContactEmail: "info@roycares.org",
	UsefulLinks:"Useful Links",
	SendIns:"Send Instructions",
	UpdateBtn:"Update",
	SendBtn:"Send",
	Cancel:"Cancel",
	ChangePsd:"Change Password",
	SaveClose:"Save & Close",
	BackBtn:"Back",
	CloseBtn:"Close",
	Resume:"Resume",
	Next: "Next",
	GoToPage: "Go To Page",
	Discussed:"Discussed",
	Save: "Save",
	Submit:"Submit",
	Delete:"Delete",
	Previous: "Previous",
	Preview: "Preview",
	CancelBTN: "Cancel",
	Completed:"Completed",
	//Registration form validation
	FirstNameRegistration: "Enter first name",
	FirstNameInvalid:"Invalid first name",
	NameInvalid:"Invalid name",
	parentInvalid:"Invalid parent name",
	selctclientType:"Select Workbook Type",
	SelectPaymentBy:"Select Paid By",
	LastNameRegistration: "Enter last name",
	LastNameInvalid:"Invalid last name",
	CountryRegistration: "Select Country",
	StateRegistration: "Select State",
	CityRegistration: "Enter City",
	EmailRegistration: "Enter email",
	emailVaidationError:"Email should be unique and valid",
	endDateValidationError: "Enter a valid End Date",
	PhoneNoRegistration: "Enter Phone Number (WhatsApp)",
	RequiredField:"This field is required",
	GenderVaidationMessage: "Select Gender",
	ParentPhoneNoRegistration: "Enter Parent Phone Number (WhatsApp)",
	PasswordRegistration: "Enter password",
	enterdob: "Enter Date of Birth",
	//API environment constants
	Pdfurl:process.env.REACT_APP_BASE_URL,
	ApiUrl: process.env.REACT_APP_API_URL,
	BaseUrl: process.env.REACT_APP_URLS_FOR_BASE,
	AdminApiUrl: process.env.REACT_APP_API_ADMIN_URL,
	// RegisterSuccess: "Registration completed successfully",
	RegisterSuccess: "Thank you for registering for the RARE Program - RoyCares Amazing Recovery Experience! Please click the NEXT button to take you to complete the Coaching Agreement we discussed, so that we can begin. ",
	PasswordValidation:"Password length should be minimum 8 characters. It must contain at least 1 uppercase letter, 1 lowercase letter, a number, any special character and no spaces.",
	RegisterSuccessEmailMessage: ["Your account is almost ready. Please click the link in your email to activate the account.",
		<br key={0}/>,
		"If you do not find the email in your Inbox, make sure to check your Spam folder."],
	//Message Time
	MessageDisplayLong:8,
	//Login
	LoginEmailValid:"Enter Username",
	LoginPasswordValid:"Enter Password",
	LoginRestriction:"Roy Cares application was opened in another window.",
	LoginRestrictionPart2:'Click "Use Here" button to continue using the application in this window.',
	ValidationMessage:"Please select an answer for all the questions.",
	//date formate
	dateformat:"ll",
	//esign popup message
	EsignPopupMessageConsentForm:["Thank you for signing the Coaching Agreement!",<br/>,"Please click the OK button to complete the Consent Form."],
	EsignPopupMessage:["Thank you for signing the Consent Form.",<br/>,"Please click the OK button to complete the General Release Form."],
	//Workbook message
	WorkbookInstructionsText:"Welcome to the RoyCares! RARE program Section",
	WorkbookInstructionsTextRefresher:"Welcome to the RoyCares! RARE program Refresher Workbook",
	WorkbookInstructionsText2:[<br/>,"Please begin this section and complete as thoroughly as possible.",<br/>,"Feel free to message me if you have any doubts or questions and we will discuss it.",<br/>,"There are NO FOOLISH QUESTIONS.",<br/>,"We are here to answer them all."],
	WorkbookHeaderText:"Get Started",
	// DashboardWelcomeMessage:"Welcome to RoyCares!. Please contact your coach to commence your sessions.",
	DashboardWelcomePayment: [<b>Thank you for signing up for RARE Program - RoyCares Amazing Recovery Experience!</b>, <br />, <b> Kindly contact your coach to enable the Section(s).</b>],
	DashboardWelcomeMessage:[<b>Thank you so much for completing the Coaching Agreement and Consent Forms.</b>,<br/>,<b>Please complete the payment to commence your sessions.</b>],
	DashboardWelcomeMessageFamilyMember:[<b>Thank you so much for completing the Coaching Agreement and Consent Forms.</b>],
	DashboardWelcomeMessageMinor:[<b>Thank you so much for completing the Coaching Agreement and Consent Forms.</b>,<br/>,<b>Once your parent(s) complete the payment, a RoyCares coach will contact you.</b>],
	DashboardWelcomeRefresher:[<b>{"Thank you so much for completing the Refresher's Coaching Agreement and Consent Forms."}</b>,<br/>,<b>Please complete the payment to commence your sessions.</b>],
	// DashboardMinorWelcomeMessage:["Thanks you so much for completing the profile form.",<br/>,"Your parent/guardian needs to give consent to proceed further.",<br/>,"Thank you."],
	ParentConsentMessage:[<b>Thank you so much for completing the Parent Consent Form.</b>,<br/>,<b>Please complete the payment. A RoyCares coach will contact your child thereafter.</b>,<br/>],
	ParentConsentMessage2 :[<b>Click <a rel = "noreferrer" target="_blank" href="https://roycares.org/">roycares.org</a> to learn more about our services.</b>],
	CallerMessage: [<b>Thank you so much for completing the Payment Agreement.</b>, <br />, <b>Please complete the payment. A RoyCares coach will contact the Client thereafter.</b>, <br />],
	PlanHeading : "Choose plan for selected",
	//checkbox table values
	GeneralOptions : [
		{
			label: 'Annoyed',
			value: 'Annoyed',
			tooltip: ''
		},
		{
			label: 'Bigoted',
			value: 'Bigoted',
			tooltip: ''
		},
		{
			label: 'Bored',
			value: 'Bored',
			tooltip: ''
		},
		{
			label: 'Burned-up',
			value: 'Burned-up',
			tooltip: ''
		},
		{
			label: 'Contemptuous',
			value: 'Contemptuous',
			tooltip: ''
		},
		{
			label: 'Discontented',
			value: 'Discontented',
			tooltip: ''
		},
		{
			label: 'Dissatisfied',
			value: 'Dissatisfied',
			tooltip: ''
		},
		{
			label: 'Dishonest',
			value: 'Dishonest',
			tooltip: ''
		},
		{
			label: 'Enraged',
			value: 'Enraged',
			tooltip: ''
		},
		{
			label: 'Envious',
			value: 'Envious',
			tooltip: 'Envy is when you want what someone else has'
		},
		{
			label: 'Fearful',
			value: 'Fearful',
			tooltip: ''
		},
		{
			label: 'Frustrated',
			value: 'Frustrated',
			tooltip: ''
		},
		{
			label: 'Grumpy',
			value: 'Grumpy',
			tooltip: ''
		},
		{
			label: 'Hateful',
			value: 'Hateful',
			tooltip: ''
		},
		{
			label: 'Irritated',
			value: 'Irritated',
			tooltip: ''
		},
		{
			label: 'Intolerant',
			value: 'Intolerant',
			tooltip: ''
		},
		{
			label: 'Impatient',
			value: 'Impatient',
			tooltip: ''
		},
		{
			label: 'Judgmental',
			value: 'Judgmental',
			tooltip: ''
		},
		{
			label: 'Jealous',
			value: 'Jealous',
			tooltip: "Jealousy is when you're worried someone's trying to take what you have."
		},
		{
			label: 'Lonely',
			value: 'Lonely',
			tooltip: ''
		},
		{
			label: 'Resentment',
			value: 'Resentment',
			tooltip: ''
		},
		{
			label: 'Restless',
			value: 'Restless',
			tooltip: ''
		},
		{
			label: 'Self-pity',
			value: 'Self-pity',
			tooltip: ''
		},
		{
			label: 'Sore',
			value: 'Sore',
			tooltip: ''
		},
		{
			label: 'Selfishness',
			value: 'Selfishness',
			tooltip: ''
		},
		{
			label: 'Sarcastic',
			value: 'Sarcastic',
			tooltip: ''
		},
		{
			label: 'Spiteful',
			value: 'Spiteful',
			tooltip: ''
		},
		{
			label: 'Unforgiving',
			value: 'Unforgiving',
			tooltip: ''
		},
		{
			label: 'Vengeful',
			value: 'Vengeful',
			tooltip: ''
		},
		{
			label: 'Vanity',
			value: 'Vanity',
			tooltip: ''
		},
	],
	BuddhismOptions : [
		{
			label: 'Ignorance',
			value: 'Ignorance',
			tooltip: ''
		},
		{
			label: 'Attachment',
			value: 'Attachment',
			tooltip: ''
		},
		{
			label: 'Aversion',
			value: 'Aversion',
			tooltip: ''
		},
		{
			label: 'Pride',
			value: 'Pride',
			tooltip: ''
		},
		{
			label: 'Jealously',
			value: 'Jealously',
			tooltip: ''
		},
	],
	ChristianityOptions:[
		{
			label: 'Lust',
			value: 'Lust',
			tooltip: ''
		},
		{
			label: 'Gluttony',
			value: 'Gluttony',
			tooltip: ''
		},
		{
			label: 'Greed',
			value: 'Greed',
			tooltip: ''
		},
		{
			label: 'Sloth',
			value: 'Sloth',
			tooltip: ''
		},
		{
			label: 'Wrath',
			value: 'Wrath',
			tooltip: ''
		},
		{
			label: 'Envy',
			value: 'Envy',
			tooltip: ''
		},
		{
			label: 'Pride',
			value: 'Pride2',
			tooltip: ''
		},
	],
	SpiritualOptions:[
		{
			label: 'Politics Without Principles',
			value: 'Politics Without Principles',
			tooltip: ''
		},
		{
			label: 'Wealth Without Work',
			value: 'Wealth Without Work',
			tooltip: ''
		},
		{
			label: 'Commerce Without Morality',
			value: 'Commerce Without Morality',
			tooltip: ''
		},
		{
			label: 'Education Without Character',
			value: 'Education Without Character',
			tooltip: ''
		},
		{
			label: 'Pleasure Without Conscience',
			value: 'Pleasure Without Conscience',
			tooltip: ''
		},
		{
			label: 'Science Without Humanity',
			value: 'Science Without Humanity',
			tooltip: ''
		},
		{
			label: 'Worship Without Sacrifice',
			value: 'Worship Without Sacrifice',
			tooltip: ''
		},
	],
	HinduismOptions:[
		{
			label: 'Kama (lust)',
			value: 'Kama (lust)',
			tooltip: ''
		},
		{
			label: 'Krodha (Anger)',
			value: 'Krodha (Anger)',
			tooltip: ''
		},
		{
			label: 'Lobha (Greed)',
			value: 'Lobha (Greed)',
			tooltip: ''
		},
		{
			label: 'Moha (Delusion)',
			value: 'Moha (Delusion)',
			tooltip: ''
		},
		{
			label: 'Mada (Pride)',
			value: 'Mada (Pride)',
			tooltip: ''
		},
		{
			label: 'Matsarya (Jealously)',
			value: 'Matsarya (Jealously)',
			tooltip: ''
		},
	],
	SikhismOptions : [
		{
			label: 'Kam-excessive desire for sex',
			value: 'Kam-excessive desire for sex',
			tooltip: ''
		},
		{
			label: 'Krodh-excessive Anger',
			value: 'Krodh-excessive Anger',
			tooltip: ''
		},
		{
			label: 'Lob-excessive materialistic Desire',
			value: 'Lob-excessive materialistic Desire',
			tooltip: ''
		},
		{
			label: 'Moh-lack of knowledge',
			value: 'Moh-lack of knowledge',
			tooltip: ''
		},
		{
			label: 'Ahankar-Ego',
			value: 'Ahankar-Ego',
			tooltip: ''
		},
	],
	IslamOptions:[
		{
			label: 'Drinking',
			value: 'Drinking',
			tooltip: ''
		},
		{
			label: 'Gambling',
			value: 'Gambling',
			tooltip: ''
		},
		{
			label: 'Adultery',
			value: 'Adultery',
			tooltip: ''
		},
		{
			label: 'Murder',
			value: 'Murder',
			tooltip: ''
		},
		{
			label: 'Theft',
			value: 'Theft',
			tooltip: ''
		},
		{
			label: 'Slander',
			value: 'Slander',
			tooltip: ''
		},
		{
			label: 'Lying',
			value: 'Lying',
			tooltip: ''
		},
		{
			label: 'Showing off',
			value: 'Showing off',
			tooltip: ''
		},
		{
			label: 'Flirting',
			value: 'Flirting',
			tooltip: ''
		},
		{
			label: 'Swearing',
			value: 'Swearing',
			tooltip: ''
		},
		{
			label: 'Backbiting',
			value: 'Backbiting',
			tooltip: ''
		},
	],
	dobdateFormat:"MM/DD/YYYY",
	SectionZeroPart:{
		welcome:1,
		coachingAgreementpageone:2,
		coachingAgreementpagetwo:3,
		suicidelForm:4,
		releaseFormpageone:5,
		releaseFormpagetwo:6
	},
	ProfileForm:"Profile Form",
	ConsentForm:"Consent Form",
	BioForm: "Bio Form",
	SuicideForm: "Suicidal Ideation Form",
	Details:"Details",
	WelcomePage:"Welcome Page",
	CoachingAgreement:"Coaching Agreement",
	ReleaseForm:"Release Form",
	profileUpdate:"Profile updated successfully",
	DownloadAgrementBtn: "View Agreement",
	DownloadParentAgrement: "View Parent's Consent",
	DownloadPDF: "Download PDF",
	Uploadfile: "Upload File",
	downloadargrementText:"Click the Download Agreement button to view a copy of the Coaching Agreement signed by your child.",
	uploadText:"Please attach proof of relationship to child – Govt. issued document with both names ie birth certificate or passport, etc.",
	commitmentToPayitemId:"684",
	commitmentToPayVM:"Please enter the amount.",
	SpecialCost:"Special Cost",
	ageProofVM:"Please upload age proof.",
	signVM:"Please sign the Coaching Agreement.",
	signPA: "Please sign the Payment Agreement.",
	signVMCF:"Please sign the Consent Form.",
	signMedicalVM:"Please sign the General Release Form.",
	section0VM:"Please fill an answer for the questions.",
	section0VMCF:"Please fill all the details.",
	notesVM:"Please save your Notes.",
	ageproofV:"View Age Proof",
	ViewNotes:"View Notes",
	ImageList:"Daily Images",
	DocumentList:"Documents",
	AllDocs: "All Documents",
	ClientNotes: "Client Notes",
	Notes: "Notes",
	ClientDocs: "Client Docs",
	DocsSigned: "Documents Signed",
	PaymentStatement:"Payment Statement",
	MedicalInfo:"Medical Info Release",
	AddDocument:"Upload Document",
	ClientEL:"Enquiries",
	UploadImages:"Upload Images",
	paginationConfig :{ 
		pageSize: 25,
		pageSizeOptions:[10,25,50,100]
	},
	DocumentTypeOption : [
		{
			value: "2",
			label: "Discharge Report",
		},
		{
			value: "1",
			label: "Medical Info Release",
		},
		{
			value: "3",
			label: "Medical Report",
		},
		{
			value: "4",
			label: "Prescription",
		},
		{
			value: "7",
			label: "Others",
		}
	],
	HeaderRegistrationLinkPopupM:"Request for Parent Consent",
	HeaderRegistrationLinkPopupA:"Send link for Registration",
	RefresherHeader:"Send link for Refresher",
	PlanCompleted:"Plan Completed",
	Refresher:"Refresher",
	RefresherWorkSheet:"Refresher Worksheet",
	AssignTo:"Assign To",
	AssignedTo:"Assigned To",
	SevenDays:"Onsite/Online",
	ThirtyDays:"Online",
	OneDay:"Refresher",
	Unlimited:"General Coaching",
	Family:"Family",
	Self:"Self",
	Other:"Other",
	Source:"Source",
	SourcePlaceHolder:"Please choose an option",
	SourceOption : [
		{
			value: "1",
			label: "Website",
		},
		{
			value: "2",
			label: "Linkedin",
		},
		{
			value: "3",
			label: "YouTube",
		},
		{
			value: "4",
			label: "WhatsApp",
		},
		{
			value: "5",
			label: "Facebook",
		},
		{
			value: "6",
			label: "Instagram",
		},
		{
			value: "7",
			label: "Twitter",
		},
		{
			value: "8",
			label: "Friends/Professional",
		},
		{
			value: "9",
			label: "Others",
		},
		{
			value: "10",
			label: "Rehab",
		}
	],
	MoreDetails:"More Details",
	OtherDetails:"Other Details",
	General:"General",
	Associates : "Associates",
	DemoClient : "Demo Client",
	AddAssociates : "Add Associates",
	AddAssociateDetails: "Add Associate Details",
	EditAssociateDetails: "Edit Associate Details",
	Add:"Add",
	Esign:"E-sign",
	GamblingValidateMessage: "Your result - You answered No to both the options. You are doing well. We suggest that you go over the options again with a loved one or best friend to be sure you have been honest in your responses.",
	Caller:"Caller",
	Parent: "Parent",
	Client:"Client",
	Status1Ttip:"Status when you started the program",
	Status2Ttip:"Status when you last completed the program",
	Status3Ttip:"Status when you are completing the program",
	SectionOptions : [
		{
			value: "1",
			label: "Section 1",
		},
		{
			value: "2",
			label: "Section 2",
		},
		{
			value: "3",
			label: "Section 3",
		},
		{
			value: "4",
			label: "Section 4",
		},
		{
			value: "5",
			label: "Section 5",
		},
		{
			value: "6",
			label: "Section 6",
		},
		{
			value: "7",
			label: "Section 7",
		},
		{
			value: "8",
			label: "Section 8",
		},
		{
			value: "100",
			label: "Refresher",
		},
		{
			value: "none-of-the-above",
			label: "None of the above",
		}
	],
	ExportToWord:"Export to Excel",
	RearrangeEnq: "Rearrange Enquiry",
	Filter:"Filter",
	SectionBackNavigationMessage:"If you click the OK button, the section will be automatically saved. You will be able to resume it later. Click Cancel if you wish to continue answering the session.",
	Session:"Session",
	Sessions: "Session (s)",
	PlanCost: "Plan Cost",
	SessionCost: "Per Session Cost",
	TotalCost: "Total Plan Cost",
	DisplayCostMsg: "Display Payment on Coaching Agreement.",
	AgreementsNo: "No Agreements available for the selected Associate.",
	ShareWith: "Share With",
	SharedWith: "Shared With",
	Services: "Services",
	AddFnF: "Add Family and Friends",
	EditFnF: "Edit Family and Friends",
	SendFamilyAgg: "Send Family Agreement",
	Relation: "Relation",
	Trauma:"Trauma",
	YouthAndWomen:"Youth & Women",
	All:"All",
	TransferTo: "Transferred to",
	DeleteDescription: "Please provide the enquiry ID to perform deletion.",
	DeleteModal: "Are you sure you want to delete Enquiry",
	//General plan options
	GeneralPlanOptions : [
    { value: "3", label: "All" },
    { value: "1", label: "Trauma" },
    { value: "2", label: "Youth & Women" }
  ],
	AgreementOptions :[
    { value: 7, label: "Onsite/Online" },
    { value: 30, label: "Online" },
    { value: 1, label: "Refresher" },
    { value: 0, label: "General" },
		//setting value 100 for family agreement.
		{ value: 100, label: "Family" },
  ],
	Enquiry:"Enquiry",
	SectionApprovalMessage:"Please click Submit and discuss the topic(s) with your Coach at your next session.",
	WbContents:"Wb Contents",
	ClientAnswers:"Client Answers"
};
export default constants;
