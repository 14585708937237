import React, { useEffect, useRef, useState } from 'react';
import { Radio, Form, Input, Button, Checkbox, Card, Space, Popconfirm, message, Spin, Select, Divider, InputNumber, Col, Row } from 'antd';
import constants from '../../../constants/constants';
import { fetchAdminApi, fetchApi } from '../../../services/api';
import { DOB } from '../../../globalFunctions/GlobalFunctions';
import { COOKIE, getCookie } from '../../../services/cookie';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { PlusOutlined } from '@ant-design/icons';
const { Option } = Select;
const { TextArea } = Input;

// Custom components
const custom_select = (allowClear, placeholder, mode, options, onAdd, value, onChange, inputRef, style) => {
    return (
        <Select
            allowClear={allowClear}
            placeholder={placeholder}
            mode={mode}
            style={style}
            dropdownRender={(menu) => (
                <>
                    {menu}
                    <Divider style={{ margin: '8px 0' }} />
                    <Space style={{ padding: '0 8px 4px' }}>
                        <Input
                            placeholder="Please enter option."
                            ref={inputRef}
                            value={value}
                            onChange={onChange}
                            onKeyDown={(e) => e.stopPropagation()}
                        />
                        <Button type="text" icon={<PlusOutlined />} onClick={onAdd}>
                            Add
                        </Button>
                    </Space>
                </>
            )}
            options={options
                .slice()
                .sort((a, b) => a.localeCompare(b))
                .map((item) => ({
                    label: item,
                    value: item,
                }))}
        />
    );
};

const AddNote = () => {
    const [form] = Form.useForm();
    const history = useHistory();
    const adminClientValuefromSession = JSON.parse(sessionStorage.getItem("adminClientValue"));
    const [loader, setLoader] = useState(false);
    const [enquiryClientDetails, setEnquiryClientDetails] = useState([]);
    const [pronoun, setPronoun] = useState("She/He");
    const [possPronoun, setPossPronoun] = useState("His/Her");

    // Notes
    const [noteValue, setNoteValue] = useState(null);
    const [noteTypeValue, setNoteTypeValue] = useState("0");
    const [sessionChecked, setSessionChecked] = useState(false);
    const [isNoteAnswered, setIsNoteAnswered] = useState(false);
    const notesHeader = ["DATA:", "ASSESSMENT:", "PLAN:"];
    // Field state changes
    const [isDrug4Selected, setIsDrug4Selected] = useState(false);
    const [isFamily8Selected, setIsFamily8Selected] = useState(false);
    const [isFamily11Selected, setIsFamily11Selected] = useState(false);
    const [isFamily14Selected, setIsFamily14Selected] = useState(false);
    const [isMedical4Selected, setIsMedical4Selected] = useState(false);
    const [isMedical6Selected, setIsMedical6Selected] = useState(false);
    const [isMedical8Selected, setIsMedical8Selected] = useState(false);
    const [selectedSubstances, setSelectedSubstances] = useState({});
    const [selectedReasons, setSelectedReasons] = useState({});
    const [selectedCrimes, setSelectedCrimes] = useState({});
    const [selectedSocials, setSelectedSocials] = useState({});
    const [selectedAttempts, setSelectedAttempts] = useState({});

    // Select Options
    const [listAssociates, setListAssociates] = useState([]);
    const [maritalStatus, setMaritalStatus] = useState(['single', 'divorced', 'married']);
    const [newMaritalStatus, setNewMaritalStatus] = useState('');
    const maritalStatusInputRef = useRef(null);
    const addMaritalStatus = () => {
        if (!newMaritalStatus) return;
        setMaritalStatus([...maritalStatus, newMaritalStatus]);
        setNewMaritalStatus('');
        setTimeout(() => maritalStatusInputRef.current?.focus(), 0);
    };
    const [ethnicity, setEthnicity] = useState(['Asian Indian', 'White', 'Black', 'Hispanic']);
    const [newEthnicity, setNewEthnicity] = useState('');
    const ethnicityInputRef = useRef(null);
    const addEthnicity = () => {
        if (!newEthnicity) return;
        setEthnicity([...ethnicity, newEthnicity]);
        setNewEthnicity('');
        setTimeout(() => ethnicityInputRef.current?.focus(), 0);
    };
    const [religion, setReligion] = useState(['Hindu', 'Muslim', 'Christian', 'Buddhist', 'Jewish']);
    const [newReligion, setNewReligion] = useState('');
    const religionInputRef = useRef(null);
    const addReligion = () => {
        if (!newReligion) return;
        setReligion([...religion, newReligion]);
        setNewReligion('');
        setTimeout(() => religionInputRef.current?.focus(), 0);
    };
    const [assessment, setAssessment] = useState(['CAGE', 'DSM-V', 'MMMH']);
    const [newAssessment, setNewAssessment] = useState('');
    const assessmentInputRef = useRef(null);
    const addAssessment = () => {
        if (!newAssessment) return;
        setAssessment([...assessment, newAssessment]);
        setNewAssessment('');
        setTimeout(() => assessmentInputRef.current?.focus(), 0);
    };
    const [disorder, setDisorder] = useState(['substance', 'gambling']);
    const [newDisorder, setNewDisorder] = useState('');
    const disorderInputRef = useRef(null);
    const addDisorder = () => {
        if (!newDisorder) return;
        setDisorder([...disorder, newDisorder]);
        setNewDisorder('');
        setTimeout(() => disorderInputRef.current?.focus(), 0);
    };
    const [living, setLiving] = useState(['living alone', 'with spouse/partner and children', 'in traditional joint family with partner and parents and siblings']);
    const [newLiving, setNewLiving] = useState('');
    const livingInputRef = useRef(null);
    const addLiving = () => {
        if (!newLiving) return;
        setLiving([...living, newLiving]);
        setNewLiving('');
        setTimeout(() => livingInputRef.current?.focus(), 0);
    };
    const [identity, setIdentity] = useState(['Heterosexual', 'Bisexual', 'LGBTQIA+']);
    const [newIdentity, setNewIdentity] = useState('');
    const identityInputRef = useRef(null);
    const addIdentity = () => {
        if (!newIdentity) return;
        setIdentity([...identity, newIdentity]);
        setNewIdentity('');
        setTimeout(() => identityInputRef.current?.focus(), 0);
    };
    const [mentalD, setMentalD] = useState(['anxiety', 'depression', 'mood disorder', 'bipolar disorder']);
    const [newMentalD, setNewMentalD] = useState('');
    const mentalDInputRef = useRef(null);
    const addMentalD = () => {
        if (!newMentalD) return;
        setMentalD([...mentalD, newMentalD]);
        setNewMentalD('');
        setTimeout(() => mentalDInputRef.current?.focus(), 0);
    };
    const [symptoms, setSymptoms] = useState(['severe distress like negative impact on work, home and social life', 'loss of interest in activities', 'problems sleeping', 'loss of appetite', 'sadness', 'hopelessness', 'headaches', 'gastrointestinal problems', 'panic attacks', 'heart palpitations', 'cold sweats', 'worry', 'fatigue', 'nervousness']);
    const [newSymptoms, setNewSymptoms] = useState('');
    const symptomsInputRef = useRef(null);
    const addSymptoms = () => {
        if (!newSymptoms) return;
        setSymptoms([...symptoms, newSymptoms]);
        setNewSymptoms('');
        setTimeout(() => symptomsInputRef.current?.focus(), 0);
    };
    // List of substances
    const substances = [
        "Nicotine/Vapes",
        "Alcohol",
        "Amphetamine",
        "Benzodiazepines",
        "Cocaine",
        "Crack Cocaine",
        "Ecstasy (MDMA)",
        "Heroin",
        "LSD (Acid)",
        "Methamphetamine",
        "Marijuana (Cannabis)",
        "Opioids (Prescription Painkillers)",
    ];
    const substances_aliases = {
        "Nicotine/Vapes": "Bidis, chew, chewing tobacco, cigars, cigarettes, coffin nails, fags, kreteks, snuff, spit, smokes, shisha, hookahs, e-cigs, vapes, e-hookahs, vape pens, mods, tanks",
        "Alcohol": "Booze, Moonshine, Liquid Courage, Brew, Goof, Hooch, Cold One, Giggle Juice, Diesel, Hard Stuff, Poison, Petrol, Oil, Sauce",
        "Amphetamine": "Bennies, Black Beauties, Hearts, Speed, Truck Drivers, Uppers",
        "Benzodiazepines": "Barbs, Phennies, Red Birds, Reds, Tooies, Yellow Jackets, Yellows",
        "Cocaine": "Blow, C, Charlie, Coke, Snow, Toot, Coca, Soda Cot",
        "Crack Cocaine": "Crack",
        "Ecstasy (MDMA)": "E, X, Molly",
        "Heroin": "H, Smack, Junk, Brown Sugar, Dope",
        "LSD (Acid)": "Acid, Blotter, Doses, Hits, Trips",
        "Methamphetamine": "Crystal, Ice, Meth, Tina",
        "Marijuana (Cannabis)": "Weed, Pot, Grass, Mary Jane, Ganja",
        "Opioids (Prescription Painkillers)": "Oxy, Percs, Vikes, Hillbilly Heroin"
    };
    const reasons = [
        "Family",
        "Partner or Spouse",
        "Sex",
        "Health",
        "Employment",
        "Finances",
        "School",
        "Physically dangerous situations like",
        "Legal problems like",
        "Social or interpersonal problems",
        "Religious beliefs"
    ];
    const reasonValues = {
        "Family": "parents, siblings, difference of opinion/arguments",
        "Partner or Spouse": "difference of opinion/arguments, children",
        "Health": "",
        "Employment": "poor performance, absences, suspensions",
        "Finances": "late bills, missing payments",
        "School": "poor grades, absences, suspensions, expulsions",
        "Sex": "",
        "Physically dangerous situations like": "",
        "Legal problems like": "DWI (Driving While under Influence of substance), violence, domestic violence, arrests, order of protection",
        "Social or interpersonal problems": "Friends (difference of opinion/arguments)",
        "Religious beliefs": "",
    };
    const crimes = [
        "DWI",
        "Violence",
        "Domestic violence",
        "Arrests",
        "Order of protection",
    ];
    const socials = [
        "Friends",
        "Religious beliefs",
    ];
    const attempts = [
        "CUT DOWN use of substances/gambling",
        "feeling ANGRY if spoken to about use",
        "feeling GUILTY about use",
        "having used substances/gambled EARLY MORNING in order to start the day",
    ];
    const underlineCaps = (text) => {
        return text.replace(/\b([A-Z]+(?:\s+[A-Z]+)*)\b/g, (match) => `<u>${match.trim()}</u>`);
    };

    // Date of notes
    const current = new Date();
    const [noteDate, setNoteDate] = useState({
        date: current.getDate(),
        month: String(current.getMonth() + 1),
        year: current.getFullYear()
    });
    const handleNoteDayChange = (value) => {
        setNoteDate({
            ...noteDate,
            date: value
        });
    };
    const handleNoteMonthChange = (value) => {
        setNoteDate({
            ...noteDate,
            month: value
        });
    };
    const handleNoteYearChange = (value) => {
        setNoteDate({
            ...noteDate,
            year: value
        });
    };

    useEffect(() => {
        getForm(noteTypeValue);
        if (adminClientValuefromSession?.clientId) {
            fetchClientDetails(adminClientValuefromSession?.clientId);
        } else {
            fetchEnquiryDetails(adminClientValuefromSession?.enquiryId);
        }
        getListAssociates();
    }, []);

    useEffect(() => {
        setFormFields();
    }, []);

    const getForm = (formType) => {
        setNoteTypeValue(formType);
        setNoteValue(["", "", ""]);
        setFormFields();
    };

    const setFormFields = () => {
        let type_source = adminClientValuefromSession?.clientId ? enquiryClientDetails?.enquiry_info : enquiryClientDetails;
        const sourceOption = constants.SourceOption.find(
            (option) => option.value === String(type_source?.source)
        );
        const sourceLabel = type_source?.source === "8" || type_source?.source === "9" ? type_source?.source_details : sourceOption?.label;

        form.setFieldsValue({
            first_name: enquiryClientDetails?.client_firstname,
            age: enquiryClientDetails?.age,
            location: enquiryClientDetails?.city + ", " + enquiryClientDetails?.region_name + ", " + enquiryClientDetails?.country_name,
            gender: enquiryClientDetails.gender === "Other" ? enquiryClientDetails?.gender_details : enquiryClientDetails?.gender,
            source: sourceLabel,
        });
    }

    // Fetching enquiry data
    const fetchEnquiryDetails = (enquiry_id) => {
        setLoader(true);
        let payload = { enquiry_id: enquiry_id };
        fetchApi(`/get-enquiry`, "post", payload)
            .then((res) => {
                if (res?.data) {
                    setEnquiryClientDetails(res.data);
                    setIsNoteAnswered(res?.data?.is_note_answered === 1);
                } else {
                    message.error(res.message);
                    setEnquiryClientDetails({});
                }
            })
            .catch(() => {
                message.error("Failed to fetch enquiry details");
                setEnquiryClientDetails({});
            })
            .finally(() => {
                setLoader(false);
            });
    };

    // Fetching client data
    const fetchClientDetails = (client_id) => {
        setLoader(true);
        fetchAdminApi(`/client-profile/${client_id}`, "get")
            .then((res) => {
                if (res?.data) {
                    setEnquiryClientDetails(res.data);
                    const gender = res?.data?.gender?.toLowerCase();;
                    if (gender === "male") {
                        setPronoun("He")
                        setPossPronoun("His")
                    } else if (gender === "female") {
                        setPronoun("She");
                        setPossPronoun("Her")
                    } else if (gender === "other") {
                        setPronoun("They");
                        setPossPronoun("Their")
                    }
                    setIsNoteAnswered(res?.data?.is_note_answered === 1);
                } else {
                    setEnquiryClientDetails([]);
                }
            })
            .catch(() => {
                message.error("Failed to fetch client details");
                setEnquiryClientDetails([]);
            })
            .finally(() => {
                setLoader(false);
            });
    };

    const lowPronoun = pronoun.toLowerCase();
    const lowPossPronoun = possPronoun.toLowerCase();

    const hideModal = () => {
        history.push({ pathname: '/notes' });
    };
    const onchangeNoteValue = (e, index) => {
        noteValue[index] = e;
    };
    const onSessionChange = (value) => {
        setSessionChecked(value);
    }
    const getListAssociates = () => {
        fetchAdminApi(`/view-associates`, "post").then((res) => {
            if (res?.data) {
                const firstItem = res?.data?.filter(obj => obj?.client_id === "1")[0];
                const remainingItems = res?.data?.filter(obj => obj?.client_id !== "1");
                let sortedData;
                if (firstItem) {
                    sortedData = [firstItem, ...remainingItems];
                } else {
                    sortedData = remainingItems
                }
                setListAssociates(sortedData);
            }
            else {
                setListAssociates([]);
            }
        });
    }
    const selectFilterOption = (input, option) => {
        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) > -1 && option.props.children.toLowerCase().indexOf(input.toLowerCase()) < 1;
    };

    const createNote = (value, type) => {
        setLoader(true);
        const formValues = {
            first_name: value?.first_name || "_________",
            disorder: value?.disorder && value?.disorder?.length > 0 ? value?.disorder?.join(", ") : "substance use/gambling use",
            age: value?.age || "____",
            gender: value?.gender || "male/female/(other)",
            location: value?.location || "______________",
            source: value?.source || "______________",
            assignto: value?.assignto || "_________",

            plan_1: value?.plan_1 || "await the appointment/consider options",
            plan_2: value?.plan_2 && value?.plan_2?.length > 0 ? value?.plan_2?.join(", ") : `complete a medical or wellness checkup/detox/have a Psych assessment with Dr. Ashish Deshpande/sign and complete the Coaching Agreement and Payment process`,
            assess_1: value?.assess_1 || "ambivalent/motivated",
            assess_2: value?.assess_2 && value?.assess_2?.length > 0 ? value?.assess_2?.join(", ") : "CAGE/DSM-V/MMMH",
            assess_5: value?.assess_5 && value?.assess_5?.length > 0 ? ` (${value?.assess_5?.join(", ")})` : "",
            assess_3: value?.assess_3 || "appears/does not appear",
            assess_4: value?.assess_4 || "appropriate/not appropriate",
            intro_1: value?.intro_1 || "single/divorced/married",
            intro_2: value?.intro_2 || "Asian Indian/White/Black/Hispanic",
            intro_3: value?.intro_3 || "Hindu/Muslim/Christian/Buddhist/Jewish _________ (other)",
            addiction_1: value?.addiction_1 || "denied/reported",
            addiction_2: value?.addiction_2 || "Denies/Reports",
            drug_1: value?.drug_1 || "Denies/Reports",
            drug_2: value?.drug_2 && value?.drug_2?.length > 0 ? value?.drug_2?.join(", ") : `□ has felt the need to bet more and more money, and has □ lied to people about how much ${lowPronoun} gambles`,
            drug_3: value?.drug_3 || "Denies/Reports",
            drug_4: value?.drug_4 || "been/never been",
            drug_5: value?.drug_5 || "_________",
            drug_6: value?.drug_6 || "_________",
            drug_7: value?.drug_7 || "no/some",
            drug_8: value?.drug_8 || "no/some",
            drug_9: value?.drug_9 || "_________ days/months/years",
            drug_10: value?.drug_10 || "_________",

            problem_1: value?.problem_1 || "online/in-person",
            problem_2: value?.problem_2 || "_________",
            problem_3: Number(value?.problem_3) || "____",
            emotional_1: value?.emotional_1 || "denies/reports",
            emotional_2: value?.emotional_2 && value?.emotional_2?.length > 0 ? value?.emotional_2?.join(", ") : "anxiety/depression/mood disorder/bipolar disorder _________ (other)",
            emotional_3: value?.emotional_3 || "_________",
            emotional_4: value?.emotional_4 || "______________",
            emotional_5: value?.emotional_5 || "______________",
            emotional_6: value?.emotional_6 || "_________ (details)",
            emotional_7: value?.emotional_7 || "_________",
            emotional_8: value?.emotional_8 && value?.emotional_8?.length > 0 ? value?.emotional_8?.join(", ") : "severe distress like negative impact on work, home and social life; loss of interest in activities; problems sleeping, loss of appetite, sadness, hopelessness, headaches, gastrointestinal problems, panic attacks, heart palpitations, cold sweats, worry, fatigue and nervousness",
            emotional_9: value?.emotional_9 || "denies/reports",
            medical_1: value?.medical_1 || "Heterosexual/Bisexual/LGBTQ",
            medical_2: value?.medical_2 || "good/poor",
            medical_3: value?.medical_3 || "_________",
            medical_4: value?.medical_4 || "Allergies/no Allergies ______________",
            medical_5: value?.medical_5 || "______________",
            medical_6: value?.medical_6 || "no/______________ illnesses/disease",
            medical_7: value?.medical_7 || "______________",
            medical_8: value?.medical_8 || "no/______________ medications/OTCs",
            medical_9: value?.medical_9 || "______________",
            medical_10: value?.medical_10 && value?.medical_10?.length > 0 ? value?.medical_10?.join(" and ") : "junk food/balanced diet",
            family_1: value?.family_1 || "____",
            family_2: value?.family_2 || "____",
            family_3: value?.family_3 || "□living alone □with spouse/partner and children □ in traditional joint family with partner and parents and siblings",
            family_4: value?.family_4 || "fair/good/poor",
            family_5: value?.family_5 || "fair/good/poor",
            family_6: value?.family_6 || "____",
            family_7: value?.family_7 || "_________",
            family_8: value?.family_8 || "no/current",
            family_9: value?.family_9 || "_________",
            family_10: value?.family_10 || "______________",
            family_11: value?.family_11 || "is unemployed/retired/owns business/works as a",
            family_12: value?.family_12 || "______________",
            family_13: value?.family_13 || "active/inactive",
            family_14: value?.family_14 || "does/does no",
            family_15: value?.family_15 || "______________",
        };

        const drugsPayload = Object.entries(selectedSubstances)
            .filter(([, value]) => value.checked)
            .map(([substance, { age }]) => {
                const ageDisplay = age || "_____";
                return `<p><b>${substance}</b> at age ${ageDisplay}</p>`;
            })
            .join("");
        const drugPart1Payload = `<p>, gradually increasing amounts to achieve the desired effect over a longer period of time than intended, despite problems in the past 12 months ${noteTypeValue === "1" ? `<u>at home</u>` : `with`}</p>`;
        const reasonsPayload = Object.entries(selectedReasons)
            .filter(([, value]) => value.checked)
            .map(([reason, { cause }]) => {
                const causeDisplay = cause || "______";
                return `<p>${reason}: ${causeDisplay}</p>`;
            })
            .join("");

        const drugPart4Payload = `, caused or exacerbated by use of substances, including reduced involvement in social activities. ${pronoun} reports several attempts to`;
        const attemptsPayload = Object.entries(selectedAttempts)
            .filter(([, isChecked]) => isChecked)
            .map(([attempt]) => ` ${underlineCaps(attempt)}`)
            .join(",");
        const drugsEndPayload = `<p><br></p><p>${formValues.drug_1} any gambling activity and ${formValues.drug_2}. ${formValues.drug_3} family of origin substance/gambling problems. Client reports ${lowPronoun} has ${formValues.drug_4} previously treated for addiction ${formValues.drug_4 !== "never been" ? ` at ${formValues.drug_5} and/or with ${formValues.drug_6}` : ""}; has ${formValues.drug_7} recovering friends; ${formValues.drug_8} prior 12-step program experience and longest period of abstinence is ${formValues.drug_9}.</p>`;

        const planPayload = `<p>Client was informed that ${lowPronoun} is required to ${formValues.plan_2} prior to the commencement of coaching. ${formValues.first_name} said ${lowPronoun} would complete the paperwork, ${formValues.plan_1}, and then call back.</p>`;
        const assessmentPayload = `<p>${formValues.first_name} presented as well-groomed and reported performing daily functions regularly. ${pronoun} is ${formValues.assess_1} about coaching and was informed that based on the ${formValues.assess_2 + formValues.assess_5} assessment screening instruments, ${lowPronoun} ${formValues.assess_3} to meet ICD criteria for ${formValues.disorder} disorder and is ${formValues.assess_4} for coaching and will be referred out to ${formValues.assignto}.</p>`;
        const addictionPayload = `<p><em><u>Addiction/Recovery History:</u></em></p><p>${pronoun} ${formValues.addiction_1} any personal history of substance abuse (other than occasional social/religious use of alcohol). ${formValues.addiction_2} any gambling activity. ${pronoun} stated that, to the best ${lowPossPronoun} recollection, ${lowPossPronoun} family member ${formValues.problem_2} had begun to use</p>${drugsPayload + drugPart1Payload + reasonsPayload + drugPart4Payload + attemptsPayload + drugsEndPayload}`
        const introPayload = `<p>${formValues.first_name} is a ${formValues.age}-year-old ${formValues.intro_1}, ${formValues.intro_2}, ${formValues.intro_3}, ${formValues.gender} from ${formValues.location}, who was referred for an assessment by ${formValues.source}.</p>`;
        const problemPayload = `<p><em><u>${noteTypeValue === "1" ? "Presenting Problem/Addiction/Recovery History:" : "Presenting Problem:"}</u></em></p><p>${noteTypeValue === "1" ? ("Client was seen " + formValues.problem_1 + ", and during " + lowPossPronoun + " assessment, outlined " + lowPossPronoun + " substance abuse history. " + formValues.first_name + " reported that " + lowPronoun + " had begun to use" + drugsPayload + drugPart1Payload + reasonsPayload + drugPart4Payload + attemptsPayload + drugsEndPayload) : ""}${noteTypeValue === "3" ? ("Client was seen online, and during " + lowPossPronoun + " assessment, stated concerns about " + lowPossPronoun + " emotional status and reports feeling angry, anxious, and depressed within the past 12 months.") : ""}${noteTypeValue === "2" ? ("Client was seen online, and during " + lowPossPronoun + " assessment, stated concerns about " + lowPossPronoun + " family member " + formValues.problem_2 + "'s" + formValues.disorder + ". " + pronoun + " reports feeling angry, anxious and depressed about " + formValues.problem_2 + "'s " + formValues.disorder + " and reports several attempts to help " + formValues.problem_2 + " abstain within the past " + formValues.problem_3 + " years and feels frustrated at " + lowPossPronoun + " inability do so.") : ""}</p>`;
        const emotionalPayload = `<p><em><u>Mental/Emotional Health:</u></em></p><p>Client ${formValues.emotional_1} MH history, was diagnosed with ${formValues.emotional_2} and received mental health treatment from ${formValues.emotional_3} and/or was hospitalized for mental illness ${formValues.emotional_4} and is on ${formValues.emotional_5}. ${(noteTypeValue !== "1") ? ("However, " + lowPronoun + " reports feeling angry, anxious and depressed about " + formValues.emotional_7 + "’s" + formValues.disorder + " including symptoms of " + formValues.emotional_8 + ". " + pronoun + " reported that this has led to problems at home (arguments and differences of opinion). ") : ""}${formValues.first_name} ${formValues.emotional_9} a history of SI (Suicidal Ideation) or HI (Homicidal Ideation). ${formValues.emotional_6}</p>`;
        const medicalPayload = `<p><em><u>Medical History:</u></em></p><p>${formValues.first_name} identifies as ${formValues.medical_1} and is in ${formValues.medical_2} health, has a primary care physician and last physical was on: ${formValues.medical_3}. Reports ${formValues.medical_4} ${(formValues.medical_4 === "Allergies") ? ("(" + formValues.medical_5 + ") ") : ""}and ${formValues.medical_6}${(formValues.medical_6 === "illnesses/disease") ? (" (" + formValues.medical_7 + ")") : ""}. Currently taking ${formValues.medical_8}${(formValues.medical_8 === "medications/OTCs") ? (" (" + formValues.medical_9 + ")") : ""}. Reports eating ${formValues.medical_10}.</p>`;
        const familyPayload = `<p><em><u>Family/General:</u></em></p><p>Client is ${formValues.family_1} ${(formValues.family_2 < 2) ? "child" : ("of " + formValues.family_2 + " children")} and is ${formValues.family_3}. ${pronoun} reports ${formValues.family_4} relations with family of origin (parents/siblings); and ${formValues.family_5} relations with nuclear family (Spouse/partner) and has ${(formValues.family_6 === 0) ? "no" : formValues.family_6} children${(formValues.family_6 === 0) ? "" : (" aged " + formValues.family_7)}.</p><p>${formValues.first_name} reports ${formValues.family_8} legal issues ${(formValues.family_8 === "current") ? ("(" + formValues.family_9 + ")") : ""}; has completed ${formValues.family_10} and ${formValues.family_11} ${(formValues.family_11 === "works as a") ? formValues.family_12 : ""}. ${pronoun} is ${formValues.family_13} and ${formValues.family_14} exercise, hobbies, recreational interests, sports, leisure activities ${(formValues.family_14 === "does no") ? "" : "like " + formValues.family_15} in ${lowPossPronoun} free time.</p>`;
        const dataPayload = `${introPayload}<p><br></p>${problemPayload}<p><br></p>${noteTypeValue !== "1" ? (addictionPayload + "<p><br></p>") : ""}${emotionalPayload}<p><br></p>${medicalPayload}<p><br></p>${familyPayload}`;

        let admin_id = getCookie(COOKIE.ClientId);
        let payload = {
            notes_content: noteValue[0] ? "<b><u>DATA:</u></b>" + " " + noteValue[0] : noteTypeValue !== "0" ? "<b><u>DATA:</u></b>" + " " + dataPayload : "",
            notes_assesment: noteValue[1] ? "<b><u>ASSESSMENT:</u></b>" + " " + noteValue[1] : noteTypeValue !== "0" ? "<b><u>ASSESSMENT:</u></b>" + " " + assessmentPayload : "",
            notes_plan: noteValue[2] ? "<b><u>PLAN:</u></b>" + " " + noteValue[2] : noteTypeValue !== "0" ? "<b><u>PLAN:</u></b>" + " " + planPayload : "",
            section_id: null,
            admin_id: admin_id,
            notes_type: noteTypeValue,
            dt_created: `${noteDate.year}-${noteDate.month}-${noteDate?.date}`
        };
        if (sessionChecked === true) {
            payload.session_checked = sessionChecked;
        }
        if (adminClientValuefromSession?.clientId) {
            payload.client_id = adminClientValuefromSession?.clientId;
        } else {
            payload.enquiry_id = adminClientValuefromSession?.enquiryId;
        }
        if (type === "esign") {
            payload.signed_by = admin_id;
        }
        if (noteValue !== null) {
            fetchAdminApi(`/add-notes`, "post", payload).then((res) => {
                if (res?.code && res.code === 200) {
                    setNoteDate({
                        ...noteDate,
                        date: current.getDate(),
                        month: String(current.getMonth() + 1),
                        year: current.getFullYear()
                    });
                    message.success(res.message);
                    hideModal();
                    setLoader(false);
                } else {
                    message.error(res.message);
                    hideModal();
                    setLoader(false);
                }
            });
        }
    };

    // Individual Field onChange functions
    const handleDrug4Change = (value) => {
        setIsDrug4Selected(value === 'never been');
    };
    const handleFamily8Change = (value) => {
        setIsFamily8Selected(value === 'current');
    };
    const handleFamily11Change = (value) => {
        setIsFamily11Selected(value === "works as a");
    };
    const handleFamily14Change = (value) => {
        setIsFamily14Selected(value === "does no");
    };
    const handleMedical4Change = (value) => {
        setIsMedical4Selected(value === "Allergies");
    };
    const handleMedical6Change = (value) => {
        setIsMedical6Selected(value === "illnesses/disease");
    };
    const handleMedical8Change = (value) => {
        setIsMedical8Selected(value === "medications/OTCs");
    };
    // Handle checkbox change for substance
    const handleCheckboxSubChange = (substance, isChecked) => {
        setSelectedSubstances((prev) => ({
            ...prev,
            [substance]: { ...prev[substance], checked: isChecked },
        }));
    };
    // Handle checkbox change for reason
    const handleCheckboxReaChange = (reason, isChecked) => {
        setSelectedReasons((prev) => ({
            ...prev,
            [reason]: { ...prev[reason], checked: isChecked, cause: isChecked ? prev[reason]?.cause || reasonValues[reason] : "", },
        }));
    };
    // Handle checkbox change for crime
    const handleCheckboxCrimeChange = (crime, isChecked) => {
        setSelectedCrimes((prev) => ({
            ...prev,
            [crime]: { ...prev[crime], checked: isChecked },
        }));
    };
    // Handle checkbox change for social
    const handleCheckboxSocialChange = (social, isChecked) => {
        setSelectedSocials((prev) => ({
            ...prev,
            [social]: { ...prev[social], checked: isChecked },
        }));
    };
    // Handle checkbox change for attempts
    const handleCheckboxAttemptChange = (attempt, isChecked) => {
        setSelectedAttempts((prev) => ({
            ...prev,
            [attempt]: isChecked,
        }));
    };
    // Handle input change for age
    const handleAgeChange = (substance, age) => {
        setSelectedSubstances((prev) => ({
            ...prev,
            [substance]: { ...prev[substance], age },
        }));
    };
    // Handle input change for cause
    const handleCauseChange = (reason, cause) => {
        setSelectedReasons((prev) => ({
            ...prev,
            [reason]: { ...prev[reason], cause },
        }));
    };
    // Handle input change for problems
    const handleProblemsChange = (social, problem) => {
        setSelectedSocials((prev) => ({
            ...prev,
            [social]: { ...prev[social], problem },
        }));
    };

    // Individual forms
    const introForm = () => {
        return (
            <Space wrap align="center" className="">
                <Form.Item name={"first_name"}>
                    <Input placeholder={constants.FirstName} />
                </Form.Item>
                {"is a"}
                <Form.Item name="age">
                    <Input type="number" placeholder="Age" />
                </Form.Item>
                {"year old"}
                <Form.Item name="intro_1">
                    {custom_select(true, "single/divorced/married", null, maritalStatus, addMaritalStatus, newMaritalStatus, (e) => setNewMaritalStatus(e.target.value), maritalStatusInputRef)}
                </Form.Item>
                {",\u00A0\u00A0"}
                <Form.Item name="intro_2">
                    {custom_select(true, "Asian Indian/White/Black/Hispanic", null, ethnicity, addEthnicity, newEthnicity, (e) => setNewEthnicity(e.target.value), ethnicityInputRef)}
                </Form.Item>
                {",\u00A0\u00A0"}
                <Form.Item name="intro_3">
                    {custom_select(true, "Hindu/Muslim/Christian/Buddhist/Jewish", null, religion, addReligion, newReligion, (e) => setNewReligion(e.target.value), religionInputRef)}
                </Form.Item>
                {",\u00A0\u00A0"}
                <Form.Item name="gender">
                    <Input placeholder="male/female/(other)" />
                </Form.Item>
                {"from"}
                <Form.Item name="location">
                    <Input placeholder="Location" className='min-w-[200px]' />
                </Form.Item>
                {"who was referred for an assessment by"}
                <Form.Item name="source">
                    <Input placeholder="Referral Source" />
                </Form.Item>
                {"."}
            </Space>
        )
    }

    const drugsForm = () => {
        return (
            <div className='flex flex-col'>
                <Space direction="vertical" className="mb-3">
                    {substances.map((substance) => (
                        <Form.Item key={substance} className="mb-3">
                            <div className='h-[36px]'>
                                <Checkbox
                                    onChange={(e) => handleCheckboxSubChange(substance, e.target.checked)}
                                >
                                    <p className='text-[14px] font-normal'><b>{substance}</b> at age</p>
                                </Checkbox>
                                {selectedSubstances[substance]?.checked && (
                                    <InputNumber
                                        placeholder="Enter age"
                                        min={1}
                                        style={{ width: 100 }}
                                        onChange={(value) => handleAgeChange(substance, value)}
                                    />
                                )}
                            </div>
                            <p className="text-blue-500 text-[14px]">-{substances_aliases[substance]}</p>
                        </Form.Item>
                    ))}
                </Space>
                <Space wrap align="center" className="mb-3">
                    {noteTypeValue === "1"
                        ? (<>, gradually increasing amounts to achieve the desired effect over a longer period of time than intended, despite problems in the past 12 months <u>at home</u></>)
                        : (`, gradually increasing amounts to achieve the desired effect over a longer period of time than intended, despite problems in the past 12 months with`)
                    }
                </Space>
                <Space direction="vertical" className="mb-5">
                    {reasons.map((reason) => (
                        <Form.Item key={reason} >
                            <Checkbox
                                onChange={(e) => handleCheckboxReaChange(reason, e.target.checked)}
                            >
                                <p className='text-[14px] font-normal'>{reason}</p>
                            </Checkbox>
                            {selectedReasons[reason]?.checked && (
                                <Input
                                    defaultValue={selectedReasons[reason]?.cause || reasonValues[reason]}
                                    placeholder="Enter cause"
                                    style={{ width: 600 }}
                                    onChange={(e) => handleCauseChange(reason, e.target.value)}
                                />
                            )}
                        </Form.Item>
                    ))}
                </Space>

                <Space wrap align="center" className="mb-3">
                    {`, caused or exacerbated by use of substances, including reduced involvement in social activities. ${pronoun} reports several attempts to`}
                </Space>
                <Space wrap align="center" className="mb-7">
                    {attempts.map((attempt) => (
                        <Form.Item key={attempt} >
                            <Checkbox
                                onChange={(e) => handleCheckboxAttemptChange(attempt, e.target.checked)}
                            >
                                <p
                                    className="text-[14px] font-normal"
                                    dangerouslySetInnerHTML={{ __html: underlineCaps(attempt) }}
                                />
                            </Checkbox>
                        </Form.Item>
                    ))}
                </Space>
                <Space wrap align="center" className="mb-3">
                    <Form.Item name="drug_1" >
                        <Select placeholder="Denies/Reports" allowClear>
                            <Option value="Denies">Denies</Option>
                            <Option value="Reports">Reports</Option>
                        </Select>
                    </Form.Item>
                    {`any gambling activity and`}
                    <Form.Item name="drug_2">
                        <Select placeholder={`has felt the need to bet more and more money, and has lied to people about how much ${lowPronoun} gambles`} mode="tags" style={{ minWidth: '400px', }}>
                            <Option value="has felt the need to bet more and more money">has felt the need to bet more and more money</Option>
                            <Option value={`has lied to people about how much ${lowPronoun} gambles.`}>has lied to people about how much {lowPronoun} gambles</Option>
                        </Select>
                    </Form.Item>
                    {"."}
                </Space>
                <Space wrap align="center" className="mb-3">
                    <Form.Item name="drug_3">
                        <Select placeholder="Denies/Reports" allowClear>
                            <Option value="Denies">Denies</Option>
                            <Option value="Reports">Reports</Option>
                        </Select>
                    </Form.Item>
                    {`family of origin substance/gambling problems.`}
                </Space>
                <Space wrap align="center" className="mb-3">
                    {`Client reports ${lowPronoun} has`}
                    <Form.Item name="drug_4">
                        <Select placeholder="been/never been" allowClear onChange={handleDrug4Change}>
                            <Option value="been">been</Option>
                            <Option value="never been">never been</Option>
                        </Select>
                    </Form.Item>
                    {`previously treated for addiction`}
                    {!isDrug4Selected && <>
                        {`at`}
                        <Form.Item name={"drug_5"}>
                            <Input placeholder={"Enter Rehab"} />
                        </Form.Item>
                        {`and/or with`}
                        <Form.Item name={"drug_6"}>
                            <Input placeholder={"Enter Therapist"} />
                        </Form.Item>
                    </>}
                    {`; has`}
                    <Form.Item name="drug_7">
                        <Select placeholder="no/some" allowClear>
                            <Option value="no">no</Option>
                            <Option value="some">some</Option>
                        </Select>
                    </Form.Item>
                    {`recovering friends;`}
                    <Form.Item name="drug_8">
                        <Select placeholder="no/some" allowClear>
                            <Option value="no">no</Option>
                            <Option value="some">some</Option>
                        </Select>
                    </Form.Item>
                    {`prior 12-step program experience and longest period of abstinence is`}
                    <Form.Item name={"drug_9"}>
                        <Input placeholder={"days/months/years"} />
                    </Form.Item>
                    {"."}
                </Space>
            </div>
        )
    }

    const presentingProblemForm = () => {
        return (
            <>
                <div className='mt-5 mb-4 italic'><b><u>{noteTypeValue === "1" ? "Presenting Problem/Addiction/Recovery History:" : "Presenting Problem:"}</u></b></div>
                <Space wrap align="center" className="mb-3">
                    {noteTypeValue === "1" && <>
                        {"Client was seen"}
                        <Form.Item name={"problem_1"}>
                            <Select placeholder="online/in-person" allowClear>
                                <Option value="online">online</Option>
                                <Option value="in-person">in-person</Option>
                            </Select>
                        </Form.Item>
                        {`and during ${lowPossPronoun} assessment, outlined ${lowPossPronoun} substance abuse history.`}
                        <Form.Item name={"first_name"}>
                            <Input placeholder={constants.FirstName} />
                        </Form.Item>
                        {`reported that ${lowPronoun} had begun to use`}
                    </>
                    }
                    {noteTypeValue === "3" && <>
                        {`Client was seen online, and during ${lowPossPronoun} assessment, stated concerns about ${lowPossPronoun} emotional status and reports feeling angry, anxious, and depressed within the past 12 months.`}
                    </>
                    }
                    {noteTypeValue === "2" && <>
                        {`Client was seen online, and during ${lowPossPronoun} assessment, stated concerns about ${lowPossPronoun} family member`}
                        <Form.Item name={"problem_2"}>
                            <Input placeholder={"Name or Relation"} />
                        </Form.Item>
                        {"'s"}
                        <Form.Item name="disorder">
                            {custom_select(false, "substance use/gambling use", "tags", disorder, addDisorder, newDisorder, (e) => setNewDisorder(e.target.value), disorderInputRef, { minWidth: '200px', })}
                        </Form.Item>
                        {`${pronoun} reports feeling angry, anxious and depressed about`}
                        <Form.Item name={"problem_2"}>
                            <Input placeholder={"Name or Relation"} />
                        </Form.Item>
                        {"'s"}
                        <Form.Item name="disorder">
                            {custom_select(false, "substance use/gambling use", "tags", disorder, addDisorder, newDisorder, (e) => setNewDisorder(e.target.value), disorderInputRef, { minWidth: '200px', })}
                        </Form.Item>
                        {"and reports several attempts to help"}
                        <Form.Item name={"problem_2"}>
                            <Input placeholder={"Name or Relation"} />
                        </Form.Item>
                        {'abstain within the past'}
                        <Form.Item name={"problem_3"}>
                            <InputNumber placeholder={"Years"} max="90" min='0' />
                        </Form.Item>
                        {`years and feels frustrated at ${lowPossPronoun} inability do so.`}
                    </>
                    }
                </Space>
                {noteTypeValue === "1" ? drugsForm() : null}
            </>
        )
    }

    const addictionForm = () => {
        return (
            <>
                <div className='mt-5 mb-4 italic'><b><u>Addiction/Recovery History:</u></b></div>
                <Space wrap align="center" className="mb-3">
                    {`${pronoun}`}
                    <Form.Item name="addiction_1">
                        <Select placeholder="denied/reported" allowClear>
                            <Option value="denied any">denied</Option>
                            <Option value="reported">reported</Option>
                        </Select>
                    </Form.Item>
                    {`personal history of substance abuse (other than occasional social/religious use of alcohol).`}
                    <Form.Item name="addiction_2">
                        <Select placeholder="Denies/Reports" allowClear>
                            <Option value="Denies">Denies</Option>
                            <Option value="Reports">Reports</Option>
                        </Select>
                    </Form.Item>
                    {`any gambling activity.`} {`${pronoun} stated that, to the best ${lowPossPronoun} recollection, ${lowPossPronoun} family member`}
                    <Form.Item name={"problem_2"}>
                        <Input placeholder={"Name or Relation"} />
                    </Form.Item>
                    {`had begun to use`}
                </Space>
                {drugsForm()}
            </>
        )
    }

    const emotionalForm = () => {
        return (
            <>
                <div className='mt-5 mb-4 italic'><b><u>Mental/Emotional Health:</u></b></div>
                <Space wrap align="center" className="mb-6">
                    {"Client"}
                    <Form.Item name="emotional_1">
                        <Select placeholder="denies/reports" allowClear>
                            <Option value="denies">denies</Option>
                            <Option value="reports">reports</Option>
                        </Select>
                    </Form.Item>
                    {"MH history, was diagnosed with"}
                    <Form.Item name="emotional_2">
                        {custom_select(false, "anxiety/depression/mood disorder/bipolar disorder", 'tags', mentalD, addMentalD, newMentalD, (e) => setNewMentalD(e.target.value), mentalDInputRef, { minWidth: '200px', })}
                    </Form.Item>
                    {"and received mental health treatment from"}
                    <Form.Item name={"emotional_3"}>
                        <Input placeholder={"Therapist"} />
                    </Form.Item>
                    {"and/or was hospitalized for mental illness"}
                    <Form.Item name={"emotional_4"}>
                        <Input placeholder={"mental illness"} />
                    </Form.Item>
                    {"and is on"}
                    <Form.Item name={"emotional_5"}>
                        <Input placeholder={"medication"} />
                    </Form.Item>
                    {"."}

                    {noteTypeValue !== "1" ? (
                        <>
                            <Space wrap align="center" className="mb-3">
                                {`However, ${lowPronoun} reports feeling angry, anxious and depressed about`}
                                <Form.Item name={"emotional_7"}>
                                    <Input placeholder={"Name or Relation"} />
                                </Form.Item>
                                {`’s`}
                                <Form.Item name="disorder">
                                    {custom_select(false, "substance use/gambling use", "tags", disorder, addDisorder, newDisorder, (e) => setNewDisorder(e.target.value), disorderInputRef, { minWidth: '200px', })}
                                </Form.Item>
                                {`including symptoms of`}
                                <Form.Item name="emotional_8">
                                    {custom_select(false, "symptoms", "tags", symptoms, addSymptoms, newSymptoms, (e) => setNewSymptoms(e.target.value), symptomsInputRef, { minWidth: '200px', })}
                                </Form.Item>
                                {`.`}
                            </Space>
                            <Space wrap align="center" className="">
                                {`${pronoun} reported that this has led to problems at home (arguments and differences of opinion).`}
                            </Space>
                        </>
                    ) : null}
                </Space>
                <Space wrap align="center" className="mb-3">
                    <Form.Item name={"first_name"}>
                        <Input placeholder={constants.FirstName} />
                    </Form.Item>
                    <Form.Item name="emotional_9">
                        <Select placeholder="denies/reports" allowClear>
                            <Option value="denies">denies</Option>
                            <Option value="reports">reports</Option>
                        </Select>
                    </Form.Item>
                    {"a history of SI (Suicidal Ideation) or HI (Homicidal Ideation)."}
                    <Form.Item name={"emotional_6"}>
                        <Input placeholder="Details" />
                    </Form.Item>
                </Space>
            </>
        )
    }

    const medicalForm = () => {
        return (
            <>
                <div className='mt-5 mb-4 italic'><b><u>Medical History:</u></b></div>
                <Space wrap align="center" className="mb-6">
                    <Form.Item name={"first_name"}>
                        <Input placeholder={constants.FirstName} />
                    </Form.Item>
                    {"identifies as"}
                    <Form.Item name={"medical_1"}>
                        {custom_select(true, "Heterosexual/Bisexual/LGBTQIA+", null, identity, addIdentity, newIdentity, (e) => setNewIdentity(e.target.value), identityInputRef)}
                    </Form.Item>
                    {"and is in"}
                    <Form.Item name="medical_2">
                        <Select placeholder="good/poor" allowClear>
                            <Option value="good">good</Option>
                            <Option value="poor">poor</Option>
                        </Select>
                    </Form.Item>
                    {"health , has a primary care physician and last physical was on:"}
                    <Form.Item name={"medical_3"}>
                        <Input placeholder={"date"} />
                    </Form.Item>
                    {". Reports"}
                    <Form.Item name="medical_4">
                        <Select placeholder="Allergies/no Allergies" allowClear onChange={handleMedical4Change}>
                            <Option value="Allergies">Allergies</Option>
                            <Option value="no Allergies">no Allergies</Option>
                        </Select>
                    </Form.Item>
                    {isMedical4Selected && (
                        <Form.Item name={"medical_5"}>
                            <Input placeholder={"allergies"} />
                        </Form.Item>
                    )}
                    {"and"}
                    <Form.Item name="medical_6">
                        <Select placeholder="illnesses/no illnesses" allowClear onChange={handleMedical6Change}>
                            <Option value="illnesses/disease">illnesses/diseases</Option>
                            <Option value="no illnesses/disease">no illnesses/diseases</Option>
                        </Select>
                    </Form.Item>
                    {isMedical6Selected && (
                        <Form.Item name={"medical_7"}>
                            <Input placeholder={"illnesses/disease"} />
                        </Form.Item>
                    )}
                    {". Currently taking"}
                    <Form.Item name="medical_8">
                        <Select placeholder="no/medications/OTCs" allowClear onChange={handleMedical8Change}>
                            <Option value="medications/OTCs">medications/OTCs</Option>
                            <Option value="no medications/OTCs">no medications/OTCs</Option>
                        </Select>
                    </Form.Item>
                    {isMedical8Selected && (
                        <Form.Item name={"medical_9"}>
                            <Input placeholder={"medications/OTCs"} />
                        </Form.Item>
                    )}
                    {". Reports eating"}
                    <Form.Item name="medical_10">
                        <Select placeholder="junk food/balanced diet" mode="multiple" style={{ minWidth: '200px', }}>
                            <Option value="junk food">junk food</Option>
                            <Option value="balanced diet">balanced diet</Option>
                        </Select>
                    </Form.Item>
                </Space>
            </>
        )
    }

    const familyForm = () => {
        return (
            <>
                <div className='mt-5 mb-4 italic'><b><u>Family/General:</u></b></div>
                <Space wrap align="center" className="mb-3">
                    {"Client is"}
                    <Form.Item name={"family_1"}>
                        <Input placeholder={"birth order"} />
                    </Form.Item>
                    {'of'}
                    <Form.Item name={"family_2"}>
                        <InputNumber min='0' placeholder={"total"} />
                    </Form.Item>
                    {"children and is"}
                    <Form.Item name="family_3">
                        {custom_select(true, "living alone/with spouse/partner & children/joint family", null, living, addLiving, newLiving, (e) => setNewLiving(e.target.value), livingInputRef)}
                    </Form.Item>
                    {"."}
                </Space>

                <Space wrap align="center" className="mb-6">
                    {`${pronoun} reports`}
                    <Form.Item name="family_4">
                        <Select placeholder="fair/good/poor" allowClear>
                            <Option value="fair">fair</Option>
                            <Option value="good">good</Option>
                            <Option value="poor">poor</Option>
                        </Select>
                    </Form.Item>
                    {`relations with family of origin (parents/siblings); and`}
                    <Form.Item name="family_5">
                        <Select placeholder="fair/good/poor" allowClear>
                            <Option value="fair">fair</Option>
                            <Option value="good">good</Option>
                            <Option value="poor">poor</Option>
                        </Select>
                    </Form.Item>
                    {`relations with nuclear family (spouse/partner) and has`}
                    <Form.Item name={"family_6"}>
                        <InputNumber placeholder={'total'} min='0' />
                    </Form.Item>
                    {'children aged'}
                    <Form.Item name={"family_7"}>
                        <Input placeholder={"children's ages"} />
                    </Form.Item>
                    {'.'}
                </Space>
                <Space wrap align="center" className="mb-3">
                    <Form.Item name={"first_name"}>
                        <Input placeholder={constants.FirstName} />
                    </Form.Item>
                    {"reports"}
                    <Form.Item name="family_8">
                        <Select placeholder="no/current" allowClear onChange={handleFamily8Change}>
                            <Option value="no">no</Option>
                            <Option value="current">current</Option>
                        </Select>
                    </Form.Item>
                    {"legal issues"}
                    {isFamily8Selected && (
                        <Form.Item name={"family_9"}>
                            <Input placeholder={"legal issues"} />
                        </Form.Item>
                    )}
                    {";\u00A0\u00A0 has completed"}
                    <Form.Item name={"family_10"}>
                        <Input placeholder={"education"} />
                    </Form.Item>
                    {"and"}
                    <Form.Item name="family_11">
                        <Select placeholder="is unemployed/retired/owns business/works" allowClear onChange={handleFamily11Change}>
                            <Option value="is unemployed">is unemployed</Option>
                            <Option value="is retired">is retired</Option>
                            <Option value="owns business">owns business</Option>
                            <Option value="works as a">works as a</Option>
                        </Select>
                    </Form.Item>
                    {isFamily11Selected && (
                        <Form.Item name={"family_12"}>
                            <Input placeholder={"profession"} />
                        </Form.Item>
                    )}
                    {`. ${pronoun} is`}
                    <Form.Item name="family_13">
                        <Select placeholder="active/inactive" allowClear>
                            <Option value="active">active</Option>
                            <Option value="inactive">inactive</Option>
                        </Select>
                    </Form.Item>
                    {'and'}
                    <Form.Item name="family_14">
                        <Select placeholder="does/does no" allowClear onChange={handleFamily14Change}>
                            <Option value="does">does</Option>
                            <Option value="does no">does no</Option>
                        </Select>
                    </Form.Item>
                    {'exercise, hobbies, recreational interests, sports, leisure activities'}
                    {!isFamily14Selected && (
                        <>
                            {'like'}
                            <Form.Item name="family_15">
                                <Input placeholder="activities" />
                            </Form.Item>
                        </>
                    )}
                    {`in ${lowPossPronoun} free time.`}
                </Space>
            </>
        )
    }

    // Main Forms
    const formData = () => {
        return (
            <div className='text-[14px] mb-4'>
                <div className='text-lg mb-4'><b><u>{notesHeader[0]}</u></b></div>
                {introForm()}
                {presentingProblemForm()}
                {noteTypeValue !== "1" ? addictionForm() : null}
                {emotionalForm()}
                {medicalForm()}
                {familyForm()}

            </div>
        )
    }

    const formAssessment = () => {
        return (
            <div>
                <div className='text-lg mb-4'><b><u>{notesHeader[1]}</u></b></div>
                <Space wrap align="center" className='text-[14px] mb-6'>
                    <Form.Item name={"first_name"}>
                        <Input placeholder={constants.FirstName} />
                    </Form.Item>
                    {`presented as well-groomed and reported performing daily functions regularly. ${pronoun} is`}
                    <Form.Item name="assess_1">
                        <Select placeholder="ambivalent/motivated" allowClear>
                            <Option value="ambivalent">ambivalent</Option>
                            <Option value="motivated">motivated</Option>
                        </Select>
                    </Form.Item>
                    {"about coaching and was informed that based on the"}
                    <Form.Item name="assess_2">
                        {custom_select(false, "CAGE/DSM-V/MMMH", "tags", assessment, addAssessment, newAssessment, (e) => setNewAssessment(e.target.value), assessmentInputRef, { minWidth: '200px', })}
                    </Form.Item>
                    <Form.Item name="assess_5">
                        <Select
                            mode="tags"
                            placeholder="Select diagnosis"
                            style={{ minWidth: '200px' }}
                        >
                            <Option value="Adjustment disorders">Adjustment disorders</Option>
                            <Option value="With Depressed Mood">With Depressed Mood</Option>
                            <Option value="With Anxiety">With Anxiety</Option>
                            <Option value="With Mixed Anxiety and Depressed Mood">With Mixed Anxiety and Depressed Mood</Option>
                            <Option value="Unspecified">Unspecified</Option>
                            <Option value="Parent Child Conflict">Parent Child Conflict</Option>
                            <Option value="Partner Relational Conflict">Partner Relational Conflict</Option>
                        </Select>
                    </Form.Item>
                    {`assessment screening instruments, ${lowPronoun}`}
                    <Form.Item name="assess_3">
                        <Select placeholder="appears/does not appear" allowClear>
                            <Option value="appears">appears</Option>
                            <Option value="does not appear">does not appear</Option>
                        </Select>
                    </Form.Item>
                    {"to meet ICD criteria for"}
                    <Form.Item name="disorder">
                        {custom_select(false, "substance use/gambling use", "tags", disorder, addDisorder, newDisorder, (e) => setNewDisorder(e.target.value), disorderInputRef, { minWidth: '200px', })}
                    </Form.Item>
                    {"disorder and is"}
                    <Form.Item name="assess_4">
                        <Select placeholder="appropriate/not appropriate" allowClear>
                            <Option value="appropriate">appropriate</Option>
                            <Option value="not appropriate">not appropriate</Option>
                        </Select>
                    </Form.Item>
                    {"for coaching and will be referred out to"}
                    <Form.Item name="assignto">
                        <Select
                            placeholder={constants.AssignedTo}
                            showSearch
                            allowClear
                            filterOption={selectFilterOption}
                            className='min-w-[200px]'
                        >
                            {
                                listAssociates?.map((associate) => <Option value={associate?.firstname + ' ' + associate?.lastname} key={associate?.client_id}>{associate?.firstname + ' ' + associate?.lastname}</Option>)
                            }
                        </Select>
                    </Form.Item>
                    {"."}
                </Space>
            </div>
        )
    };

    const formPlan = () => {
        return (
            <>
                <div className='text-lg mb-4'><b><u>{notesHeader[2]}</u></b></div>
                <Space wrap align="center" className='text-[14px]'>
                    {`Client was informed that ${lowPronoun} is required to`}
                    <Form.Item name="plan_2">
                        <Select
                            title="complete a medical or wellness checkup/detox/have a Psych assessment with Dr. Ashish Deshpande/sign and complete the Coaching Agreement and Payment process"
                            placeholder="complete a medical or wellness checkup/detox/have a Psych assessment with Dr. Ashish Deshpande/sign and complete the Coaching Agreement and Payment process"
                            mode="tags"
                            style={{ minWidth: '400px', }}
                        >
                            <Option value="complete a medical or wellness checkup">complete a medical or wellness checkup</Option>
                            <Option value="detox">detox</Option>
                            <Option value="have a Psych assessment with Dr. Ashish Deshpande">have a Psych assessment with Dr. Ashish Deshpande</Option>
                            <Option value="sign and complete the Coaching Agreement and Payment process">sign and complete the Coaching Agreement and Payment process</Option>
                        </Select>
                    </Form.Item>
                    {`prior to the commencement of coaching.`}
                    <Form.Item name={"first_name"}>
                        <Input placeholder={constants.FirstName} />
                    </Form.Item>
                    {`said ${lowPronoun} would complete the paperwork,`}
                    <Form.Item name="plan_1">
                        <Select placeholder="await the appointment/consider options" allowClear>
                            <Option value="await the appointment">await the appointment</Option>
                            <Option value="consider options">consider options</Option>
                        </Select>
                    </Form.Item>
                    {",\u00A0 and then call back."}
                </Space>
            </>
        )
    };

    const EditorForm = () => {
        const noteValueRefs = noteValue?.map(() => React.createRef());

        const handleFocus = (index) => {
            const quill = noteValueRefs[index].current.getEditor();
            const selection = quill.getSelection();
            if (!selection || selection.length === 0) {
                quill.format('header', 3);
            }
        };

        return (
            <>
                {noteValue?.map((value, index) => (
                    <div>
                        <div className='text-lg'><b><u>{notesHeader[index]}</u></b></div>
                        <ReactQuill
                            name={index}
                            key={index}
                            ref={noteValueRefs[index]}
                            className='admin-notes mb-2'
                            theme="snow"
                            onChange={(val) => onchangeNoteValue(val, index)}
                            value={value}
                            placeholder="Write your note here"
                            onFocus={() => handleFocus(index)}
                            onKeyPress={() => handleFocus(index)}
                        />
                    </div>
                ))}
                <div className='pb-3'>
                    <Space>
                        <Button className='bg-sky-800 text-white uppercase' onClick={hideModal}>{constants.Cancel}</Button>
                        <Button className='bg-sky-800 text-white uppercase' onClick={() => createNote(null, "add")} >{constants.Save}</Button>
                        <Popconfirm title="You will not be able to Edit after you Esign the Notes" onConfirm={() => createNote(null, "esign")}>
                            <Button className='bg-sky-800 text-white uppercase'>{constants.Esign}</Button>
                        </Popconfirm>
                    </Space>
                </div>
            </>
        )
    }

    const renderForm = () => {
        return (
            <>
                {formData()}
                {formAssessment()}
                {formPlan()}
            </>
        )
    };

    return (
        <div className='add-note'>
            <div className='text-center bg-amber-300 text-lg font-semibold p-3'>{constants.Subtitle}
                <span className="py-0.5 px-3 ml-3 text-sm bg-white rounded-xl">{constants.AddNote}</span>
            </div>
            <div className='bg-amber-100 text-base font-semibold p-2 mb-3'><div className="container mx-auto">{constants.AddNote} - {`${adminClientValuefromSession?.client_name ? adminClientValuefromSession?.client_name : ""} (#${adminClientValuefromSession?.client_serial_number ? adminClientValuefromSession?.client_serial_number : ""})`}</div></div>
            <Spin spinning={loader} size="large">
                <div className='container mx-auto'>
                    <div className='flex justify-between items-center pl-3 py-2 pr-7'>
                        <Radio.Group onChange={(e) => getForm(e.target.value)} value={noteTypeValue}>
                            <Radio disabled={isNoteAnswered === false ? false : noteTypeValue === "2" ? false : true} value={"2"}>Family Member</Radio>
                            <Radio disabled={isNoteAnswered === false ? false : noteTypeValue === "1" ? false : true} value={"1"}>Addicts/Gamblers</Radio>
                            <Radio disabled={isNoteAnswered === false ? false : noteTypeValue === "3" ? false : true} value={"3"}>Emotional</Radio>
                            <Radio disabled={isNoteAnswered === false ? false : noteTypeValue === "0" ? false : true} value={"0"}>General</Radio>
                        </Radio.Group>
                    </div>
                    <div className="px-2 pb-2">
                        <div><b className='text-sm'>Initial Assessment General</b></div>
                        <div className='flex gap-5 flex-wrap'>
                            {DOB(noteDate.month, handleNoteMonthChange, noteDate.date, handleNoteDayChange, noteDate.year, handleNoteYearChange, "Date", false)}
                            <Checkbox onChange={(e) => onSessionChange(e.target.checked)} className='mb-6' checked={sessionChecked}>Session</Checkbox>
                        </div>
                        <Card>
                            {noteTypeValue === "0" ? EditorForm() : (
                                <Form
                                    layout="inline"
                                    form={form}
                                    onFinish={createNote}
                                    className='mb-10'>
                                    {renderForm()}
                                    <div className='pt-3'>
                                        <Space>
                                            <Button className='bg-sky-800 text-white uppercase' onClick={hideModal}>{constants.Cancel}</Button>
                                            <Button className='bg-sky-800 text-white uppercase' htmlType='submit' >{constants.Save}</Button>
                                            <Popconfirm title="You will not be able to Edit after you Esign the Notes" onConfirm={() => createNote(null, "esign")}>
                                                <Button className='bg-sky-800 text-white uppercase'>{constants.Esign}</Button>
                                            </Popconfirm>
                                        </Space>
                                    </div>
                                </Form>
                            )}
                        </Card>
                    </div>
                </div>
            </Spin>
        </div>
    );
};

export default AddNote;