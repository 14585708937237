import { Row, Col, Card, Button, Space, Modal, Empty, Checkbox, Tooltip, message, Popconfirm, Spin, Tag } from 'antd';
import { LockOutlined, UnlockOutlined, CheckCircleFilled, CheckOutlined, TrophyOutlined, LikeOutlined, UndoOutlined } from '@ant-design/icons';
import constants from '../../constants/constants';
import { useHistory } from "react-router";
import React, { useEffect, useState } from "react";
import { fetchApi, fetchAdminApi } from '../../services/api';
import { COOKIE, getCookie } from '../../services/cookie';
import { notesModel, DOB } from '../../globalFunctions/GlobalFunctions'
const Dashboard = (props) => {
	const [isModalOpen, setisModalOpen] = useState(props && props.location && props.location.state && props.location.state.isPopUpMessage);
	const [sectionId] = useState(props && props.location && props.location.state && props.location.state.sectionId);
	const history = useHistory();
	const [dashboardData, setDashboarddata] = useState([]);
	const [showSection, setShowSection] = useState(false);
	const [isPaid, setIsPaid] = useState("");
	const [loading, setloading] = useState(false);
	const [loginType] = useState(getCookie(COOKIE.LoginType));
	const [adminClientValue, setAdminClientValue] = useState({});
	const [username] = useState(props && props.location && props.location.state);
	const [clientType, setClientType] = useState("");
	const [isMinor, setIsMinor] = useState(false);
	const [isRefresher, setIsRefresher] = useState(false);
	const [popOverDescription, setPopOverDescription] = useState("");
	const [isWorkbookComplete, setIsWorkbookComplete] = useState(false);
	const [isPopOverOpen, setIsPopOverOpen] = useState(false);
	const [sid, setSid] = useState("");
	const [clientDetails, setClientDetails] = useState([]);
	// const [latestEnabledSection, setLatestEnabledSection] = useState("0");
	//notes
	const [noteValue, setNoteValue] = useState(null);
	const [noteTypeValue, setNoteTypeValue] = useState("0");
	const [notesVisible, setnotesVisible] = useState(false);
	const [sessionChecked, setSessionChecked] = useState(false);
	const current = new Date();
	const [noteDate, setNoteDate] = useState({
		date: current.getDate(),
		month: String(current.getMonth() + 1),
		year: current.getFullYear()
	});
	const [isNoteAnswered, setIsNoteAnswered] = useState(false);
	useEffect(() => {
		if (props && props.location && props.location.state) {
			sessionStorage.setItem("adminClientValue", JSON.stringify(props.history.location.state));
		}
		if(props?.location?.state?.section_zero_counter){
			sessionStorage.setItem("count",props?.location?.state?.section_zero_counter);
		}else{
			sessionStorage.removeItem("count");
		}
		sessionStorage.removeItem("editedByClient");
		if (isModalOpen) {
			setTimeout(() => {
				setisModalOpen(false);
				history.replace({ state: null });
			}, 6000);
		}
		getDashBoardData();
	}, []);
	useEffect(() => {
		const onBackButtonClicked = () => {
			if (history?.location?.pathname === "/workbook-section-0") {
				history.push("/dashboard");
			}
		};
		window.addEventListener("popstate", onBackButtonClicked);
		return () => {
			window.removeEventListener("popstate", onBackButtonClicked);
		};
	}, []);
	const getClientDetails = (client_id) => {
		fetchAdminApi(`/client-profile/${client_id}`, "get",).then((res) => {
			if (res && res.data) {
				setClientDetails(res.data);
				if (res?.data?.plan_completed === 1 || res?.data?.is_active === "2") {
					setIsWorkbookComplete(true);
				} else {
					setIsWorkbookComplete(false);
				}
			} else {
				setClientDetails([]);
			}
		})
	};
	const handleCancel = () => {
		setisModalOpen(false);
		history.replace({ state: null });
	};
	//Function to get dashboard data
	const getDashBoardData = () => {
		// setShowSection(true);
		let clientId = null;
		let adminClientValuefromSession = JSON.parse(sessionStorage.getItem("adminClientValue"));
		setAdminClientValue(adminClientValuefromSession);
		if (props && props.location && props.location.state && props.location.state.isAdmin || adminClientValuefromSession?.isAdmin) {
			clientId = props && props.location && props.location.state && props.location.state.clientId || adminClientValuefromSession?.clientId;
		} else {
			clientId = getCookie(COOKIE.ClientId);
		}
		setloading(true);
		fetchApi(`/client-dashboard/${clientId}`, "get").then((res) => {
			getClientDetails(clientId);
			setIsPaid(res?.is_paid);
			// setLatestEnabledSection(res?.latest_section_enabled);
			if (res && res.data && res.code === 200) {
				setloading(false);
				if (res && res.data && res.data.show_section === 0 && loginType === "0") {
					if(res?.data?.is_resume){
						sessionStorage.setItem("count",res?.data?.is_resume);
					}
					sessionStorage.setItem("section_id", 0);
					history.push({ pathname: "workbook-section-0", state: { new_user: true} });
				} else {
					setClientType(res?.client_type);
					setDashboarddata(res.data);
					let latestSessionEnabled = res?.latest_section_enabled;
					sessionStorage.setItem("latestSessionEnabled", latestSessionEnabled);
					setShowSection(true);
				}
			} else {
				setloading(false);
				setDashboarddata([]);
				if (res?.is_minor === "1") {
					setIsMinor(true);
				} else {
					setIsMinor(false);
				}
				if (adminClientValuefromSession?.isAdmin) {
					setShowSection(true);
				} else {
					setShowSection(false);
				}
				// setShowSection(true);
			}
			// setIsPaid(res?.is_paid);
			// if(res?.is_refresher === 1 && res?.is_paid === 1){
			// 	history.push({ pathname: "/refresher-workbook", });
			// }
			setIsRefresher(res?.is_refresher === 1 ? true : false);
		});
	};
	//Function to return card color
	const getCardColour = (status) => {
		if (status === "0") {
			return 'bg-slate-300 text-center h-36 text-slate-500 cursor-not-allowed';
		} else {
			return 'bg-amber-300 text-center h-36 cursor-pointer';
		}
	};
	//function to route to edited section workbook
	const getEditedSection = (sectionId, partId) => {
		sessionStorage.setItem('count', partId);
		// sessionStorage.setItem('editedByClient', true);
		sessionStorage.setItem('section_id', sectionId);
		history.push({ pathname: '/workbook-section' });
	};
	const enableSection = (flag,sectionId) => {
		let payload = {};
		payload.section_id = sectionId;
		payload.flag = flag;
		payload.is_admin = "1";
		setloading(true);
		fetchAdminApi(`/update-client-sections/${clientDetails?.client_id}`, "put", payload).then((res) => {
			if (res && res.code && res.code === 200) {
				getDashBoardData();
				message.success(res.message);
				setloading(false);
			} else {
				message.error("Failed to enable section.");
				setloading(false);
			}
		});
	};
	// const getSectionId = (section_url) => {
	// 	let url = section_url;
	// 	let url_length = url?.length;
	// 	let section_id = url?.charAt(url_length - 1);
	// 	setSid(section_url === "/refresher-workbook" ? "100" : section_id);
	// };
	const undoSection = (sectionid) => {
		let payload = {
			is_admin : "1",
			flag : "3",
			section_id : sectionid
		};
		fetchAdminApi(`/update-client-sections/${adminClientValue?.clientId}`, "put", payload).then((res) => {
			if (res?.code === 200) {
				message.success(res.message);
				getDashBoardData();
			} else {
				message.error(res.message);
			}
		});
	};
	//Function to return card icon
	const getCardIcon = (status,sectionId,partId) => {
		// let url_length = url?.length;
		// let section_id = url?.charAt(url_length - 1);
		if (clientType === "0") {
			if (sectionId === "2") {
				status = "0";
			}
		}
		switch (status) {
			case "0":
				return clientType === "0" && sectionId === "2" ?
					<Tooltip title={clientDetails?.plan_completed === 1 ? `To Enable Section ${sectionId}, Kindly uncheck the Plan Completed.` : "Unavailable"}><LockOutlined className='absolute bottom-0 right-0 p-2.5' /></Tooltip> :
					loginType !== "0" && clientDetails?.plan_completed === 0 && sectionId !== "8" && dashboardData?.[7]?.[0]?.status !== "3"
						|| loginType !== "0" && clientDetails?.plan_completed === 0 && dashboardData?.[7]?.[0]?.status === "3"
						/* || loginType === "2" && clientDetails?.plan_completed === 0 && sectionId === "8" && dashboardData?.[7]?.[0]?.status !== "3"
						|| loginType === "2" && clientDetails?.plan_completed === 0 && dashboardData?.[7]?.[0]?.status === "3" */
						? <Popconfirm
							title={constants.EnableSection}
							description={sectionId === 100 ? "Are you sure you want to Enable Refresher Section?" : constants.EnableSectionDescription + " " + sectionId + "?"}
							onConfirm={()=>enableSection("1",sectionId)}
							okText="Yes"
							cancelText="No"
							icon={<UnlockOutlined className='!text-sky-500' />}
						><Tooltip title={"Enable"}><LockOutlined /* onClick={() => getSectionId(url)} */ className='absolute bottom-0 right-0 p-2.5' /></Tooltip></Popconfirm> :
						<Tooltip title={clientDetails?.plan_completed === 1 ? `To Enable Section ${sectionId}, Kindly uncheck the Plan Completed.` : "Unavailable"}><LockOutlined className='absolute bottom-0 right-0 p-2.5' /></Tooltip>
			case "1":
				return loginType === "0" ? <Tooltip title={"Available"}><UnlockOutlined className='absolute bottom-0 right-0 p-2.5' /></Tooltip>: <Popconfirm
				title={constants.UnEnableSection}
				description={sectionId === 100 ? "Are you sure you want to UnEnable Refresher Section?" : constants.UnEnableSectionDescription + " " + sectionId + "?"}
				onConfirm={()=>enableSection("4",sectionId)}
				okText="Yes"
				cancelText="No"
				icon={<LockOutlined className='!text-sky-500' />}
			><Tooltip title={"Available"}><UnlockOutlined /* onClick={() => getSectionId(url)} */ className='absolute bottom-0 right-0 p-2.5' /></Tooltip></Popconfirm>;
			case "2":
				return <Tooltip title={"Submitted"}><CheckCircleFilled className='absolute bottom-0 right-0 p-2.5 text-blue-600' /></Tooltip>;
			case "3":
				return loginType === "0"  ? <Tooltip title={"Completed"}><CheckCircleFilled className='absolute bottom-0 right-0 p-2.5 text-green-600' /></Tooltip>: <Popconfirm
					title={"Undo Section Status"}
					description={"Are you sure you want to mark this section as Not Complete ?"}
					onConfirm={()=>undoSection(sectionId)}
					okText="Yes"
					cancelText="No"
					icon={<UndoOutlined className='!text-sky-500' />}
				><Tooltip title={"Completed"}><CheckCircleFilled className='absolute bottom-0 right-0 p-2.5 text-green-600' /></Tooltip></Popconfirm>;
			case "4":
				return <span className='absolute bottom-0 right-0 p-2.5 '><span className="text-sm">Discussion In Progress{" "}</span><Tooltip title={"Discussion In Progress"}><CheckCircleFilled className='!align-middle text-orange-600' /></Tooltip></span>;
			case "5":
				return <span className='absolute bottom-0 right-0 p-2.5 '><a onClick={() => { getEditedSection(sectionId, partId); }} className="text-sm">Edited{" "}</a><Tooltip title={"Completed (Edited)"}><CheckCircleFilled className='!align-middle text-orange-600' /></Tooltip></span>;
			case "6":
				return <span className='absolute bottom-0 right-0 p-2.5 '><a onClick={() => { getEditedSection(sectionId, partId); }} className="text-sm">Edited{" "}</a><Tooltip title={"Completed (Edited)"}><CheckCircleFilled className='!align-middle text-green-600' /></Tooltip></span>;
			case "7":
				return <Tooltip title={"In Progress"}><CheckCircleFilled className='absolute bottom-0 right-0 p-2.5 text-orange-600' /></Tooltip>;
			default:
				break;
		}
	};
	//Function to disable card
	const isCardDisable = (status, section_id) => {
		if (status !== "0") {
			if (props && props.location && props.location.state && props.location.state.isAdmin === true || adminClientValue?.isAdmin) {
				history.push({
					pathname: section_id === 100 ? 'refresher-workbook' : 'workbook-section',
					state: {
						isAdmin: true,
						clientId: props && props.location && props.location.state && props.location.state.clientId || adminClientValue?.clientId,
						client_name: username?.client_name || adminClientValue?.client_name,
						status: status,
						section_id: section_id
					}
				});
			} else {
				sessionStorage.removeItem("adminClientValue");
				history.push({
					pathname: section_id === 100 ? 'refresher-workbook' : 'workbook-section',
					state: { 
						status: status,
						section_id: section_id
					}
				});
			}
		}
	};
	const redirectToSuicideForm = () => {
		sessionStorage.setItem("isProfileForm", true);
		sessionStorage.setItem('count', constants.SectionZeroPart.suicidelForm);
		window.open(constants.BaseUrl + "/workbook-section-0");
	};
	const redirectToFilesListing = () => {
		sessionStorage.clear();
		let payload = {
			clientId:  clientDetails?.client_id,
			client_serial_number: clientDetails?.client_serial_number,
			client_name: clientDetails?.firstname + " " + clientDetails?.lastname
		};
		sessionStorage.setItem("adminClientValue", JSON.stringify(payload));
		window.open(constants.BaseUrl + "/documents");
	};
	const getMedicalForm = () => {
		sessionStorage.clear();
		let payload = {
			clientId:  clientDetails?.client_id,
			client_name: clientDetails?.firstname + " " + clientDetails?.lastname,
			is_medical_info : true
		};
		sessionStorage.setItem("adminClientValue", JSON.stringify(payload));
		window.open(constants.BaseUrl + "/medical-info");
	};
	const redirectToEditProfile = () => {
		window.open(constants.BaseUrl + "/edit-client");
	};
	const routeToListNotes = () => {
		window.open(constants.BaseUrl + "/notes");
	};
	const userHeader = () => {
		return <Col span={24} className={`flex flex-col sm:flex-row ${getCookie(COOKIE.ClientId) === '1' ? 'justify-start' : 'justify-between'} sm:items-end gap-x-2`}>
				{
					loginType === "1" || loginType === "2" ?
						<Space size={'small'} wrap direction='vertical'>
							{/* {
								loginType === "1" || loginType === "2" ? (<span onClick={() => {
									history.push({
										pathname: "/edit-client",
										state: { client_id: adminClientValue?.clientId }
									});
								}} className='font-semibold text-base text-black cursor-pointer'>{constants.AdminWorkbook} - {username?.client_name ? username?.client_name : adminClientValue?.client_name} {`(#${username?.client_serial_number ? username?.client_serial_number : adminClientValue?.client_serial_number})`}</span>) : dashboardData.length === 0 ? null : (<span className='font-semibold text-base text-black'>{constants.MyWorkbook}</span>)} */}
							{/* {
								loginType === "1" || loginType === "2" ?
									<Button className='bg-sky-800 text-white uppercase' onClick={addNotes}>Add Notes</Button>
									: null
							} */}
							<span onClick={() => {
								history.push({
									pathname: "/edit-client",
									state: { client_id: adminClientValue?.clientId }
								});
							}} className='font-semibold text-base text-black cursor-pointer'>{constants.AdminWorkbook} - {username?.client_name ? username?.client_name : adminClientValue?.client_name} {`(#${username?.client_serial_number ? username?.client_serial_number : adminClientValue?.client_serial_number})`}</span>
							<Space size="small">
								<Button size='small' className='text-xs font-semibold bg-sky-800 text-white uppercase' onClick={routeToListNotes}>{constants.ViewNotes}</Button>
								<Button size='small' className='text-xs font-semibold bg-sky-800 text-white uppercase' onClick={addNotes}>{constants.AddNote}</Button>
								{/* {loginType === "1" || loginType === "2" ? (<Button className='bg-sky-800 text-white uppercase ml-2' onClick={routeToListNotes}>{constants.ViewNotes}</Button>) : null}
							{loginType === "1" || loginType === "2" ? (<Button className='bg-sky-800 text-white uppercase' onClick={() => history.goBack()}>{constants.BackBtn}</Button>) : null} */}
							</Space>
						</Space> :
						dashboardData.length === 0 ? <>
							<span className='font-semibold text-base text-black'>{constants.MyWorkbook}</span>
							<Space className="float-right flex-wrap">
								<Button size='small' className='text-xs font-semibold bg-sky-800 text-white uppercase' onClick={getMedicalForm}>{constants.ReleaseForm}</Button>
								<Button size='small' className='text-xs font-semibold bg-sky-800 text-white uppercase' onClick={() => { history.push("/my-profile"); }}>{constants.MyProfileText}</Button>
							</Space>
						</> : (
							<>
								<span className='font-semibold text-base text-black'>{constants.MyWorkbook}</span>
								<Space className="float-right flex-wrap">
									{(clientDetails && clientDetails.show_suicidal_consent === 1) && <Button size='small' className='text-xs font-semibold bg-sky-800 text-white uppercase' onClick={redirectToSuicideForm}>{constants.SuicideForm}</Button>}
									<Button size='small' className='text-xs font-semibold bg-sky-800 text-white uppercase' onClick={redirectToFilesListing}>{constants.DocumentList}</Button>
									<Button size='small' className='text-xs font-semibold bg-sky-800 text-white uppercase' onClick={getMedicalForm}>{constants.ReleaseForm}</Button>
									<Button size='small' className='text-xs font-semibold bg-sky-800 text-white uppercase' onClick={() => { history.push("/my-profile"); }}>{constants.MyProfileText}</Button>
								</Space>
							</>
						)
				}
				{loginType === "1" || loginType === "2" ? <Space size="small" className='ml-3'>
					<Button size='small' className='text-xs font-semibold bg-sky-800 text-white uppercase' onClick={redirectToEditProfile}>Profile</Button></Space> : null}
			</Col>
	};
	const addNotes = () => {
		if (clientDetails.is_note_answered === 0) {
			window.open(constants.BaseUrl + "/add-note");
		} else {
			document.getElementById('top').scrollIntoView({ behavior: "smooth" });
			getForm(noteTypeValue);
			setnotesVisible(true);
		}
	};
	const getForm = (formType) => {
		let adminClientValuefromSession = JSON.parse(sessionStorage.getItem("adminClientValue"));
		setNoteTypeValue(formType);
		let payload = { note_type: formType };
		payload.client_id = props && props.location && props.location.state && props.location.state.clientId || adminClientValuefromSession?.clientId;
		fetchAdminApi("/notes", 'post', payload).then((res) => {
			if (res?.code === 200) {
				setNoteValue(res?.data?.data);
				if (res?.data?.is_note_answered === 1) {
					setIsNoteAnswered(true);
				} else {
					setIsNoteAnswered(false);
				}
			} else {
				setNoteValue([]);
			}
		});
	};
	const hideModal = () => {
		setNoteValue(null);
		setNoteTypeValue("0");
		setnotesVisible(false);
	};
	const onchangeNoteValue = (e, index) => {
		noteValue[index] = e;
	};
	const createNote = (type) => {
		let adminClientValuefromSession = JSON.parse(sessionStorage.getItem("adminClientValue"));
		let admin_id = getCookie(COOKIE.ClientId);
		let payload = {
			client_id: props && props.location && props.location.state && props.location.state.clientId || adminClientValuefromSession?.clientId,
			notes_content: noteValue[0] ? "<b><u>DATA:</u></b>" + " " + noteValue[0] : "",
			notes_assesment: noteValue[1] ? "<b><u>ASSESSMENT:</u></b>" + " " + noteValue[1] : "",
			notes_plan: noteValue[2] ? "<b><u>PLAN:</u></b>" + " " + noteValue[2] : "",
			section_id: null,
			admin_id: admin_id,
			notes_type: noteTypeValue,
			dt_created: `${noteDate.year}-${noteDate.month}-${noteDate?.date}`
		};
		if (sessionChecked === true) {
			payload.session_checked = sessionChecked;
		}
		if (type === "esign") {
			payload.signed_by = admin_id;
		}
		if (noteValue !== null) {
			fetchAdminApi(`/add-notes`, "post", payload).then((res) => {
				if (res && res.code && res.code === 200) {
					setNoteDate({
						...noteDate,
						date: current.getDate(),
						month: String(current.getMonth() + 1),
						year: current.getFullYear()
					});
					message.success(res.message);
				} else {
					message.error(res.message);
				}
			});
		}
		hideModal();
	};
	//date of notes date onchange
	const handleNoteDayChange = (value) => {
		setNoteDate({
			...noteDate,
			date: value
		});
	};
	const handleNoteMonthChange = (value) => {
		setNoteDate({
			...noteDate,
			month: value
		});
	};
	const handleNoteYearChange = (value) => {
		setNoteDate({
			...noteDate,
			year: value
		});
	};
	const onSessionChange = (value) => {
		setSessionChecked(value);
	}
	const onchangeWorkbookComplete = (e) => {
		if (e.target.checked === true) {
			setPopOverDescription("Are you sure you want to close the workbook section?");
		} else {
			setPopOverDescription("Are you sure you want to resume the workbook section?");
		}
		setIsWorkbookComplete(e.target.checked);
	};
	const confirm = () => {
		let payload = {
			client_id: clientDetails?.client_id,
			workbook_complete: isWorkbookComplete === true ? '1' : '0'
		};
		fetchAdminApi(`/workbook-complete`, "post", payload).then((res) => {
			if (res?.code === 200) {
				message.success(res.message);
				getDashBoardData();
				setIsPopOverOpen(false);
			} else {
				message.error(res.message);
				setIsPopOverOpen(false);
			}
		});
	};
	const cancel = () => {
		setIsPopOverOpen(false);
		setIsWorkbookComplete(clientDetails?.plan_completed === 0 || clientDetails?.is_active !== "2" ? false : true);
	};
	const showPopconfirm = () => {
		setIsPopOverOpen(true);
	};
	const planCompletedComponant = (
		<Popconfirm
			title={constants.PlanCompleted}
			description={popOverDescription}
			onConfirm={confirm}
			onCancel={cancel}
			open={isPopOverOpen.plan_completed}
			okText="Yes"
			cancelText="No"
			icon={<CheckOutlined className='!text-sky-500' />}
		>
			<Checkbox checked={isWorkbookComplete} onClick={showPopconfirm} onChange={onchangeWorkbookComplete}>{constants.PlanCompleted}</Checkbox>
		</Popconfirm>
	);
	return (
		<div>
			<div className='text-center bg-amber-300 text-lg font-semibold p-3'>{constants.Subtitle}
				<span className="py-0.5 px-3 ml-3 text-sm bg-white rounded-xl">Dashboard</span>
			</div>
			<div className='bg-amber-100 text-base font-semibold p-1.5 mb-3'><div className="container mx-auto">{userHeader()}</div></div>
			<div className='mx-6 mb-6 mt-3'>
				{showSection &&
					<Spin spinning={loading} size="large">
						{
							clientDetails?.client_id === clientDetails?.initial_client_id || clientDetails?.client_id && clientDetails?.initial_client_id === null ?
								<Row gutter={[24, 24]}>
									<Col xs={24} md={12} lg={6}>
										<Card bodyStyle={{ height: "100%" }} size="small" className={getCardColour(dashboardData && dashboardData[1] ? dashboardData[1][0].status : "0")} >
											<div className='h-4/5' onClick={() => isCardDisable(dashboardData && dashboardData[1] ? dashboardData[1][0].status : "0", 1)}>
												<span className='text-lg font-bold'>{constants.sec1}</span><br />
												<span className='text-base'>{constants.sec1sub}</span>
											</div>
											{getCardIcon(dashboardData && dashboardData[1] ? dashboardData[1][0].status : "0", "1", dashboardData && dashboardData[1] ? dashboardData[1][0]?.part_id : null)}
										</Card>
									</Col>
									<Col xs={24} md={12} lg={6}>
										<Card bodyStyle={{ height: "100%" }} size="small" className={getCardColour(clientType === "0" ? "0" : dashboardData && dashboardData[2] ? dashboardData[2][0].status : "0")} >
											<div className='h-4/5' onClick={() => isCardDisable(clientType === "0" ? "0" : dashboardData && dashboardData[2] ? dashboardData[2][0].status : "0", 2)}>
												<span className='text-lg font-bold'>{constants.sec2}</span><br />
												<span className='text-base'>{constants.sec2sub}</span>
												{clientType === "0" && (<span className='absolute bottom-0 left-0 p-2.5 text-sm'>Non Applicable</span>)}
											</div>
											{getCardIcon(dashboardData && dashboardData[2] ? dashboardData[2][0].status : "0", "2", dashboardData && dashboardData[2] ? dashboardData[2][0]?.part_id : null, dashboardData && dashboardData[2] ? dashboardData[2][0]?.section_id : null)}
										</Card>
									</Col>
									<Col xs={24} md={12} lg={6}>
										<Card bodyStyle={{ height: "100%" }} size="small" className={getCardColour(dashboardData && dashboardData[3] ? dashboardData[3][0].status : "0")}>
											<div className='h-4/5' onClick={() => isCardDisable(dashboardData && dashboardData[3] ? dashboardData[3][0].status : "0", 3)}>
												<span className='text-lg font-bold'>{constants.sec3}</span><br />
												<span className='text-base'>{constants.sec3sub}</span>
											</div>
											{getCardIcon(dashboardData && dashboardData[3] ? dashboardData[3][0].status : "0", "3", dashboardData && dashboardData[3] ? dashboardData[3][0]?.part_id : null)}
										</Card>
									</Col>
									<Col xs={24} md={12} lg={6}>
										<Card bodyStyle={{ height: "100%" }} size="small" className={getCardColour(dashboardData && dashboardData[4] ? dashboardData[4][0].status : "0")}>
											<div className='h-4/5' onClick={() => isCardDisable(dashboardData && dashboardData[4] ? dashboardData[4][0].status : "0", 4)}>
												<span className='text-lg font-bold'>{constants.sec4}</span><br />
												<span className='text-base'>{constants.sec4sub}</span>
											</div>
											{getCardIcon(dashboardData && dashboardData[4] ? dashboardData[4][0].status : "0", "4", dashboardData && dashboardData[4] ? dashboardData[4][0]?.part_id : null)}
										</Card>
									</Col>
									<Col xs={24} md={12} lg={6}>
										<Card bodyStyle={{ height: "100%" }} size="small" className={getCardColour(dashboardData && dashboardData[5] ? dashboardData[5][0].status : "0")}>
											<div className='h-4/5' onClick={() => isCardDisable(dashboardData && dashboardData[5] ? dashboardData[5][0].status : "0", 5)}>
												<span className='text-lg font-bold'>{constants.sec5}</span><br />
												<span className='text-base'>{constants.sec5sub}</span>
											</div>
											{getCardIcon(dashboardData && dashboardData[5] ? dashboardData[5][0].status : "0", "5", dashboardData && dashboardData[5] ? dashboardData[5][0]?.part_id : null)}
										</Card>
									</Col>
									<Col xs={24} md={12} lg={6}>
										<Card bodyStyle={{ height: "100%" }} size="small" className={getCardColour(dashboardData && dashboardData[6] ? dashboardData[6][0].status : "0")}>
											<div className='h-4/5' onClick={() => isCardDisable(dashboardData && dashboardData[6] ? dashboardData[6][0].status : "0", 6)}>
												<span className='text-lg font-bold'>{constants.sec6}</span><br />
												<span className='text-base'>{constants.sec6sub}</span>
											</div>
											{getCardIcon(dashboardData && dashboardData[6] ? dashboardData[6][0].status : "0", "6", dashboardData && dashboardData[6] ? dashboardData[6][0]?.part_id : null)}
										</Card>
									</Col>
									<Col xs={24} md={12} lg={6}>
										<Card bodyStyle={{ height: "100%" }} size="small" className={getCardColour(dashboardData && dashboardData[7] ? dashboardData[7][0].status : "0")}>
											<div className='h-4/5' onClick={() => isCardDisable(dashboardData && dashboardData[7] ? dashboardData[7][0].status : "0", 7)}>
												<span className='text-lg font-bold'>{constants.sec7}</span><br />
												<span className='text-base'>{constants.sec7sub}</span>
											</div>
											{getCardIcon(dashboardData && dashboardData[7] ? dashboardData[7][0].status : "0", "7", dashboardData && dashboardData[7] ? dashboardData[7][0]?.part_id : null)}
										</Card>
									</Col>
									<Col xs={24} md={12} lg={6}>
										<Card bodyStyle={{ height: "100%" }} size="small" className={getCardColour(dashboardData && dashboardData[8] ? dashboardData[8][0].status : "0")}>
											<div className='h-4/5' onClick={() => isCardDisable(dashboardData && dashboardData[8] ? dashboardData[8][0].status : "0", 8)}>
												<span className='text-lg font-bold'>{constants.sec8}</span><br />
												<span className='text-base'>{constants.sec8sub}</span>
											</div>
											{getCardIcon(dashboardData && dashboardData[8] ? dashboardData[8][0].status : "0", "8", dashboardData && dashboardData[8] ? dashboardData[8][0]?.part_id : null)}
										</Card>
									</Col>
									<Col span={24}>
										{loginType === "1" || loginType === "2" ? <div className='float-left'>{planCompletedComponant}</div> : null}
										<Space className='w-full' size={[0, 8]} wrap>
											<Tag color="default" icon={<LockOutlined />}>Unavailable</Tag>
											<Tag color="default" icon={<UnlockOutlined />}>Available</Tag>
											<Tag color="orange" icon={<CheckCircleFilled />}>In Progress</Tag>
											<Tag color="blue" icon={<CheckCircleFilled />}>Submitted</Tag>
											<Tag color="green" icon={<CheckCircleFilled />}>Completed</Tag>
											<Tag color="orange" icon={<CheckCircleFilled />}>Discussion In Progress</Tag>
											<Tag color="green" icon={<CheckCircleFilled />}>Completed (Edited)</Tag>
										</Space>
									</Col>
								</Row> :
								<Row>
									<Col xs={24} md={12} lg={6}>
										<Card size="small" className={getCardColour(dashboardData && dashboardData[100] ? dashboardData[100][0].status : "0")} onClick={() => isCardDisable(dashboardData && dashboardData[100] ? dashboardData[100][0].status : "0", 100)}>
											<br />
											<span className='text-lg font-bold'>{constants.RefresherWorkSheet}</span>
											{getCardIcon(dashboardData && dashboardData[100] ? dashboardData[100][0].status : "0", 100, dashboardData && dashboardData[100] ? dashboardData[100][0]?.part_id : null)}
										</Card>
									</Col>
									<Col span={24}>
										{loginType === "1" || loginType === "2" ? <div className='float-left'>{planCompletedComponant}</div> : null}
										<Space className='w-full' size={[0, 8]} wrap>
											<Tag color="default" icon={<LockOutlined />}>Unavailable</Tag>
											<Tag color="default" icon={<UnlockOutlined />}>Available</Tag>
											<Tag color="orange" icon={<CheckCircleFilled />}>In Progress</Tag>
											<Tag color="blue" icon={<CheckCircleFilled />}>Submitted</Tag>
											<Tag color="green" icon={<CheckCircleFilled />}>Completed</Tag>
											<Tag color="orange" icon={<CheckCircleFilled />}>Discussion In Progress</Tag>
											<Tag color="green" icon={<CheckCircleFilled />}>Completed (Edited)</Tag>
										</Space>
									</Col>
								</Row>
						}
					</Spin> 
				}
				{isPaid == 0 && (
					<Empty
						image={null}
						className="custom-ant-empty-image"
						description={
							<div className="text-center">
								<LikeOutlined className="text-8xl text-sky-800 mb-2.5" />
								<div className="text-lg">
									{isRefresher === false ?
										isMinor ?
											constants.DashboardWelcomeMessageMinor
											: clientDetails?.enquiry_info?.member_of !== null ? constants.DashboardWelcomeMessageFamilyMember : constants.DashboardWelcomeMessage
										: constants.DashboardWelcomeRefresher
									}
								</div>
							</div>
						}
					>
					</Empty>
				)}
				{/* {(isPaid == 1 && latestEnabledSection == "0" && loginType === "0") && (
					<Empty
						image={null}
						className="custom-ant-empty-image"
						description={
							<div className="text-center">
								<LikeOutlined className="text-8xl text-sky-800 mb-2.5" />
								<div className="text-lg">
									{constants.DashboardWelcomePayment}
								</div>
							</div>
						}
					/>
				)} */}
			</div>
			<Modal
				title={
					<div>
						<TrophyOutlined className="text-5xl text-sky-900" />
					</div>
				}
				open={isModalOpen}
				onCancel={handleCancel}
				footer={null}
				className="welcome-modal text-center"
				width={410}
			>
				<div className="text-2xl font-bold mb-2.5 text-sky-900">
					{constants.complete}
				</div>
				<div className="text-lg font-semibold text-slate-500" type="secondary">
					{constants.complete2} {sectionId}.
					{constants.complete3}
					{constants.complete4}
				</div>
			</Modal>
			{noteValue && notesModel(hideModal, notesVisible, onchangeNoteValue, createNote, getForm, noteValue, noteTypeValue, isNoteAnswered, DOB(noteDate.month, handleNoteMonthChange, noteDate.date, handleNoteDayChange, noteDate.year, handleNoteYearChange, "Date", false), onSessionChange, sessionChecked, false, null, null)}
		</div>
	);
};
export default Dashboard;
